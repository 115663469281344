/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** FIXME: Fix sharing of global sass variables into javascript file/code.
 * For the mean time, we make the values static */
/** TODO: sync update values here to globalScssVariables.ts */
// import globalPrivateSassVariables from "@/scss/definitions/light-theme-variables.scss";
// import globalCorpSassVariables from "@/scss/definitions/dark-theme-variables.scss";

import { isCorporateSite } from "@/shared/utils/config";

// export const getGlobalSassVariables = () => {
//   if (isCorporateSite) {
//     return globalCorpSassVariables;
//   } else {
//     return globalPrivateSassVariables;
//   }
// };

export const getGlobalSassVariables = () => {
  return {
    white: "#fff",
    black: "#000",
    modalMaskColor: "rgba(0, 0, 0, 0.25)",
    separatorColor: isCorporateSite ? "#444d63" : "#dbdfea",
    qrCodeColor: isCorporateSite ? "#fff" : "#2b3346",
    qrCodeBgColor: isCorporateSite ? "#2b3346" : "#fff",
    bodyBackground: isCorporateSite ? "#202737" : "#fafbfe",
    accentColor: "#4770d6",
  };
};
