<template>
  <!-- Reference for properties https://2x.antdv.com/components/button#API -->
  <a-button class="basic-button" v-bind="$attrs">
    <template #icon>
      <slot name="icon" />
    </template>

    <slot v-if="!iconOnly">{{ t("basicButton.defaultText") }}</slot>
  </a-button>
</template>

<style lang="scss"></style>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const t = useI18n().t;

    return { t };
  },
});
</script>
