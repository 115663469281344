<template>
  <transition name="fade" mode="out-in">
    <div v-if="!isPersonalVerificationStatusTemporarilySuspended">
      <!-- 
        Show authenticated layout on authenticated user and routes which requires auth
        e.g. dashboard, payments, history, etc.
      -->
      <AuthenticatedLayout v-if="isAuthenticated && isAuthenticatedRoute">
        <VErrorBoundary :on-error="handleError">
          <template #boundary="{ hasError }">
            <a-skeleton
              active
              :paragraph="{ rows: 4 }"
              :loading="
                updateUserInfo.user.loading.value ||
                updateUserInfo.isReloading.value
              "
              class="pa-40"
              v-if="
                updateUserInfo.user.loading.value ||
                updateUserInfo.isReloading.value
              "
            />
            <router-view v-else />
            <!-- To trigger the SomethingWentWrong Page -->
            <div v-if="hasError ? (trigger = true) : (trigger = false)"></div>

            <!-- AutoLogout component for inactivity feature
              must be included only on AuthenticatedLayout -->
            <AutoLogout />
          </template>
        </VErrorBoundary>
      </AuthenticatedLayout>

      <!-- 
        Otherwise, show Non-authenticated pages
        e.g, login, register pages (both non-authenticated and authenticated pages) 
      -->
      <NonAuthenticatedLayout v-else>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </NonAuthenticatedLayout>
    </div>

    <!-- Separate page for Temporarily Suspended Account -->
    <div v-else>
      <router-view />

      <!-- AutoLogout component for inactivity feature -->
      <AutoLogout />
    </div>
  </transition>
</template>

<style lang="scss">
@import "./../scss/utils/utils";

#app {
  height: 100%;
}
// The following codes are added to modify
// the default css of nprogress(loading indicator)
/* Make clicks pass-through */
#nprogress .bar {
  background: $primary-color;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px $primary-color, 0 0 5px $primary-color;
}

#nprogress .spinner-icon {
  visibility: hidden;
}
//End of modifying CSS on loading indicators
</style>

<script>
import NonAuthenticatedLayout from "@/shared/components/layouts/NonAuthenticatedLayout.vue";
import AuthenticatedLayout from "@/shared/components/layouts/AuthenticatedLayout.vue";
import VErrorBoundary from "vue-error-boundary";
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import useLogging from "@/shared/composables/useLogging";
import { useUpdateUserInfo } from "@/shared/composables/api/useUpdateUserInfo";
import { useI18n } from "vue-i18n";
import { authenticatedPrivateUserRoutes } from "@/private/router";
import { useRoute, useRouter } from "vue-router";
import routeNames from "@/corporate/router/routeNames";
import AutoLogout from "@/shared/components/inactivty/AutoLogout.vue";
import { useJiraWidgetSupport } from "@/shared/composables/useJiraWidgetSupport";
import { useReactiveJiraWidget } from "@/shared/composables/useReactiveJiraWidget";
import { useUserStatus } from "@/shared/composables/useUserStatus";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const t = useI18n().t;
    const trigger = ref(false);

    // on mounted, call the hide jira widget
    onMounted(() =>
      setTimeout(() => {
        useJiraWidgetSupport();
        useReactiveJiraWidget();
      }, 300)
    );

    // Fetch if user is authenticated/logged in
    const isAuthenticated = computed(
      () => store.state.userInfo.isAuthenticated
    );
    // For personal site, fetch if personal status is temporarily suspended
    const { isPersonalVerificationStatusTemporarilySuspended } =
      useUserStatus();

    const isAuthenticatedRoute = computed(() =>
      authenticatedPrivateUserRoutes.includes(String(route.name))
    );

    const updateUserInfo = useUpdateUserInfo();
    const { submitErrorToApi } = useLogging();

    /** Watch the trigger value to fire the
     * Something Went Wrong Page */
    watch([trigger], (value) => {
      if (value) {
        router.push({ name: routeNames.somethingWentWrong });
      }
    });

    /** Called composable inside of setup instead
     * since it's being called twice if inside
     * of onMounted
     */
    if (isAuthenticated.value) {
      (async () => {
        await updateUserInfo.update(true);
      })();
    }

    return {
      isAuthenticated,
      isPersonalVerificationStatusTemporarilySuspended,
      isAuthenticatedRoute,
      updateUserInfo,
      handleError(err) {
        console.log("submittoapi", err);
        /**
         * Submit Error Message to API with stack trace
         */
        submitErrorToApi(err.message, { stack: err.stack });
      },
      t,
      trigger,
      router,
    };
  },
  components: {
    NonAuthenticatedLayout,
    AuthenticatedLayout,
    VErrorBoundary,
    AutoLogout,
  },
});
</script>
