import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/images/Logo.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOutlined = _resolveComponent("MenuOutlined")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_UserMenuPopup = _resolveComponent("UserMenuPopup")!

  return (_ctx.isMediumScreen)
    ? (_openBlock(), _createBlock(_component_a_layout_header, {
        key: 0,
        class: "mobile-header"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { class: "w-100 vertical-middle" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 2 }, {
                default: _withCtx(() => [
                  (_ctx.mobileCollapsed)
                    ? (_openBlock(), _createBlock(_component_MenuOutlined, {
                        key: 0,
                        class: "sidebar-toggle cursor-pointer",
                        onClick: _ctx.openSidebar
                      }, null, 8, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_CloseOutlined, {
                        key: 1,
                        class: "sidebar-toggle cursor-pointer",
                        onClick: _ctx.closeSidebar
                      }, null, 8, ["onClick"]))
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 20,
                class: "page-name text-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_typography_title, {
                    level: 3,
                    class: "mt-20 capitalize mobile-header__title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.pageName), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 2,
                class: "flex items-center justify-end"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "mobile-logo"
                  }, null, -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_a_layout_header, {
        key: 1,
        class: "desktop-header authenticated-header pl-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { justify: "center" }, {
            default: _withCtx(() => [
              (!_ctx.ifKycQuestionnaire)
                ? (_openBlock(), _createBlock(_component_a_col, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_UserMenuPopup)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}