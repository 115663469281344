import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-menu-popup--details-button__fullname" }
const _hoisted_2 = { class: "user-menu-popup--details-button__site-settings" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_BasicButton = _resolveComponent("BasicButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_col, {
      type: "flex",
      class: "user-menu-popup--details-button--col-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BasicButton, {
          class: "user-menu-popup__user-details-btn",
          type: "text",
          onClick: _ctx.handleSettingsKYCRedirect
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { class: "mx-8" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { class: "mr-8 flex items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_avatar, { class: "user-menu-popup__avatar" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.userInitials), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { class: "flex flex-col justify-center text-left" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.userName), 1),
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.isCorporateSite ? _ctx.t("Business") : _ctx.t("Personal")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    (!_ctx.isTemporarilySuspended)
      ? (_openBlock(), _createBlock(_component_a_col, {
          key: 0,
          type: "flex",
          class: "user-menu-popup--details-settings-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BasicButton, {
              class: "user-menu-popup__details-btn",
              type: "text",
              onClick: _ctx.handleSettingsRedirect
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "user-menu-popup__icon",
                  src: _ctx.SettingsIcon
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_col, {
      type: "flex",
      class: "user-menu-popup--details-settings-icon"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BasicButton, {
          class: "user-menu-popup__details-btn",
          type: "text",
          onClick: _ctx.handleJiraWidgetSupport
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "user-menu-popup__icon",
              src: _ctx.NavbarJiraWidgetIcon
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_col, {
      type: "flex",
      class: "user-menu-popup--details-logout-icon pr-20"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BasicButton, {
          onClick: _ctx.logoutUser,
          class: "user-menu-popup__details-btn",
          type: "text",
          "icon-only": _ctx.logoutLoading,
          loading: _ctx.logoutLoading,
          disabled: _ctx.logoutLoading
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "user-menu-popup__icon",
              src: _ctx.LogoutIcon
            }, null, 8, _hoisted_5)
          ]),
          _: 1
        }, 8, ["onClick", "icon-only", "loading", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}