import { isCorporateSite } from "@/shared/utils/config";

/**
 * Use this to import store for the configured site app
 */
let store;

if (isCorporateSite) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  store = require("@/corporate/store").default;
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  store = require("@/private/store").default;
}

export default store;
