export enum QuestionnaireNavigationSteps {
  Welcome = "Welcome",
  BirthPlace = "BirthPlace",
  UsageType = "UsageType",
  EmploymentStatus = "EmploymentStatus",
  UsageAmount = "UsageAmount",
  Tax = "Tax",
  Pep = "Pep",
  Confirm = "Confirm",
}
