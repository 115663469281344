import { computed } from "vue";
import { useStore } from "vuex";

/**
 * Use this for images that we want to display that is protected
 * via x-accel nginx config. @see https://www.nginx.com/resources/wiki/start/topics/examples/x-accel/
 *
 * You can directly put the response on the src attribute of an img element
 */
export function useSecureImageQuery(): {
  fetchImage: (protectedUrl?: string | null | undefined) => Promise<string>;
} {
  const store = useStore();
  const userToken = computed(() => store.state.userInfo.token);

  const fetchImage: (protectedUrl?: string | null) => Promise<string> = async (
    protectedUrl?: string | null
  ) => {
    if (!protectedUrl) {
      return "";
    }

    try {
      return await fetch(protectedUrl ?? "", {
        method: "get",
        headers: {
          Authorization: `Bearer ${userToken.value}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob));
    } catch (e) {
      console.log("Error on useSecureImageQuery", e);
      return "";
    }
  };

  return {
    fetchImage,
  };
}
