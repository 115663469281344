import { RootState } from "./index";
import { ActionContext } from "vuex/types";
import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";

export type TopUpNavigationState = {
  pageNumber: number;
  redirectTo: string;
  verifyStatus: string;
  topUpStatus: string;
};

const topUpNavigationInitialState = {
  pageNumber: 1,
  redirectTo: "",
  verifyStatus: "",
  topUpStatus: "",
};

type TopUpNavigationContext = ActionContext<TopUpNavigationState, RootState>;

const setToUpStepActionMutation = actionMutationFactory<
  TopUpNavigationState,
  number,
  RootState
>("setTopUpStep", (state, data) => {
  state.pageNumber = data;
});

const setToUpRedirectActionMutation = actionMutationFactory<
  TopUpNavigationState,
  string,
  RootState
>("setTopUpRedirect", (state, data) => {
  state.redirectTo = data;
});

const setVerifyStatusActionMutation = actionMutationFactory<
  TopUpNavigationState,
  string,
  RootState
>("setVerifyStatus", (state, data) => {
  state.verifyStatus = data;
});

const setTopUpStatusActionMutation = actionMutationFactory<
  TopUpNavigationState,
  string,
  RootState
>("setTopUpStatus", (state, data) => {
  state.topUpStatus = data;
});

const resetTopUpNavigationStateActionMutation = resetStateActionMutationFactory<
  TopUpNavigationState,
  RootState
>("resetTopUpNavigationState", topUpNavigationInitialState);

export const topUpNavigation = {
  state: {
    ...topUpNavigationInitialState,
  },
  actions: {
    ...setToUpStepActionMutation.actions,
    ...setToUpRedirectActionMutation.actions,
    ...setVerifyStatusActionMutation.actions,
    ...setTopUpStatusActionMutation.actions,
    ...resetTopUpNavigationStateActionMutation.actions,
    goToNextTopUp(context: TopUpNavigationContext) {
      context.commit("goToNextTopUp");
    },
    goToPreviousTopUp(context: TopUpNavigationContext) {
      context.commit("goToPreviousTopUp");
    },
    resetStatuses(context: TopUpNavigationContext) {
      context.commit("resetStatuses");
    },
  },
  mutations: {
    ...setToUpStepActionMutation.mutations,
    ...setToUpRedirectActionMutation.mutations,
    ...setVerifyStatusActionMutation.mutations,
    ...setTopUpStatusActionMutation.mutations,
    ...resetTopUpNavigationStateActionMutation.mutations,
    goToNextTopUp(state: TopUpNavigationState) {
      state.pageNumber++;
    },
    goToPreviousTopUp(state: TopUpNavigationState) {
      state.pageNumber--;
    },
    resetStatuses(state: TopUpNavigationState) {
      state.redirectTo = "";
      state.verifyStatus = "";
      state.topUpStatus = "";
    },
  },
};
