import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes from "@/shared/router";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import store from "../store";

/**
 * Note: Add `login` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. loginHome)
 */
const mobileUploadRoutes: Array<RouteRecordRaw> = [
  {
    path: routePaths.ssoLogin,
    name: routeNames.ssoLogin,
    component: () =>
      import(/* webpackChunkName: "loginSso" */ "../views/SsoLogin.vue"),
    meta: {
      requiresAuth: false,
    },
    props(route) {
      return route.query || {};
    },
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(
        /* webpackChunkName: "loginNotFound" */ "../../shared/views/NotFound.vue"
      ),
    // To force any screen in SsoLogin redirect
    /** FIXME: To change depending on planned behavior */
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.state.userInfo.isAuthenticated;
      if (isAuthenticated) {
        return next({ name: routeNames.ssoLogin });
      } else {
        return next({ name: routeNames.ssoLogin });
      }
    },
  },
  ...sharedRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: mobileUploadRoutes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.userInfo.isAuthenticated;

  // If going to a page that requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      return next();
    }

    return next({ name: routeNames.login });
  }

  // If going to a page that does not require authentication
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // Some pages (like Login page) should not be accessed if user is logged in
    if (isAuthenticated && to.meta.requiresAuth === false) {
      return next({ name: routeNames.mobileUploadCode });
    }

    return next();
  }

  next();
});

export default router;
