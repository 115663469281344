<template>
  <div class="sidebar">
    <div :class="isMobileScreen ? 'sidebar-mobile' : 'sidebar-desktop'">
      <div class="sidebar__brand-logo" v-if="!isMobileScreen">
        <brand-logo v-if="!isCollapsed" />
        <img
          v-else
          src="../../../assets/images/Logo.svg"
          class="sidebar__brand-logo-only"
        />
      </div>
      <a-menu
        mode="inline"
        theme="dark"
        v-model:selectedKeys="selectedKeys"
        @click="isMobileScreen && closeSidebar()"
      >
        <!-- SIDEBAR ITEMS -->
        <!-- Pass title as empty so there will be no tooltip on ant menu -->
        <a-menu-item
          v-for="route in sidebarRouteMenu"
          title=""
          :key="route.route"
          @click="!isMobileScreen && closeSidebar()"
        >
          <router-link
            :to="route.route"
            :class="[
              isCollapsed
                ? 'sidebar-menu-item--collapsed'
                : 'sidebar-menu-item',
            ]"
          >
            <img
              v-if="route.icon"
              :src="route.icon"
              class="sidebar-menu-item__route-icon"
            />
            <span v-if="!isCollapsed" class="sidebar-menu-item__text">
              {{ route.text }}
            </span>
          </router-link>
        </a-menu-item>
      </a-menu>

      <!-- USER ACTIONS -->
      <div class="sidebar-mobile__user-actions">
        <!-- USER PROFILE -->
        <router-link
          :to="{
            name: routeNames.settings,
            params: { tab: 'profile' },
          }"
          class="sidebar-menu-item sidebar-menu-item--block"
          @click="closeSidebar"
        >
          <div class="sidebar-menu-item__multi-content">
            <div
              :class="[
                'sidebar-menu-item__multi-content__icon-wrapper',
                'sidebar-menu-item__multi-content__icon-wrapper--avatar',
              ]"
            >
              <a-avatar class="sidebar-menu-item__avatar">
                {{ userInitials }}
              </a-avatar>
            </div>
            <div
              :class="[
                'sidebar-menu-item__multi-content__text',
                'sidebar-menu-item__multi-content__text--profile',
                'user-description',
              ]"
            >
              <span class="user-name">{{ userName }}</span>
              <!-- Word break email at "@". &#8203 is the character code for line break. -->
              <span class="user-email">
                {{ replace(userEmail, "@", isMobileScreen ? "&#8203;@" : "@") }}
              </span>
            </div>
            <div class="sidebar-menu-item__multi-content__profile-arrow">
              <RightOutlined />
            </div>
          </div>
        </router-link>

        <!-- Jira Widget -->
        <div class="sidebar-menu-item" @click="handleJiraWidgetSupport">
          <div class="sidebar-menu-item__multi-content">
            <div class="sidebar-menu-item__multi-content__icon-wrapper">
              <img
                class="sidebar-menu-item__multi-content__icon"
                :src="JiraWidgetIcon"
              />
            </div>
            <div class="sidebar-menu-item__multi-content__text">
              {{ t("Get support") }}
            </div>
          </div>
        </div>

        <!-- LOGOUT -->
        <div class="sidebar-menu-item" @click="logoutUser">
          <div class="sidebar-menu-item__multi-content">
            <div class="sidebar-menu-item__multi-content__icon-wrapper">
              <img
                class="sidebar-menu-item__multi-content__icon"
                :src="LogoutIcon"
              />
            </div>
            <div class="sidebar-menu-item__multi-content__text">
              {{ t("Logout") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.sidebar {
  width: 100%;
  height: 100%;

  > .sidebar-desktop,
  > .sidebar-mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-menu {
    background-color: $sidebar-menu-bg-color;
    flex: auto;
    overflow-y: auto;

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
    }

    &-root {
      border-right: 1px solid $separator-color;
    }
    &-item {
      padding: 0px $padding-sm !important;
      &:hover,
      &:focus {
        > span {
          background-color: $sidebar-menu-item-focused;
          border-radius: 6px;
        }
      }

      & > span {
        padding-left: $padding-sm;
        // Sidebar item icon
        & > a > img {
          filter: $siderbar-icon-filter;
        }
        // Sidebar item text
        & > a > span {
          margin-left: $margin-sm;
        }
      }
    }

    &-inline-collapsed {
      text-align: center;

      .ant-menu-item {
        & > span {
          padding: $padding-sm;
          border-radius: 6px;
        }
      }
    }
  }

  // Override antdv's styling for selected menu item
  .ant-menu-dark {
    .ant-menu-item > span > a {
      color: $sidebar-menu-items-text !important;
    }
    .ant-menu-item-selected > span {
      background-color: $sidebar-menu-item-focused;
      border-radius: 6px;

      & > a {
        color: $sidebar-menu-items-text-active !important;

        .sidebar-menu-item__route-icon {
          filter: $siderbar-icon-filter-active;
        }
      }
    }

    // Override antdv's styling for disabled menu item
    .ant-menu-item-disabled {
      &:hover {
        & > span {
          background-color: transparent;
        }
      }
      & > span > a {
        color: $sidebar-menu-items-disabled-text !important;
        opacity: unset;
      }
    }
  }

  &__brand-logo {
    margin: $margin-lg 0 0 $margin-lg;
    max-width: 90px;
    img {
      height: 30px;
    }
    &-only {
      height: 4vh;
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: $screen-md) {
    &-mobile {
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      // Menu Items : Fill remaining space
      .ant-menu {
        flex-grow: 1;
      }

      // User Actions to be shown on Mobile
      &__user-actions {
        min-height: 75px;
        min-width: 100%;

        .sidebar-menu-item {
          &__avatar {
            background-color: $avatar-bg-color;
            font-size: $avatar-font-size;
            font-weight: $avatar-font-weight;
            font-style: $avatar-font-style;
            .ant-avatar-string {
              left: 40%;
            }
          }
        }
        .user-description {
          line-height: 0px;
          .user-name {
            line-height: 18px;
            font-weight: $font-bold;
            font-size: $sidebar-user-font-size;
          }
          .user-name::after {
            content: "\a";
            white-space: pre;
          }
          .user-email {
            line-height: 18px;
            word-wrap: break-word;
            color: $sidebar-menu-item-email;
          }
        }
        .logout-text {
          line-height: 18px;
          font-weight: $font-bold;
          color: $sidebar-menu-items-text !important;
        }
      }
    }
  }

  /** Separate CSS for manual hiding of user actions 
   * section of sidebare on 768px and below screen size */
  &-mobile {
    &__user-actions {
      padding: $sidebar-user-actions-padding;
      border-top: 1px solid $separator-color;
      display: none;
      flex: none;
      @media screen and (max-width: $screen-md) {
        display: block;
      }
    }
  }
}

/** Sepratre CSS to avoid confusion with the .sidebar */
.sidebar-menu-item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &--block {
    display: block;
  }
  &__multi-content {
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 0 24px;
    gap: 12px;
    min-height: 40px;
    &__text {
      flex: auto;
      color: $sidebar-menu-items-text;
      text-align: left;
      padding: 9px 0;
      &--profile {
        padding: 0;
      }
    }
    &__icon-wrapper {
      min-width: 32px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 0;
      &--avatar {
        padding: 4px 0;
      }
    }
    &__icon {
      height: 20px;
      width: 20px;
      filter: $siderbar-icon-filter;
    }
    &__profile-arrow {
      padding: 13px 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { RightOutlined } from "@ant-design/icons-vue";
import BrandLogo from "@/shared/components/icons/BrandLogo.vue";
import { useSidebarItems } from "@/shared/composables/sidebarRoutes";
import { useRouter } from "vue-router";
import routeNames from "@/corporate/router/routeNames";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import replace from "lodash/replace";
import { useStore } from "vuex";
import { InternalVerificationStatus } from "@/../__generated__/globalTypes";
import useLogout from "@/shared/composables/useLogout";
import { isCorporateSite } from "@/shared/utils/config";
import { windowSizeSimpleStore } from "@/shared/store/simpleStore/windowSizeData";
import remove from "lodash/remove";
import routePaths from "@/shared/router/routePaths";
import { getNameInitials } from "@/shared/utils/stringHelper";
import JiraWidgetIcon from "@/assets/images/icons/common/navbar-jira-widget.svg";
import LogoutIcon from "@/assets/images/icons/dashboard/Logout.svg";
import { useJiraWidgetSupport } from "@/shared/composables/useJiraWidgetSupport";
import { useUserStatus } from "@/shared/composables/useUserStatus";

export default defineComponent({
  props: {
    isCollapsed: {
      type: Boolean,
    },
  },
  setup(_, { emit }) {
    const t = useI18n().t;
    const {
      sidebarItemsTopGroup,
      sidebarItemsBottomGroup,
      sidebarItemsMobileViewGroup,
      sidebarItemsUnverifiedGroup,
    } = useSidebarItems();
    const router = useRouter();
    const selectedKeys = ref<string[]>([]);
    const store = useStore();
    const userName = computed(() => store.state.userInfo.user.name);
    const userEmail = computed(() => store.state.userInfo.user.email);
    const kycStatus = computed(
      () => store.state.userInfo.user.internalVerificationStatus
    );
    const isCryptoPermitted = computed(
      () =>
        store.state.userInfo?.accountPermissions?.isCryptoExchangePermitted ??
        false
    );
    const { logoutUser, logoutLoading } = useLogout();
    const closeSidebar = () => {
      emit("closeSidebar");
    };
    const isMobileScreen = computed(() => windowSizeSimpleStore.isMobile);
    const permittedSidebarItemsTopGroup = ref(sidebarItemsTopGroup);
    const permittedSidebarItemsBottomGroup = ref(sidebarItemsBottomGroup);
    const permittedSidebarItemsMobileViewGroup = ref(
      sidebarItemsMobileViewGroup
    );

    onMounted(async () => {
      // Display Crypto Exchange tab when user is verified and cyrpto is allowed
      if (
        kycStatus.value !== InternalVerificationStatus.VERIFIED ||
        !isCryptoPermitted.value
      ) {
        remove(permittedSidebarItemsTopGroup.value, (item) => {
          return item.route === routePaths.cryptoExchange;
        });
        remove(permittedSidebarItemsMobileViewGroup.value, (item) => {
          return item.route === routePaths.cryptoExchange;
        });
      }
    });

    watchEffect(() => {
      /**
       * Update the active item to the current route
       * if there are no active item set yet.
       */
      const path = router.currentRoute.value.path;
      if (
        isEmpty(selectedKeys.value) ||
        first(selectedKeys.value) === "/" ||
        !selectedKeys.value.includes(path)
      ) {
        // Only use the base route for setting the active sidebar item
        selectedKeys.value = [`/${path.split("/")[1]}`];
      }
    });

    const { isInternalVerificationStatusVerified } = useUserStatus();

    // fetching whether we loop the mobile menu or the desktop menu
    const sidebarRouteMenu = computed(() => {
      // For Verified Users
      if (isInternalVerificationStatusVerified) {
        return isMobileScreen.value
          ? permittedSidebarItemsMobileViewGroup.value
          : permittedSidebarItemsTopGroup.value;
      }

      // For Unverified Users
      return sidebarItemsUnverifiedGroup;
    });

    const userInitials = computed(() => getNameInitials(userName.value));

    // Jira Widget
    const { openJira, closeJira, isJiraOpen } = useJiraWidgetSupport();

    const handleJiraWidgetSupport = () => {
      closeSidebar();
      if (!isJiraOpen.value) {
        openJira();
        return;
      }

      closeJira();
    };

    return {
      t,
      replace,
      isMobileScreen,
      selectedKeys,
      permittedSidebarItemsTopGroup,
      permittedSidebarItemsBottomGroup,
      permittedSidebarItemsMobileViewGroup,
      kycStatus,
      InternalVerificationStatus,
      userName,
      userEmail,
      logoutUser,
      logoutLoading,
      closeSidebar,
      isCorporateSite,
      routeNames,
      sidebarRouteMenu,
      userInitials,
      LogoutIcon,
      JiraWidgetIcon,
      handleJiraWidgetSupport,
    };
  },
  components: {
    BrandLogo,
    RightOutlined,
  },
  emits: ["closeSidebar"],
});
</script>
