import { computed } from "vue";

export enum KycNavigationSteps {
  SubmissionStatus = "submission-status",
  UploadDocumentId = "upload-document-id",
  DocumentIdDetails = "document-id-details",
  UploadProofOfAddress = "upload-proof-of-address",
  ProofOfAddressDetails = "proof-of-address-details",
  UploadSelfie = "upload-selfie",
}

export enum NumericKycNavigationSteps {
  SubmissionStatus = 1,
  UploadDocumentId = 2,
  DocumentIdDetails = 3,
  UploadProofOfAddress = 4,
  ProofOfAddressDetails = 5,
  UploadSelfie = 6,
}

export const KycNavigationStepsCount = computed(() => {
  // convert enum to an array, remove double data since enum will separate the values and keys of enum to create an array
  const arrayKycSteps = Object.values(NumericKycNavigationSteps).filter((v) =>
    isNaN(Number(v))
  );

  // return the length of array , in this case returns 6
  return arrayKycSteps.length;
});
