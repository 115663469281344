import routePaths from "@/shared/router/routePaths";
import { useI18n } from "vue-i18n";
import dashboardIcon from "@/assets/images/icons/dashboard/dashboard.svg";
import walletIcon from "@/assets/images/icons/dashboard/wallets.svg";
import paymentIcon from "@/assets/images/icons/dashboard/payments.svg";
import historyIcon from "@/assets/images/icons/dashboard/history.svg";
import newExchangeIcon from "@/assets/images/icons/dashboard/newExchange.svg";
import tariffIcon from "@/assets/images/icons/dashboard/tariff.svg";
import cryptoIcon from "@/assets/images/icons/dashboard/crypto-exchange.svg";
import verificationIcon from "@/assets/images/icons/dashboard/verification-menu.svg";
import insertItemsIf from "@dynameyes/js-utils/insertItemsIf";
import { allowFiatExchange } from "@/shared/utils/config";

type SideBarItemsType = {
  text: string;
  route: string;
  icon: string;
};

export function useSidebarItems(): {
  sidebarItemsTopGroup: SideBarItemsType[];
  sidebarItemsBottomGroup: SideBarItemsType[];
  sidebarItemsMobileViewGroup: SideBarItemsType[];
  sidebarItemsUnverifiedGroup: SideBarItemsType[];
} {
  const t = useI18n().t;

  return {
    sidebarItemsTopGroup: [
      {
        text: t("Dashboard"),
        route: routePaths.home,
        icon: dashboardIcon,
      },
      {
        text: t("Accounts"),
        route: routePaths.wallets,
        icon: walletIcon,
      },
      {
        text: t("Payments"),
        route: routePaths.payments,
        icon: paymentIcon,
      },
      {
        text: t("History"),
        route: routePaths.history,
        icon: historyIcon,
      },
      ...insertItemsIf(allowFiatExchange, [
        {
          text: t("Exchange"),
          route: routePaths.fiatExchange,
          icon: newExchangeIcon,
        },
      ]),
      {
        text: t("Crypto"),
        route: routePaths.cryptoExchange,
        icon: cryptoIcon,
      },
      {
        text: t("Tariff & fees"),
        route: routePaths.tariff,
        icon: tariffIcon,
      },
    ],
    sidebarItemsBottomGroup: [
      // {
      //   text: t("SUPPORT"),
      //   route: routePaths.support,
      //   // TODO: Update support icon
      //   icon: "supportIcon",
      // },
    ],
    sidebarItemsMobileViewGroup: [
      {
        text: t("Dashboard"),
        route: routePaths.home,
        icon: dashboardIcon,
      },
      {
        text: t("Accounts"),
        route: routePaths.wallets,
        icon: walletIcon,
      },
      {
        text: t("Payments"),
        route: routePaths.payments,
        icon: paymentIcon,
      },
      {
        text: t("History"),
        route: routePaths.history,
        icon: historyIcon,
      },
      ...insertItemsIf(allowFiatExchange, [
        {
          text: t("Exchange"),
          route: routePaths.fiatExchange,
          icon: newExchangeIcon,
        },
      ]),
      {
        text: t("Crypto"),
        route: routePaths.cryptoExchange,
        icon: cryptoIcon,
      },
      {
        text: t("Tariff & fees"),
        route: routePaths.tariff,
        icon: tariffIcon,
      },
    ],
    sidebarItemsUnverifiedGroup: [
      {
        text: t("Verification"),
        route: routePaths.splashScreenVerification,
        icon: verificationIcon,
      },
      {
        text: t("Accounts"),
        route: routePaths.splashScreenAccounts,
        icon: walletIcon,
      },
      {
        text: t("Payments"),
        route: routePaths.splashScreenPayments,
        icon: paymentIcon,
      },
      {
        text: t("History"),
        route: routePaths.splashScreenHistory,
        icon: historyIcon,
      },
      {
        text: t("Exchange"),
        route: routePaths.splashScreenExchange,
        icon: newExchangeIcon,
      },
    ],
  };
}
