import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/login/store";

export type UserInfoState = {
  isAuthenticated: boolean;
};

const userInfoInitialState = {
  isAuthenticated: false,
};

export type UserInfoPayload = {
  token: string;
};

const loginSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("loginSuccess", (state) => {
  state.isAuthenticated = true;
});

const logoutSuccessActionMutation = resetStateActionMutationFactory<
  UserInfoState,
  RootState
>("logoutSuccess", userInfoInitialState);

export const userInfo = {
  state: {
    ...userInfoInitialState,
  },
  actions: {
    ...loginSuccessActionMutation.actions,
    ...logoutSuccessActionMutation.actions,
  },
  mutations: {
    ...loginSuccessActionMutation.mutations,
    ...logoutSuccessActionMutation.mutations,
  },
};
