import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '../../../assets/images/Logo.svg'


const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar__brand-logo"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_0,
  class: "sidebar__brand-logo-only"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "sidebar-menu-item__text"
}
const _hoisted_6 = { class: "sidebar-mobile__user-actions" }
const _hoisted_7 = { class: "sidebar-menu-item__multi-content" }
const _hoisted_8 = {
  class: /*@__PURE__*/_normalizeClass([
                'sidebar-menu-item__multi-content__icon-wrapper',
                'sidebar-menu-item__multi-content__icon-wrapper--avatar',
              ])
}
const _hoisted_9 = {
  class: /*@__PURE__*/_normalizeClass([
                'sidebar-menu-item__multi-content__text',
                'sidebar-menu-item__multi-content__text--profile',
                'user-description',
              ])
}
const _hoisted_10 = { class: "user-name" }
const _hoisted_11 = { class: "user-email" }
const _hoisted_12 = { class: "sidebar-menu-item__multi-content__profile-arrow" }
const _hoisted_13 = { class: "sidebar-menu-item__multi-content" }
const _hoisted_14 = { class: "sidebar-menu-item__multi-content__icon-wrapper" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "sidebar-menu-item__multi-content__text" }
const _hoisted_17 = { class: "sidebar-menu-item__multi-content" }
const _hoisted_18 = { class: "sidebar-menu-item__multi-content__icon-wrapper" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "sidebar-menu-item__multi-content__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_brand_logo = _resolveComponent("brand-logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_RightOutlined = _resolveComponent("RightOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMobileScreen ? 'sidebar-mobile' : 'sidebar-desktop')
    }, [
      (!_ctx.isMobileScreen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (!_ctx.isCollapsed)
              ? (_openBlock(), _createBlock(_component_brand_logo, { key: 0 }))
              : (_openBlock(), _createElementBlock("img", _hoisted_3))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_menu, {
        mode: "inline",
        theme: "dark",
        selectedKeys: _ctx.selectedKeys,
        "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event)),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isMobileScreen && _ctx.closeSidebar()))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sidebarRouteMenu, (route) => {
            return (_openBlock(), _createBlock(_component_a_menu_item, {
              title: "",
              key: route.route,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.isMobileScreen && _ctx.closeSidebar()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: route.route,
                  class: _normalizeClass([
              _ctx.isCollapsed
                ? 'sidebar-menu-item--collapsed'
                : 'sidebar-menu-item',
            ])
                }, {
                  default: _withCtx(() => [
                    (route.icon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: route.icon,
                          class: "sidebar-menu-item__route-icon"
                        }, null, 8, _hoisted_4))
                      : _createCommentVNode("", true),
                    (!_ctx.isCollapsed)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.text), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to", "class"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["selectedKeys"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: {
            name: _ctx.routeNames.settings,
            params: { tab: 'profile' },
          },
          class: "sidebar-menu-item sidebar-menu-item--block",
          onClick: _ctx.closeSidebar
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_avatar, { class: "sidebar-menu-item__avatar" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.userInitials), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.userName), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.replace(_ctx.userEmail, "@", _ctx.isMobileScreen ? "​@" : "@")), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_RightOutlined)
              ])
            ])
          ]),
          _: 1
        }, 8, ["to", "onClick"]),
        _createElementVNode("div", {
          class: "sidebar-menu-item",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleJiraWidgetSupport && _ctx.handleJiraWidgetSupport(...args)))
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("img", {
                class: "sidebar-menu-item__multi-content__icon",
                src: _ctx.JiraWidgetIcon
              }, null, 8, _hoisted_15)
            ]),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.t("Get support")), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "sidebar-menu-item",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.logoutUser && _ctx.logoutUser(...args)))
        }, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("img", {
                class: "sidebar-menu-item__multi-content__icon",
                src: _ctx.LogoutIcon
              }, null, 8, _hoisted_19)
            ]),
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t("Logout")), 1)
          ])
        ])
      ])
    ], 2)
  ]))
}