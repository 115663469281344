import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/corporate/store";
import { User } from "@/shared/types/user";
import { Company } from "@/shared/types/company";
import { User_user_User } from "@/api/onboarding/__generated__/User";
import { TokenAuthenticate_tokenAuthenticate_Authentication_user } from "@/api/authentication/__generated__/TokenAuthenticate";

export type VerificationDocument = {
  hasError: boolean;
  verificationStatus: string;
};

export type AccountPermission = {
  isCryptoWithdrawalPermitted: boolean;
  isCryptoDepositPermitted: boolean;
  isCryptoExchangePermitted: boolean;
  allowedFiatCurrenciesForCryptoWithdrawal: string[] | null;
  allowedFiatCurrenciesForCryptoDeposit: string[] | null;
  isCardAcquiringPermitted: boolean;
  allowedFiatCurrenciesForCardAcquiring: string[] | null;
  isRussianBankTransferPermitted: boolean;
  isRussianCardTransferPermitted: boolean;
  isUkraineBankTransferPermitted: boolean;
  isUkraineCardTransferPermitted: boolean;
  isIsraelBankTransferPermitted: boolean;
  isIsraelCardTransferPermitted: boolean;
  isFiatExchangePermitted: boolean;
  allowedFiatCurrencyForFiatExchange: string[] | null;
  isAllowedEEA: boolean;
  isAllowedLocalGBP: boolean;
  isAllowedInternational: boolean;
  allowedCurrencyForInternationalTopUp: string[] | null;
  allowedCurrencyForCardTransfer: string[] | null;
};

export type CompanyVerificationDocument = {
  hasError: boolean;
  documents: {
    verificationStatus: string;
    documentType: string;
  };
};

export type UserInfoState = {
  isAuthenticated: boolean;
  isOtpSetup: boolean | null;
  isMissingFieldsBtn: boolean;
  // for resend mobile confirmation
  mobileOtpLastSent: Date | null;
  // for send/resend email confirmation
  emailOtpLastSent: Date | null;
  // for send otp using sms
  sendOtpViaSmsLastSent: Date | null;
  token: string;
  user: Partial<User>;
  accountPermissions: Partial<AccountPermission>;
  company: Partial<Company>;
  idDocument: Partial<VerificationDocument>;
  addressDocument: Partial<VerificationDocument>;
  selfie: Partial<VerificationDocument>;
  questionnaire: Partial<VerificationDocument>;
  companyDocuments: Partial<CompanyVerificationDocument>;
};

export type UserInfoPayload =
  TokenAuthenticate_tokenAuthenticate_Authentication_user & {
    token: string;
    mobile: string;
    isMissingFieldsBtn: boolean;
    accountPermissions: AccountPermission;
    idDocument: VerificationDocument;
    addressDocument: VerificationDocument;
    selfie: VerificationDocument;
    questionnaire: VerificationDocument;
    companyDocuments: CompanyVerificationDocument;
  };

const userInfoInitialState = {
  isAuthenticated: false,
  isOtpSetup: false,
  isMissingFieldsBtn: false,
  mobileOtpLastSent: null,
  emailOtpLastSent: null,
  sendOtpViaSmsLastSent: null,
  token: "",
  user: {
    id: "",
    name: "",
    email: "",
    mobile: "",
    nationality: null,
    pendingPhone: "",
    pendingEmail: "",
    internalVerificationStatus: null,
    personalVerificationStatus: null,
    companyVerificationStatus: null,
    currentVerificationPage: null,
    apiCompanyId: null,
    isEmailConfirmed: false,
    isPhoneConfirmed: false,
    hasBackupCodesSetup: false,
    address: "",
    primaryCurrency: "EUR",
    avatarUrl: "",
  },
  accountPermissions: {
    isCryptoWithdrawalPermitted: false,
    isCryptoDepositPermitted: false,
    isCryptoExchangePermitted: false,
    allowedFiatCurrenciesForCryptoWithdrawal: null,
    allowedFiatCurrenciesForCryptoDeposit: null,
    isCardAcquiringPermitted: false,
    allowedFiatCurrenciesForCardAcquiring: null,
    isRussianBankTransferPermitted: false,
    isRussianCardTransferPermitted: false,
    isUkraineBankTransferPermitted: false,
    isUkraineCardTransferPermitted: false,
    isIsraelBankTransferPermitted: false,
    isIsraelCardTransferPermitted: false,
    isFiatExchangePermitted: false,
    allowedFiatCurrencyForFiatExchange: null,
    isAllowedEEA: false,
    isAllowedLocalGBP: false,
    isAllowedInternational: false,
    allowedCurrencyForInternationalTopUp: null,
    allowedCurrencyForCardTransfer: null,
  },
  idDocument: {
    hasError: false,
    verificationStatus: "",
  },
  addressDocument: {
    hasError: false,
    verificationStatus: "",
  },
  selfie: {
    hasError: false,
    verificationStatus: "",
  },
  questionnaire: {
    hasError: false,
    verificationStatus: "",
  },
  companyDocuments: {
    hasError: false,
    documents: {
      verificationStatus: "",
      documentType: "",
    },
  },
  //Company object
  company: {
    companyName: null, //FIXME: might be multiple [] later on.... see: #1qn6hd4 -zeplin design
  },
};

// Account Permissions
const accountPermissionsActionMutations = actionMutationFactory<
  UserInfoState,
  AccountPermission,
  RootState
>("accountPermission", (state, permissions) => {
  state.accountPermissions.isCryptoWithdrawalPermitted =
    permissions.isCryptoWithdrawalPermitted;
  state.accountPermissions.isCryptoDepositPermitted =
    permissions.isCryptoDepositPermitted;
  state.accountPermissions.isCryptoExchangePermitted =
    permissions.isCryptoExchangePermitted;
  state.accountPermissions.allowedFiatCurrenciesForCryptoWithdrawal =
    permissions.allowedFiatCurrenciesForCryptoWithdrawal;
  state.accountPermissions.allowedFiatCurrenciesForCryptoDeposit =
    permissions.allowedFiatCurrenciesForCryptoDeposit;
  state.accountPermissions.isCardAcquiringPermitted =
    permissions.isCardAcquiringPermitted;
  state.accountPermissions.allowedFiatCurrenciesForCardAcquiring =
    permissions.allowedFiatCurrenciesForCardAcquiring;
  state.accountPermissions.isRussianBankTransferPermitted =
    permissions.isRussianBankTransferPermitted;
  state.accountPermissions.isRussianCardTransferPermitted =
    permissions.isRussianCardTransferPermitted;
  state.accountPermissions.isUkraineBankTransferPermitted =
    permissions.isUkraineBankTransferPermitted;
  state.accountPermissions.isUkraineCardTransferPermitted =
    permissions.isUkraineCardTransferPermitted;
  state.accountPermissions.isIsraelBankTransferPermitted =
    permissions.isIsraelBankTransferPermitted;
  state.accountPermissions.isIsraelCardTransferPermitted =
    permissions.isIsraelCardTransferPermitted;
  state.accountPermissions.isFiatExchangePermitted =
    permissions.isFiatExchangePermitted;
  state.accountPermissions.allowedFiatCurrencyForFiatExchange =
    permissions.allowedFiatCurrencyForFiatExchange;
  state.accountPermissions.isAllowedEEA = permissions.isAllowedEEA;
  state.accountPermissions.isAllowedLocalGBP = permissions.isAllowedLocalGBP;
  state.accountPermissions.isAllowedInternational =
    permissions.isAllowedInternational;
  state.accountPermissions.allowedCurrencyForInternationalTopUp =
    permissions.allowedCurrencyForInternationalTopUp;
  state.accountPermissions.allowedCurrencyForCardTransfer =
    permissions.allowedCurrencyForCardTransfer;
});

const otpSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("otpSuccess", (state) => {
  state.isOtpSetup = true;
});

const isMissingFieldsActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoState,
  RootState
>("isMissingFields", (state, payload) => {
  state.isMissingFieldsBtn = payload.isMissingFieldsBtn;
});

// Store user last mobile OTP Sent so it won't reset on every refresh
const mobileOtpSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("mobileOtpLastSent", (state) => {
  state.mobileOtpLastSent = new Date();
});

// Store user last email OTP Sent so it won't reset on every refresh
const emailOtpSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("emailOtpLastSent", (state) => {
  state.emailOtpLastSent = new Date();
});

// Store user last send otp using sms
const sendOtpViaSmsSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("sendOtpViaSmsLastSent", (state) => {
  state.sendOtpViaSmsLastSent = new Date();
});

const loginOtpActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("loginOtpUpdate", (state, user) => {
  state.user.hasBackupCodesSetup = user.hasBackupCodesSetup;
  state.user.isEmailConfirmed = user.isEmailConfirmed;
  state.user.isPhoneConfirmed = user.isPhoneConfirmed;
});

const userInfoUpdateActionMutation = actionMutationFactory<
  UserInfoState,
  User_user_User,
  RootState
>("updateUserInfo", (state, user) => {
  state.user.name = user.name;
  state.user.email = user.email ?? "";
  state.user.mobile = user?.phone ?? "";
  state.user.id = user.id;
  state.user.nationality = user.nationality;
  state.user.pendingPhone = user.pendingPhone;
  state.user.pendingEmail = user.pendingEmail;
  state.user.internalVerificationStatus = user.internalVerificationStatus;
  state.user.personalVerificationStatus = user.personalVerificationStatus;
  state.user.companyVerificationStatus = user.companyVerificationStatus;
  state.user.currentVerificationPage = user.currentVerificationPage;
  state.user.apiCompanyId = user.apiCompanyId;
  state.user.isEmailConfirmed = user.isEmailConfirmed;
  state.user.isPhoneConfirmed = user.isPhoneConfirmed;
  state.user.isPolicyConfirmed = user.isPolicyConfirmed;
  state.user.hasBackupCodesSetup = user.hasBackupCodesSetup;
  state.isOtpSetup = user.is2faEnabled;

  //Temporary: to remove when connected with backend
  state.company.companyName = null;
  state.user.address = "7 Guild Street London, E1W 6FU, the UK";
  state.user.primaryCurrency = "EUR";
});

const loginSuccessActionMutation = actionMutationFactory<
  UserInfoState,
  UserInfoPayload,
  RootState
>("loginSuccess", (state, payload) => {
  state.isAuthenticated = true;
  state.token = payload.token;

  state.idDocument = {
    hasError: payload.idDocument ? payload.idDocument.hasError : false,
    verificationStatus: payload.idDocument
      ? payload.idDocument.verificationStatus
      : "",
  };

  state.addressDocument = {
    hasError: payload.addressDocument
      ? payload.addressDocument.hasError
      : false,
    verificationStatus: payload.addressDocument
      ? payload.addressDocument.verificationStatus
      : "",
  };

  state.selfie = {
    hasError: payload.selfie ? payload.selfie.hasError : false,
    verificationStatus: payload.selfie ? payload.selfie.verificationStatus : "",
  };

  state.questionnaire = {
    hasError: payload.questionnaire ? payload.questionnaire.hasError : false,
    verificationStatus: payload.questionnaire
      ? payload.questionnaire.verificationStatus
      : "",
  };

  state.companyDocuments = {
    hasError: payload?.companyDocuments?.hasError ?? false,
    documents: payload?.companyDocuments?.documents ?? [],
  };

  state.user.id = payload.id;
  state.user.name = payload.name;
  state.user.email = payload.email ?? "";
  state.user.mobile = payload.mobile;
  state.user.nationality = payload.nationality;
  state.user.pendingPhone = payload.pendingPhone;
  state.user.internalVerificationStatus = payload.internalVerificationStatus;
  state.user.personalVerificationStatus = payload.personalVerificationStatus;
  state.user.companyVerificationStatus = payload.companyVerificationStatus;
  state.user.currentVerificationPage = payload.currentVerificationPage;
  state.user.apiCompanyId = payload.apiCompanyId;
  state.user.isEmailConfirmed = payload.isEmailConfirmed;
  state.user.isPhoneConfirmed = payload.isPhoneConfirmed;
  state.user.hasBackupCodesSetup = payload.hasBackupCodesSetup;
  state.isOtpSetup = payload.is2faEnabled;

  //Temporary: to remove when connected with backend
  state.company.companyName = null;
  state.user.address = "7 Guild Street London, E1W 6FU, the UK";
  state.user.primaryCurrency = "EUR";
});

const logoutSuccessActionMutation = resetStateActionMutationFactory<
  UserInfoState,
  RootState
>("logoutSuccess", userInfoInitialState);

export const userInfo = {
  state: {
    ...userInfoInitialState,
  },
  actions: {
    ...userInfoUpdateActionMutation.actions,
    ...loginSuccessActionMutation.actions,
    ...logoutSuccessActionMutation.actions,
    ...otpSuccessActionMutation.actions,
    ...mobileOtpSuccessActionMutation.actions,
    ...emailOtpSuccessActionMutation.actions,
    ...sendOtpViaSmsSuccessActionMutation.actions,
    ...loginOtpActionMutation.actions,
    ...accountPermissionsActionMutations.actions,
    ...isMissingFieldsActionMutation.actions,
  },
  mutations: {
    ...userInfoUpdateActionMutation.mutations,
    ...loginSuccessActionMutation.mutations,
    ...logoutSuccessActionMutation.mutations,
    ...otpSuccessActionMutation.mutations,
    ...mobileOtpSuccessActionMutation.mutations,
    ...emailOtpSuccessActionMutation.mutations,
    ...sendOtpViaSmsSuccessActionMutation.mutations,
    ...loginOtpActionMutation.mutations,
    ...accountPermissionsActionMutations.mutations,
    ...isMissingFieldsActionMutation.mutations,
  },
};
