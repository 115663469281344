/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { RootState } from "./index";
import { ActionContext } from "vuex/types";
import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { questionnairePrivateSideBarMenuItems } from "@/private/router/routeMenuItems";
import { QuestionnaireNavigationSteps } from "@/shared/types/questionnaireNavigationSteps";

export type QuestionnaireNavigationState = {
  activeItem: QuestionnaireNavigationSteps;
  percentage: number;
};

const questionnaireNavigationInitialState = {
  activeItem: QuestionnaireNavigationSteps.Welcome,
  percentage: 10,
};

type QuestionnaireNavigationContext = ActionContext<
  QuestionnaireNavigationState,
  RootState
>;

// TODO: Move to utils
const computeQuestionnairePercentage = (
  kycStep: QuestionnaireNavigationSteps
) => {
  const menuItems = questionnairePrivateSideBarMenuItems();
  if (!menuItems) return 0;
  const currentItemIndex = findIndex(menuItems, { name: kycStep }) + 1;
  return (currentItemIndex / menuItems.length) * 100;
};

const updateQuestionnaireStep = actionMutationFactory<
  QuestionnaireNavigationState,
  QuestionnaireNavigationSteps,
  RootState
>("updateQuestionnaireStep", (state, data) => {
  state.activeItem = data;
});

const updateQuestionnairePercentage = actionMutationFactory<
  QuestionnaireNavigationState,
  number,
  RootState
>("updateQuestionnairePercentage", (state, data) => {
  state.percentage = data;
});

const resetQuestionnaireNavigationState = resetStateActionMutationFactory<
  QuestionnaireNavigationState,
  RootState
>("resetQuestionnaireNavigationState", questionnaireNavigationInitialState);

export const questionnaireNavigation = {
  state: {
    ...questionnaireNavigationInitialState,
  },
  actions: {
    ...updateQuestionnairePercentage.actions,
    ...resetQuestionnaireNavigationState.actions,
    updateQuestionnaireStep(
      context: QuestionnaireNavigationContext,
      questionnaireStep: QuestionnaireNavigationSteps
    ) {
      context.commit("updateQuestionnaireStep", questionnaireStep);

      // update questionnaire percentage
      const percentage = computeQuestionnairePercentage(questionnaireStep);
      context.commit("updateQuestionnairePercentage", percentage);
    },
    goToNextQuestionnaire(context: QuestionnaireNavigationContext) {
      context.commit("goToNextQuestionnaire");
    },
    goToPreviousQuestionnaire(context: QuestionnaireNavigationContext) {
      context.commit("goToPreviousQuestionnaire");
    },
  },
  mutations: {
    ...updateQuestionnaireStep.mutations,
    ...updateQuestionnairePercentage.mutations,
    ...resetQuestionnaireNavigationState.mutations,
    goToNextQuestionnaire(state: QuestionnaireNavigationState) {
      const menuItems = questionnairePrivateSideBarMenuItems();
      if (!menuItems) return;

      const currentItem = state.activeItem;
      const currentItemIndex = findIndex(menuItems, {
        name: currentItem,
      });
      const nextItem = get(menuItems, currentItemIndex + 1);
      if (!nextItem) return;
      if (nextItem.action) nextItem.action();
    },
    goToPreviousQuestionnaire(state: QuestionnaireNavigationState) {
      const menuItems = questionnairePrivateSideBarMenuItems();
      if (!menuItems) return;

      const currentItem = state.activeItem;
      const currentItemIndex = findIndex(menuItems, {
        name: currentItem,
      });
      const previousItem = get(menuItems, currentItemIndex - 1);
      if (!previousItem) return;
      if (previousItem.action) previousItem.action();
    },
  },
};
