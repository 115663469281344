<template>
  <a-col type="flex" class="user-menu-popup--details-button--col-2">
    <BasicButton
      class="user-menu-popup__user-details-btn"
      type="text"
      @click="handleSettingsKYCRedirect"
    >
      <a-row class="mx-8">
        <a-col class="mr-8 flex items-center">
          <a-avatar class="user-menu-popup__avatar">
            {{ userInitials }}
          </a-avatar>
        </a-col>
        <a-col class="flex flex-col justify-center text-left">
          <div class="user-menu-popup--details-button__fullname">
            {{ userName }}
          </div>
          <div class="user-menu-popup--details-button__site-settings">
            {{ isCorporateSite ? t("Business") : t("Personal") }}
          </div>
        </a-col>
      </a-row>
    </BasicButton>
  </a-col>
  <a-col
    type="flex"
    class="user-menu-popup--details-settings-icon"
    v-if="!isTemporarilySuspended"
  >
    <BasicButton
      class="user-menu-popup__details-btn"
      type="text"
      @click="handleSettingsRedirect"
    >
      <img class="user-menu-popup__icon" :src="SettingsIcon" />
    </BasicButton>
  </a-col>
  <a-col type="flex" class="user-menu-popup--details-settings-icon">
    <BasicButton
      class="user-menu-popup__details-btn"
      type="text"
      @click="handleJiraWidgetSupport"
    >
      <img class="user-menu-popup__icon" :src="NavbarJiraWidgetIcon" />
    </BasicButton>
  </a-col>
  <!-- since the padding alone of the user-menu-popup--details-logout-icon is 15px,we will only add 20px on the right -->
  <a-col type="flex" class="user-menu-popup--details-logout-icon pr-20">
    <BasicButton
      @click="logoutUser"
      class="user-menu-popup__details-btn"
      type="text"
      :icon-only="logoutLoading"
      :loading="logoutLoading"
      :disabled="logoutLoading"
    >
      <img class="user-menu-popup__icon" :src="LogoutIcon" />
    </BasicButton>
  </a-col>
</template>

<style lang="scss">
.user-menu-popup {
  &__avatar {
    background-color: $avatar-bg-color;
    font-size: $avatar-font-size;
    font-weight: $avatar-font-weight;
    font-style: $avatar-font-style;
    .ant-avatar-string {
      left: 40%;
    }
  }
  &__icon {
    filter: $light-grey-filter;
  }
  &__user-details-btn {
    height: 50px;
    padding: 0;
  }
  &--details-button__site-settings {
    color: $dark-grey;
  }
}
.user-menu-popup__details-btn__check-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 12px;
}
.user-menu-popup__details-btn {
  height: 50px;
}
.user-menu-popup--details-user-icon {
  img {
    height: 24px;
    width: 26px;
  }
}
.user-menu-popup--details-settings-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 24px;
    width: 26px;
  }
}
.user-menu-popup--details-logout-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 24px;
    width: 26px;
  }

  .basic-button.ant-btn-icon-only {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    svg.anticon-spin {
      height: 50px;
      width: 26px;
    }
  }
}
.user-menu-popup--details-button--col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 24px;
    width: 26px;
  }
}
.user-menu-popup--details-button__fullname {
  height: 16px;
  line-height: 16px;
  font-weight: $font-bold;
  font-size: 13px;
  color: $text-color;
  text-align: left;
}
.user-menu-popup--details-button__number {
  font-size: 13px;
  color: $text-color-secondary;
  height: 30px;
  text-align: left;
}
</style>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getNameInitials } from "@/shared/utils/stringHelper";
import { useI18n } from "vue-i18n";
import routeNames from "@/private/router/routeNames";
import BasicButton from "@/shared/components/buttons/BasicButton.vue";
import { InternalVerificationStatus } from "@/../__generated__/globalTypes";
import { isCorporateSite } from "@/shared/utils/config";
import useLogout from "@/shared/composables/useLogout";
import { useSecureImageQuery } from "@/shared/composables/api/useSecureImageQuery";
import UserVerifiedIcon from "@/assets/images/icons/common/check.svg";
import UserIcon from "@/assets/images/icons/common/user-recolored.png";
import NavbarJiraWidgetIcon from "@/assets/images/icons/common/navbar-jira-widget.svg";
import SettingsIcon from "@/assets/images/icons/dashboard/Settings.svg";
import LogoutIcon from "@/assets/images/icons/dashboard/Logout.svg";
import { useJiraWidgetSupport } from "@/shared/composables/useJiraWidgetSupport";

export default defineComponent({
  props: {
    isTemporarilySuspended: Boolean,
  },
  setup(props) {
    const t = useI18n().t;
    const store = useStore();
    const router = useRouter();
    const userName = computed(() => store.state.userInfo.user.name);
    const userMobile = computed(() => store.state.userInfo.user.mobile);
    const { fetchImage } = useSecureImageQuery();
    const userCompanyName = computed(() =>
      isCorporateSite ? store.state.userInfo.company.companyName : null
    );

    const { openJira, closeJira, isJiraOpen } = useJiraWidgetSupport();

    const handleJiraWidgetSupport = () => {
      if (!isJiraOpen.value) {
        openJira();
        return;
      }

      closeJira();
    };

    const userAvatar = computed(() =>
      fetchImage(store.state.userInfo.user.avatarUrl)
    );

    const userInitials = computed(() => getNameInitials(userName.value));

    const { logoutUser, logoutLoading } = useLogout();

    const handleSettingsRedirect = () => {
      router.push({ name: routeNames.settings });
    };

    const handleSettingsKYCRedirect = () => {
      // don't do anything on temporarily suspended
      if (props.isTemporarilySuspended) return;

      // else, redirect to settings
      router.push({
        name: routeNames.settings,
        params: { tab: "profile" },
      });
    };

    const isUserVerified = computed(
      () =>
        store.state.userInfo.user.internalVerificationStatus ===
        InternalVerificationStatus.VERIFIED
    );

    return {
      userName,
      userInitials,
      handleSettingsRedirect,
      logoutUser,
      routeNames,
      logoutLoading,
      isUserVerified,
      handleSettingsKYCRedirect,
      userCompanyName,
      userMobile,
      userAvatar,
      UserVerifiedIcon,
      UserIcon,
      t,
      isCorporateSite,
      SettingsIcon,
      NavbarJiraWidgetIcon,
      LogoutIcon,
      handleJiraWidgetSupport,
    };
  },
  components: {
    BasicButton,
  },
});
</script>
