import { allowCookieConsent, config } from "@/shared/utils/config";
import { i18nTranslate } from "@/plugins/i18n";
// import * as lightThemePrimaryColor from "../../theme/light-theme.js";
// import * as darkThemePrimaryColor from "../../theme/dark-theme.js";
// import { isCorporateSite } from "@/shared/utils/config";

// Script for cookie consent plugin
window.gtmId = config.GTMid;
window.dataLayer = window.dataLayer || [];

function gtag() {
  window.dataLayer.push(arguments);
}

window.gtag = gtag;

// configure our cookie consent
// see: https://www.npmjs.com/package/@motomedialab/cookie-consent
window.cookieConsent = {
  // on mount of page, hide cookie banner if the cookie consent is not allowed
  hide: !allowCookieConsent,

  // ability to change basic styling options
  styling: {
    buttonColor: "#4770d6",
  },

  // text values
  text: {
    description: i18nTranslate(""),
    cookiePolicy:
      i18nTranslate(
        "We use cookies to enhance overall user experience. Please read our "
      ) + ":link(cookie policy).",
    buttons: {
      acceptAll: i18nTranslate("Accept all"),
      onlyEssentials: i18nTranslate("Only essentials"),
    },
  },

  // link to privacy policy (optional, hides link if not defined)
  cookiePolicy: config.cookiePolicyUrl,

  // per cookie settings. This gives the ability to customise the cookie settings
  // these are in five categories, ad, analytics, functionality, personalization & security
  // and you can have one, none, all or some of these categories
  settings: {
    functionality: {
      essential: true,
      description: i18nTranslate(
        "There are some cookies that we have to include in order for certain web pages to function. For this reason, they do not require your consent."
      ),
    },
    security: {
      essential: true,
      description: i18nTranslate(
        "We use these cookies to help identify and prevent potential security risks. For this reason, they do not require your consent."
      ),
    },
    ad: {
      title: i18nTranslate("Ads data"),
      description: i18nTranslate(
        "We use Google Ads to measure the performance of our advertising campaigns and to provide advertising based on visitors' interests. We do not use cookies to collect personal information."
      ), // if no cookies are provided, a 'more details' option will be hidden for this preference
    },
  },
};
