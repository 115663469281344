/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty } from "lodash";
import { RouteLocationNormalized } from "vue-router";

// Use this when restricting routes. See [src/../router/index.ts]
export const allowedRoutes = (
  routeRuleName: string,
  to: RouteLocationNormalized,
  routes: string[]
) => {
  if (!isEmpty(routeRuleName)) console.log("Route Rule Name: ", routeRuleName);
  return routes.every((route) => to.name !== route);
};

/** Use for forced page redirection
 *
 * checks if router should force page redirect instead when
 * going to a specific route/s (restrictedRoutes) with
 * a specified condition (userCondition - e.g. VERIFIED account,
 * no companyId, etc.)
 *
 * see [src/../router/index.ts]
 */
export const forceRedirectIf = (
  to: RouteLocationNormalized,
  restrictedRoutes: string[],
  userCondition: boolean,
  redirection: () => void,
  redirectionContext: string
) => {
  if (!allowedRoutes(``, to, restrictedRoutes) && userCondition) {
    console.log("Forced Redirect: ", redirectionContext);
    redirection();
  }
};
