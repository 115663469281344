import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { logsApiGql } from "@/api/logging/loggingEndpoint";
import { LogLevel } from "../../../__generated__/globalTypes";

export default function useLogging() {
  const logsApiGqlMutation = useCustomMutation(logsApiGql);

  const submitToApi = async (
    logLevel = LogLevel.INFO,
    message: string,
    moreDetailsObject = {}
  ) => {
    const createLogResponse = await logsApiGqlMutation.mutate({
      input: {
        logLevel,
        message: message,
        context: JSON.stringify(moreDetailsObject),
      },
    });

    console.log("createLogResponse", createLogResponse);
    return createLogResponse;
  };

  const submitInfoToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(LogLevel.INFO, message, moreDetailsObject);
  };

  const submitWarningToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(LogLevel.WARNING, message, moreDetailsObject);
  };

  const submitErrorToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(LogLevel.ERROR, message, moreDetailsObject);
  };

  const submitFatalToApi = (message: string, moreDetailsObject = {}) => {
    return submitToApi(LogLevel.FATAL, message, moreDetailsObject);
  };

  return {
    submitToApi,
    submitInfoToApi,
    submitWarningToApi,
    submitErrorToApi,
    submitFatalToApi,
  };
}
