/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { RootState } from "./index";
import { ActionContext } from "vuex/types";
import { privatePersonalQuestionnaireSidebarMenuItems } from "@/private/router/routeMenuItems";
import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import {
  PersonalQuestionnaireNavigationState,
  PersonalQuestionnaireNavigationSteps,
} from "@/shared/types/personalQuestionnaireNavigationSteps";

const personalQuestionnaireNavigationInitialState = {
  activeItem: PersonalQuestionnaireNavigationSteps.BASIC_INFO,
  percentage: 10,
};
const computePQPercentage = (pqSteps: PersonalQuestionnaireNavigationSteps) => {
  const menuItems = privatePersonalQuestionnaireSidebarMenuItems();
  if (!menuItems) return 0;
  const currentItemIndex = findIndex(menuItems, { name: pqSteps }) + 1;
  return (currentItemIndex / menuItems.length) * 100;
};

type PersonalQuestionnaireNavigationContext = ActionContext<
  PersonalQuestionnaireNavigationState,
  RootState
>;

const updatePersonalQuestionnaireStep = actionMutationFactory<
  PersonalQuestionnaireNavigationState,
  PersonalQuestionnaireNavigationSteps,
  RootState
>("updatePersonalQuestionnaireStep", (state, data) => {
  state.activeItem = data;
});

const updatePQPercentageActionMutation = actionMutationFactory<
  PersonalQuestionnaireNavigationState,
  number,
  RootState
>("updatePersonalQuestionnaireStep", (state, data) => {
  state.percentage = data;
});

const resetPersonalQuestionnaireNavigationState =
  resetStateActionMutationFactory<
    PersonalQuestionnaireNavigationState,
    RootState
  >(
    "resetPersonalQuestionnaireNavigationState",
    personalQuestionnaireNavigationInitialState
  );

export const personalQuestionnaireNavigation = {
  state: {
    ...personalQuestionnaireNavigationInitialState,
  },
  actions: {
    ...updatePQPercentageActionMutation.actions,
    ...resetPersonalQuestionnaireNavigationState.actions,
    updatePersonalQuestionnaireStep(
      context: PersonalQuestionnaireNavigationContext,
      pqSteps: PersonalQuestionnaireNavigationSteps
    ) {
      context.commit("updatePersonalQuestionnaireStep", pqSteps);
    },
    goToNextPersonalQuestionnaire(
      context: PersonalQuestionnaireNavigationContext
    ) {
      context.commit("goToNextPersonalQuestionnaire");
    },
    goToPreviousPersonalQuestionnaire(
      context: PersonalQuestionnaireNavigationContext
    ) {
      context.commit("goToPreviousPersonalQuestionnaire");
    },
  },
  mutations: {
    ...updatePQPercentageActionMutation.mutations,
    ...updatePersonalQuestionnaireStep.mutations,
    ...resetPersonalQuestionnaireNavigationState.mutations,
    goToNextPersonalQuestionnaire(state: PersonalQuestionnaireNavigationState) {
      const menuItems = privatePersonalQuestionnaireSidebarMenuItems();
      if (!menuItems) return;

      const currentItem = state.activeItem;
      const currentItemIndex = findIndex(menuItems, {
        name: currentItem,
      });
      const nextItem = get(menuItems, currentItemIndex + 1);
      if (!nextItem) return;
      if (nextItem.action) nextItem.action();
    },
    goToPreviousPersonalQuestionnaire(
      state: PersonalQuestionnaireNavigationState
    ) {
      const menuItems = privatePersonalQuestionnaireSidebarMenuItems();
      if (!menuItems) return;

      const currentItem = state.activeItem;
      const currentItemIndex = findIndex(menuItems, {
        name: currentItem,
      });
      const previousItem = get(menuItems, currentItemIndex - 1);
      if (!previousItem) return;
      if (previousItem.action) previousItem.action();
    },
  },
};
