import { createApp, h } from "vue";
import router from "./router";
import store from "./store";
import App from "./PrivateApp.vue";
import { apolloProvider } from "@/api/graphqlClient/apolloClient";
import { useDebugApp } from "@/shared/composables/useDebugApp";
import { allowDebugConsole } from "@/shared/utils/config";

/**
 * Setup private site
 */
export default createApp({
  setup() {
    apolloProvider;

    if (allowDebugConsole) {
      useDebugApp();
    }

    return {};
  },

  render: () => h(App),
})
  .use(store)
  .use(router);
