<template>
  <figure class="brand-logo">
    <router-link to="/">
      <img
        src="../../../assets/images/LogoWithText.svg"
        :alt="t('{brandName}', { brandName: config.merchantBrand })"
      />
    </router-link>
  </figure>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    return {
      t,
      config,
    };
  },
});
</script>
