<template>
  <!-- Reference for properties https://2x.antdv.com/components/modal#API -->
  <a-modal
    class="update-info-modal"
    :maskStyle="maskStyle"
    v-bind="$attrs"
    :centered="true"
    @cancel="onCloseModal"
  >
    <slot></slot>
    <template #title>
      <slot name="title"></slot>
      <div class="overview-card-layout__custom-title-border">
        <div class="overview-card-layout__custom-title-border-item"></div>
        <div class="overview-card-layout__custom-title-border-item"></div>
        <div class="overview-card-layout__custom-title-border-item"></div>
      </div>
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
    <template #cancelText>
      <slot name="cancelText"></slot>
    </template>
    <template #okText>
      <slot name="okText"></slot>
    </template>
    <template #closeIcon>
      <slot name="closeIcon">
        <!-- Default close icon -->
        <img
          :src="isCorporateSite ? WhiteCross : BlackCross"
          :alt="t('close')"
        />
      </slot>
    </template>
  </a-modal>
</template>

<style lang="scss">
.update-info-modal {
  width: $modal-width;
  .ant-modal-content {
    background-color: $modal-bg;
    box-shadow: unset; // Remove default box-shadow for all modals

    .ant-modal-header {
      background-color: $modal-bg;
    }
  }

  .basic-button {
    width: clamp(260px, 50vw, 360px);
    min-width: unset;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { getGlobalSassVariables } from "@/shared/utils/globalScssVariables";
import BlackCross from "@/assets/images/icons/common/cross-black.svg";
import WhiteCross from "@/assets/images/icons/common/cross-white.svg";
import { isCorporateSite } from "@/shared/utils/config";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup(props, { emit }) {
    return {
      maskStyle: {
        backgroundColor: props.maskColor,
      },
      onCloseModal: () => {
        emit("onCancelModal");
      },
      t: useI18n().t,
      BlackCross,
      WhiteCross,
      isCorporateSite,
    };
  },
  emits: ["onCancelModal"],
  props: {
    maskColor: {
      type: String,
      default: getGlobalSassVariables().modalMaskColor,
    },
  },
});
</script>
