import gql from "graphql-tag";

export const logoutGql = gql`
  mutation Logout {
    logout {
      ... on GenericSuccess {
        message
        success
      }

      ... on ResponseErrors {
        errors {
          message
          code
          displayMessage

          ... on InternalError {
            message
            code
            displayMessage
            context
          }
        }
      }
    }
  }
`;
