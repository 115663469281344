import "./shared/utils/logger";
import i18n from "@/plugins/i18n";
import { useAntDesignComponents } from "@/plugins/antDesignComponents";
import app from "@/entryApp";
import { useFormLoadingDirective } from "./shared/directives/formLoadingDirective";
import { windowSizeChecker } from "@/shared/utils/windowSizeChecker";
import VueCreditCardValidation from "vue-credit-card-validation";
import { antTabResizeInkbar } from "@/shared/utils/antTabResizeInkbar";
import "@/plugins/gtm/cookieConsent.js";
import { createGtm } from "@gtm-support/vue-gtm";
import router from "@/private/router";
import {
  config,
  allowGTMDebugging,
  allowGTMPlugin,
} from "@/shared/utils/config";

// For full demo checkout the following - https://github.com/wuori/vue-credit-card-validation/blob/master/example/index.html
app.use(VueCreditCardValidation);
useFormLoadingDirective(app);
/**
 * Setup plugins for whole site
 */
useAntDesignComponents(app)
  .use(i18n)
  .use(
    createGtm({
      enabled: allowGTMPlugin,
      id: config.GTMid,
      vueRouter: router,
      debug: allowGTMDebugging,
    })
  )
  .mount("#app");

/**
 * Setup Media Queries for Window Size
 **/
windowSizeChecker();

/**
 * Workaround for ant-design's tab inkbar not changing translate.x on resize.
 * See: #2v8qytg
 **/
antTabResizeInkbar();
