import { isCorporateSite, isSsoLogin } from "@/shared/utils/config";

/**
 * Use this to import configured site app
 */
let app;
if (isCorporateSite) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  app = require("@/corporate/entryApp").default;
} else if (isSsoLogin) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  app = require("@/login/entryApp").default;
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  app = require("@/private/entryApp").default;
}

export default app;
