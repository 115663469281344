import { makeSimpleStore } from "@/shared/utils/simpleStoreUtils";
import { UnwrapNestedRefs } from "@vue/reactivity";

export type LoginData = {
  username?: string;
  password?: string;
  code?: string;
  captcha?: string;
};

export type LoginSimpleStore = UnwrapNestedRefs<
  LoginData & { resetStore: () => void }
>;

export const loginSimpleStore: LoginSimpleStore = makeSimpleStore<LoginData>({
  username: "",
  password: "",
  code: "",
  captcha: "",
});
