import { makeDefaultErrorToast, makeToast } from "@/shared/utils/toast";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import { apiErrorCodes } from "@/shared/utils/constants";
import { i18nTranslate } from "@/plugins/i18n";
import includes from "lodash/includes";
import replace from "lodash/replace";
import { regex } from "@/shared/utils/constants";
import { config } from "@/shared/utils/config";

export type GqlError = {
  isValid: boolean;
  message: string | null;
  errors: {
    message: string;
    code?: string;
    __typename: string;
    displayMessage?: string;
    context?: string;
  }[];
};

/**
 * Use this to parse error from GQL response. Errors are not handled
 * on apollo link since we are using a custom union error responses
 *
 * All custom error message based on code should be handled here
 *
 * @deprecated
 */
export const handleGqlError = (
  response,
  successFunction: () => void
): { isValid: boolean } => {
  const result = { isValid: true };

  if (isPlainObject(response?.data)) {
    for (const responseItem in response?.data) {
      const responseItemValue = response?.data[responseItem];

      if (!isEmpty(responseItemValue?.errors)) {
        /// TODO Add logic here to customize message based on error code if needed
        /// TODO Apply localization once it is supported outside of setup function
        makeToast("error", "Error", responseItemValue?.errors[0].message);
        result.isValid = false;
      }
    }
  }

  if (successFunction && result.isValid) {
    successFunction();
  }

  return result;
};

export enum HideIfErrorCode {
  ALL_ERRORS = "*",
}

/**
 * Use this to parse error from GQL response. Errors are not handled
 * on apollo link since we are using a custom union error responses
 *
 * All custom error message based on code should be handled here
 *
 * To be used in conjunction with [parseGqlResponse]
 */
export const parseGqlError = (response, hideIfErrorCode?): GqlError => {
  const result = { isValid: true, message: null, errors: [] };
  const showErrors =
    hideIfErrorCode !== HideIfErrorCode.ALL_ERRORS &&
    (hideIfErrorCode == null || response?.errors[0].code != hideIfErrorCode);

  if (!isEmpty(response?.errors)) {
    /// TODO Add logic here to customize message based on error code if needed
    /** Fetch Internal Error Specific Response Context*/
    if (response?.errors[0].code === apiErrorCodes.INTERNAL_ERROR) {
      try {
        const contextResponse = JSON.parse(response?.errors[0].context);
        if (
          contextResponse.errors.includes(
            "The specified Company is already exists. code:-102"
          )
        ) {
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate("The specified company already exists.")
          );
          // Catch Error for Crypto Withdrawal when Crypto address is invalid
        } else if (
          contextResponse.status === 1015 &&
          contextResponse.errors[0].includes("Address is invalid")
        ) {
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate("Address is invalid")
          );
        } else if (contextResponse.errors.includes("Amount is not correct")) {
          // Skip error toast display for card topup amount error (#862jqvte5)
        } else if (
          contextResponse.errors.includes(
            "The field FileName must be a string with a maximum length of 100."
          )
        ) {
          // Display error message for file upload with filename > 100
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate("File name must be at most 100 characters long.")
          );
        } else if (
          contextResponse.errors.includes(
            "PS: Method AUTHORIZE was not found."
          ) ||
          contextResponse.errors.includes(
            "Failed to complete Crypto Exchange Create process"
          )
        ) {
          // Custom error message for Crypto Exchange permission error
          makeToast(
            "error",
            i18nTranslate("Error"),
            i18nTranslate(
              "Crypto Exchange is disabled. Please contact {contactEmail}.",
              { contactEmail: config.contactEmail }
            )
          );
        } else {
          // Show error toast for Internal Error only if the showErrors is true
          if (showErrors) {
            let errorMessage = contextResponse.errors;
            // Remove error code when included in the error message
            if (includes(errorMessage, "code:")) {
              errorMessage = replace(
                errorMessage,
                new RegExp(regex.MABErrorCode),
                ""
              );
            }
            makeToast("error", i18nTranslate("Error"), errorMessage);
          }
        }
      } catch (e) {
        if (showErrors) {
          makeDefaultErrorToast();
        }
      }
    } else if (
      response?.errors?.find(
        (error) => error.code === apiErrorCodes.FILE_ALREADY_UPLOADED
      )
    ) {
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate(
          "Mobile document already has an uploaded file. Please refresh the cabinet page on your computer to generate a new code."
        )
      );
    } else if (
      response?.errors?.find(
        (error) => error.code === apiErrorCodes.WAIT_BEFORE_RESENDING
      )
    ) {
      // show the detailed error message instead of just the generic message for this specific error
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate(response?.errors[0]?.displayMessage)
      );
    } else if (showErrors) {
      let errorMessage = response?.errors[0].message;
      // Remove error code when included in the error message
      if (includes(errorMessage, "code:")) {
        errorMessage = replace(
          errorMessage,
          new RegExp(regex.MABErrorCode),
          ""
        );
      }
      makeToast("error", i18nTranslate("Error"), errorMessage);
    }

    result.isValid = false;
    result.message = response?.errors[0].message;
    result.errors = response?.errors;
  }

  return result;
};
