/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import routeNames from "@/private/router/routeNames";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { logoutGql } from "@/api/authentication/logout";
import { wsClientContainer } from "@/api/graphqlClient/wsLink";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  Logout,
  Logout_logout_GenericSuccess,
} from "@/api/authentication/__generated__/Logout";

export default function useLogout() {
  const store = useStore();
  const router = useRouter();

  const logout = useCustomMutation<Logout>(logoutGql);

  const logoutUser = async () => {
    // close ws client connection
    wsClientContainer.wsClient?.close(true, true);
    const logoutMutation = await logout.mutate();
    const parsedGqlResponse = parseGqlResponse<Logout_logout_GenericSuccess>(
      "GenericSuccess",
      logoutMutation
    );

    /** only perform additional logout actions once
     * user has been logged out in server
     */
    if (parsedGqlResponse?.data?.success) {
      // set to false so we can proceed to non-auth page
      store.state.userInfo.isAuthenticated = false;

      // proceed to non auth page
      await router.push({ name: routeNames.login });
    }
  };

  return {
    logoutUser,
    logoutLoading: logout.loading,
  };
}
