/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";

export const getValidPhoneNumber = (value: string, country?: string) => {
  try {
    return parsePhoneNumber(
      value,
      country as CountryCode | undefined
    ).number.toString();
  } catch (e) {
    // Parsing can throw error. In this case, return original string
    return value;
  }
};

/**
 * Fetch details of mobile number (ie. country code, number, etc)
 *
 * @param value
 * @param country
 * @returns phone number details
 */
export const getPhoneNumberDetails = (value: string, country?: string) => {
  try {
    return parsePhoneNumber(value, country as CountryCode | undefined);
  } catch (e) {
    // Parsing can throw error. In this case, return original string
    return value;
  }
};
