<template>
  <NonAuthenticatedLayout>
    <router-view />
  </NonAuthenticatedLayout>
</template>

<style lang="scss">
@import "./../scss/utils/utils";

#app {
  height: 100%;
}
</style>

<script>
import NonAuthenticatedLayout from "@/shared/components/layouts/NonAuthenticatedLayout.vue";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();

    return {
      isAuthenticated: computed(() => store.state.userInfo.isAuthenticated),
    };
  },
  components: {
    NonAuthenticatedLayout,
  },
});
</script>
