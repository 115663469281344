import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, _mergeProps({
    class: "update-info-modal",
    maskStyle: _ctx.maskStyle
  }, _ctx.$attrs, {
    centered: true,
    onCancel: _ctx.onCloseModal
  }), {
    title: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title"),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overview-card-layout__custom-title-border" }, [
        _createElementVNode("div", { class: "overview-card-layout__custom-title-border-item" }),
        _createElementVNode("div", { class: "overview-card-layout__custom-title-border-item" }),
        _createElementVNode("div", { class: "overview-card-layout__custom-title-border-item" })
      ], -1))
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    cancelText: _withCtx(() => [
      _renderSlot(_ctx.$slots, "cancelText")
    ]),
    okText: _withCtx(() => [
      _renderSlot(_ctx.$slots, "okText")
    ]),
    closeIcon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "closeIcon", {}, () => [
        _createElementVNode("img", {
          src: _ctx.isCorporateSite ? _ctx.WhiteCross : _ctx.BlackCross,
          alt: _ctx.t('close')
        }, null, 8, _hoisted_1)
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["maskStyle", "onCancel"]))
}