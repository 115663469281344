import { makeSimpleStore } from "@/shared/utils/simpleStoreUtils";

export type WindowSizeData = {
  mobile: MediaQueryList | null;
  isMobile: boolean;
  medium: MediaQueryList | null;
  isMedium: boolean;
  large: MediaQueryList | null;
  isLarge: boolean;
};

export const windowSizeSimpleStore = makeSimpleStore<WindowSizeData>({
  mobile: null,
  isMobile: false,
  medium: null,
  isMedium: false,
  large: null,
  isLarge: false,
});
