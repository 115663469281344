/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountCurrencies {
  CZK = "CZK",
  EUR = "EUR",
  GBP = "GBP",
  RUB = "RUB",
  USD = "USD",
}

/**
 * An enumeration.
 */
export enum AccountProducts {
  CRYPTO_EXCHANGE_DEPOSIT = "CRYPTO_EXCHANGE_DEPOSIT",
  CRYPTO_EXCHANGE_WITHDRAWAL = "CRYPTO_EXCHANGE_WITHDRAWAL",
  EXCHANGE = "EXCHANGE",
  INCOMING_WIRE = "INCOMING_WIRE",
  INTERNAL_PAYMENT = "INTERNAL_PAYMENT",
  LOAD_ACCOUNT_BY_CARD = "LOAD_ACCOUNT_BY_CARD",
  OUTWARD_WIRE = "OUTWARD_WIRE",
  WITHDRAWAL_TO_CARD = "WITHDRAWAL_TO_CARD",
}

/**
 * An enumeration.
 */
export enum BulletinCategory {
  ANNOUNCEMENT = "ANNOUNCEMENT",
  NEWS = "NEWS",
  PROMO = "PROMO",
}

export enum BusinessActivity {
  CASINOS = "CASINOS",
  CHARITIES = "CHARITIES",
  DIGITAL_CURRENCY = "DIGITAL_CURRENCY",
  INTERMEDIARIES = "INTERMEDIARIES",
  MONEY_SERVICES = "MONEY_SERVICES",
  OTHER = "OTHER",
  PHARMACEUTICAL = "PHARMACEUTICAL",
  PRECIOUS_METALS = "PRECIOUS_METALS",
  PRIVATE_MILITARY = "PRIVATE_MILITARY",
  PRIVATE_SECTOR = "PRIVATE_SECTOR",
  PUBLIC_SECTOR = "PUBLIC_SECTOR",
  REAL_STATE = "REAL_STATE",
}

/**
 * An enumeration.
 */
export enum CardAcquiringPaymentStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum ChargeType {
  CHARGE_TYPE_BEN = "CHARGE_TYPE_BEN",
  CHARGE_TYPE_OUR = "CHARGE_TYPE_OUR",
  CHARGE_TYPE_SHA = "CHARGE_TYPE_SHA",
}

/**
 * An enumeration.
 */
export enum CompanyDocumentType {
  BENEFICIARY = "BENEFICIARY",
  CERODIR = "CERODIR",
  CEROINC = "CEROINC",
  CEROREOF = "CEROREOF",
  DIRDOC = "DIRDOC",
  GOODSTANDING = "GOODSTANDING",
  LEBANKST = "LEBANKST",
  LEOTHER = "LEOTHER",
  MINUTES = "MINUTES",
  REGISTRAR = "REGISTRAR",
  REPDOC = "REPDOC",
  SHARDOC = "SHARDOC",
  UBODOC = "UBODOC",
}

export enum CompanyExistenceTime {
  FROM_1_TO_5_YEARS = "FROM_1_TO_5_YEARS",
  LESS_1_YEAR = "LESS_1_YEAR",
  MORE_5_YEARS = "MORE_5_YEARS",
}

export enum CompanyPlannedTransactions {
  CARD_TRANSFER = "CARD_TRANSFER",
  INTERNAL_PAYMENTS = "INTERNAL_PAYMENTS",
  LOCAL_WIRE_TRANSFER = "LOCAL_WIRE_TRANSFER",
  SEPA_PAYMENT = "SEPA_PAYMENT",
  SWIFT = "SWIFT",
}

/**
 * An enumeration.
 */
export enum CompanyQuestionnaireSection {
  AMOUNT_OF_USE = "AMOUNT_OF_USE",
  BUSINESS_ACTIVITY_OF_THE_CLIENT = "BUSINESS_ACTIVITY_OF_THE_CLIENT",
  CLIENTS_OF_THE_COMPANY = "CLIENTS_OF_THE_COMPANY",
  COMPANY_ADDRESS = "COMPANY_ADDRESS",
  COMPANY_INFORMATION = "COMPANY_INFORMATION",
  COMPANY_QUESTIONNAIRE_SECTION_CONFIRM = "COMPANY_QUESTIONNAIRE_SECTION_CONFIRM",
  COMPANY_REPRESENTATIVES_LIST = "COMPANY_REPRESENTATIVES_LIST",
  CORPORATE_GROUP_OR_ASSOCIATION = "CORPORATE_GROUP_OR_ASSOCIATION",
  DESCRIPTION_OF_THE_OPERATIONS_ON_THE_ACCOUNT = "DESCRIPTION_OF_THE_OPERATIONS_ON_THE_ACCOUNT",
  MAIN_PARTNERS_OF_THE_COMPANY = "MAIN_PARTNERS_OF_THE_COMPANY",
  OTHER_ACCOUNTS = "OTHER_ACCOUNTS",
  SERVICES_ORDERED = "SERVICES_ORDERED",
  SOURCE_OF_COMPANIES_FUND = "SOURCE_OF_COMPANIES_FUND",
  TAX_IDENTIFICATION_NUMBER_TIN_INFORMATION = "TAX_IDENTIFICATION_NUMBER_TIN_INFORMATION",
  TYPES_OF_PAYMENTS_OR_OPERATIONS = "TYPES_OF_PAYMENTS_OR_OPERATIONS",
}

export enum CompanyRoleOfClient {
  DIRECTOR_OR_SHAREHOLDER = "DIRECTOR_OR_SHAREHOLDER",
  EMPLOYEE = "EMPLOYEE",
  SOLE = "SOLE",
}

export enum CompanyServicesIntegration {
  API = "API",
  CABINET = "CABINET",
}

export enum CompanyServicesOrdered {
  LOCAL_PAYMENTS = "LOCAL_PAYMENTS",
  SEPA_TRANSFERS = "SEPA_TRANSFERS",
  WIRE_TRANSFERS = "WIRE_TRANSFERS",
}

export enum CompanyTransactionAmount {
  BET_6K_18K = "BET_6K_18K",
  LESS_6K = "LESS_6K",
  MORE_18K = "MORE_18K",
}

export enum CompanyTransactionFrequency {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum CompanyType {
  CORPORATION = "CORPORATION",
  LLC = "LLC",
  LLP = "LLP",
  LTD = "LTD",
  NONPROFIT = "NONPROFIT",
  OFFSHORE = "OFFSHORE",
  PARTNERSHIP = "PARTNERSHIP",
  SOLE = "SOLE",
  TRUST = "TRUST",
}

/**
 * An enumeration.
 */
export enum CryptoExchangeActions {
  BUY_CRYPTO = "BUY_CRYPTO",
  BUY_FIAT = "BUY_FIAT",
  SELL_CRYPTO = "SELL_CRYPTO",
  SELL_FIAT = "SELL_FIAT",
}

/**
 * An enumeration.
 */
export enum CryptoExchangePaymentStatuses {
  STATUS_FOREX_FAILED = "STATUS_FOREX_FAILED",
  STATUS_FOREX_SUCCESS = "STATUS_FOREX_SUCCESS",
  STATUS_NEW = "STATUS_NEW",
  STATUS_TRANSFER_FAILED = "STATUS_TRANSFER_FAILED",
  STATUS_TRANSFER_SUCCESS = "STATUS_TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum CryptoExchangeStatuses {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NEW = "NEW",
  WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
}

/**
 * An enumeration.
 */
export enum CryptoNetwork {
  BTC = "BTC",
  ERC20 = "ERC20",
  ETH = "ETH",
  LTC = "LTC",
  TRC20 = "TRC20",
}

/**
 * An enumeration.
 */
export enum DocumentUploadStatus {
  ERROR = "ERROR",
  NEW = "NEW",
  SAVED = "SAVED",
}

/**
 * An enumeration.
 */
export enum DocumentVerificationStatus {
  APPROVED = "APPROVED",
  BADCOPY = "BADCOPY",
  BANKSTMNT = "BANKSTMNT",
  EXPIRED = "EXPIRED",
  INCOMPLETE = "INCOMPLETE",
  INPROCESS = "INPROCESS",
  IN_REVIEW = "IN_REVIEW",
  OK = "OK",
  ON_HOLD = "ON_HOLD",
  PENDING_RENEWAL = "PENDING_RENEWAL",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  REJECTED = "REJECTED",
  RESUBMIT = "RESUBMIT",
  SUSCOPY = "SUSCOPY",
  SUSPCOPY = "SUSPCOPY",
  UNDERCONS = "UNDERCONS",
  VERIFIED_WITHOUT_ORIGINAL_DOCUMENTS = "VERIFIED_WITHOUT_ORIGINAL_DOCUMENTS",
  WRONGADDRR = "WRONGADDRR",
  WRONGDOC = "WRONGDOC",
  WRONGLANG = "WRONGLANG",
  WRONGNAME = "WRONGNAME",
}

/**
 * An enumeration.
 */
export enum DomesticILSTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum DomesticILSTransferTransferType {
  CARD = "CARD",
  IBAN = "IBAN",
  IBAN_ISR_ILS = "IBAN_ISR_ILS",
}

export enum DomesticILSType {
  CARD = "CARD",
  IBAN = "IBAN",
  IBAN_ISR_ILS = "IBAN_ISR_ILS",
}

/**
 * An enumeration.
 */
export enum DomesticTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum DomesticUAHTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum DomesticUAHTransferTransferType {
  CARD = "CARD",
  IBAN = "IBAN",
}

export enum DomesticUAHType {
  CARD = "CARD",
  IBAN = "IBAN",
}

/**
 * An enumeration.
 */
export enum EmailConfirmationStatus {
  FAILED = "FAILED",
  INVALID = "INVALID",
  SUCCESS = "SUCCESS",
  UPDATE_FAILED = "UPDATE_FAILED",
}

export enum FlowType {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

/**
 * An enumeration.
 */
export enum InternalTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum InternalVerificationStatus {
  NOT_VERIFIED = "NOT_VERIFIED",
  ONGOING = "ONGOING",
  VERIFIED = "VERIFIED",
}

export enum LogLevel {
  ERROR = "ERROR",
  FATAL = "FATAL",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum MoneyServicesProviders {
  BANKS = "BANKS",
  BUILDING_SOCIETIES = "BUILDING_SOCIETIES",
  CLAIMS_MANAGEMENT = "CLAIMS_MANAGEMENT",
  CREDIT_FIRMS = "CREDIT_FIRMS",
  EMONEY = "EMONEY",
  FINANCIAL_ADVISERS = "FINANCIAL_ADVISERS",
  FINTECH = "FINTECH",
  GENERAL_INSURERS = "GENERAL_INSURERS",
  INVESTMENT_MANAGERS = "INVESTMENT_MANAGERS",
  LIFE_INSURERS = "LIFE_INSURERS",
  MORTGAGE_LENDERS = "MORTGAGE_LENDERS",
  MUTUAL_SOCIETIES = "MUTUAL_SOCIETIES",
  PROVIDERS = "PROVIDERS",
  SOLE_ADVISERS = "SOLE_ADVISERS",
  WEALTH_MANAGERS = "WEALTH_MANAGERS",
}

export enum NumberOfEmployees {
  FROM_11_TO_49 = "FROM_11_TO_49",
  FROM_49 = "FROM_49",
  UP_TO_10 = "UP_TO_10",
}

/**
 * An enumeration.
 */
export enum OCRDocumentStatus {
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
  UNKNOWN = "UNKNOWN",
}

/**
 * An enumeration.
 */
export enum OCRDocumentType {
  DriversLicense = "DriversLicense",
  IdentityCard = "IdentityCard",
  OtherDocument = "OtherDocument",
  Passport = "Passport",
}

/**
 * An enumeration.
 */
export enum Operation {
  CRYPTO_EXCHANGE_DEPOSIT = "CRYPTO_EXCHANGE_DEPOSIT",
  CRYPTO_EXCHANGE_WITHDRAWAL = "CRYPTO_EXCHANGE_WITHDRAWAL",
  DOMESTIC_PAYMENT = "DOMESTIC_PAYMENT",
  DOMESTIC_PAYMENT_ILS = "DOMESTIC_PAYMENT_ILS",
  DOMESTIC_PAYMENT_UAH = "DOMESTIC_PAYMENT_UAH",
  LOAD_ACCOUNT_BY_CARD = "LOAD_ACCOUNT_BY_CARD",
  RUSSIAN_BANK = "RUSSIAN_BANK",
  RUSSIAN_CARD = "RUSSIAN_CARD",
  SEPA_BANK = "SEPA_BANK",
  WIRE = "WIRE",
}

export enum OperationType {
  EXPENSE = "EXPENSE",
  Exchange = "Exchange",
  INCOME = "INCOME",
  IncomingWire = "IncomingWire",
  InternalPayments = "InternalPayments",
  OutwardWire = "OutwardWire",
}

export enum PaymentOrderArchiveStatus {
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  OPEN = "OPEN",
}

export enum PaymentOrderFailedStatus {
  FAILED = "FAILED",
  NOT_FAILED = "NOT_FAILED",
}

/**
 * An enumeration.
 */
export enum PaymentOrderIssues {
  BNF_NOT_VERIFIED = "BNF_NOT_VERIFIED",
  DOCS_NOT_VERIFIED = "DOCS_NOT_VERIFIED",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  KYC_AMOUNT_NOT_PASSED = "KYC_AMOUNT_NOT_PASSED",
  KYC_NO_TIN_INFO = "KYC_NO_TIN_INFO",
  KYC_TYPE_NOT_DECLARED = "KYC_TYPE_NOT_DECLARED",
  NO_DEVICE = "NO_DEVICE",
  NO_QUESTIONNAIRE = "NO_QUESTIONNAIRE",
  PHOTO_ID_NEEDED = "PHOTO_ID_NEEDED",
  UNPAID = "UNPAID",
  VIDEO_VERIFICATION_NEEDED = "VIDEO_VERIFICATION_NEEDED",
}

/**
 * An enumeration.
 */
export enum PaymentOrderSecurityIssues {
  BANK_TRANSFER_ISSUE_NAME_NOT_MATCHED = "BANK_TRANSFER_ISSUE_NAME_NOT_MATCHED",
  CREDIT_CARD_ISSUE_3DS_NOT_PASSED = "CREDIT_CARD_ISSUE_3DS_NOT_PASSED",
  CREDIT_CARD_ISSUE_DIFFERENT_COUNTRIES = "CREDIT_CARD_ISSUE_DIFFERENT_COUNTRIES",
  CREDIT_CARD_ISSUE_EXCEEDS_RISK_LIMIT = "CREDIT_CARD_ISSUE_EXCEEDS_RISK_LIMIT",
  CREDIT_CARD_ISSUE_MULTIPLE_CARDS = "CREDIT_CARD_ISSUE_MULTIPLE_CARDS",
  INSUFFICIENT_RECEIVED = "INSUFFICIENT_RECEIVED",
  IP_ADDRESS_ISSUE_HIGH_RISK = "IP_ADDRESS_ISSUE_HIGH_RISK",
  IP_ADDRESS_ISSUE_NO_SCORE = "IP_ADDRESS_ISSUE_NO_SCORE",
  METHOD_NOT_MATCHED = "METHOD_NOT_MATCHED",
}

export enum PaymentOrderStatus {
  AWAITING_CONFIRMATION = "AWAITING_CONFIRMATION",
  CONFIRMED = "CONFIRMED",
  FAILED = "FAILED",
  NEW = "NEW",
  NOTICE_RECEIVED = "NOTICE_RECEIVED",
  ON_HOLD = "ON_HOLD",
  PAID = "PAID",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  QUEUED = "QUEUED",
  REPROCESS_SUCCESS = "REPROCESS_SUCCESS",
  SUCCESS = "SUCCESS",
}

export enum PaymentOrderType {
  DOMESTIC_ILS_TRANSFER = "DOMESTIC_ILS_TRANSFER",
  DOMESTIC_TRANSFER = "DOMESTIC_TRANSFER",
  DOMESTIC_UAH_TRANSFER = "DOMESTIC_UAH_TRANSFER",
  INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
  RUSSIAN_BANK_TRANSFER = "RUSSIAN_BANK_TRANSFER",
  RUSSIAN_CARD_TRANSFER = "RUSSIAN_CARD_TRANSFER",
  SEPA_BANK = "SEPA_BANK",
  WIRE = "WIRE",
}

/**
 * An enumeration.
 */
export enum PaymentType {
  PAYMENT_TYPE_CARD = "PAYMENT_TYPE_CARD",
  PAYMENT_TYPE_REGULAR = "PAYMENT_TYPE_REGULAR",
  PAYMENT_TYPE_SWIFT = "PAYMENT_TYPE_SWIFT",
}

export enum PaymentTypes {
  ADMINISTRATION_FEES = "ADMINISTRATION_FEES",
  DIGITAL_CURRENCIES_TRADING = "DIGITAL_CURRENCIES_TRADING",
  E_WALLETS = "E_WALLETS",
  FEES = "FEES",
  FX_OPERATIONS = "FX_OPERATIONS",
  INVESTMENTS = "INVESTMENTS",
  OTHER = "OTHER",
  PAYMENTS = "PAYMENTS",
  REFERRAL_PROGRAM = "REFERRAL_PROGRAM",
  SALARY = "SALARY",
}

export enum PersonTitleEnum {
  MR = "MR",
  MRS = "MRS",
  MS = "MS",
}

/**
 * An enumeration.
 */
export enum PersonalDocumentType {
  ADDRESS = "ADDRESS",
  ALIEN = "ALIEN",
  DRIVINGLICENSE = "DRIVINGLICENSE",
  NATIONALID = "NATIONALID",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
  PHOTOID = "PHOTOID",
}

/**
 * An enumeration.
 */
export enum PersonalGeographyOfTransactionsEnum {
  AFR = "AFR",
  ASIA = "ASIA",
  AUS = "AUS",
  BAL = "BAL",
  CAN = "CAN",
  CIS = "CIS",
  EU = "EU",
  LAM = "LAM",
  OFFSHORE = "OFFSHORE",
  OTHER = "OTHER",
  UK = "UK",
  US = "US",
}

/**
 * An enumeration.
 */
export enum PersonalPrimaryUseOfAccountEnum {
  ESTATE = "ESTATE",
  FAMILY = "FAMILY",
  LOAN = "LOAN",
  MEDICAL = "MEDICAL",
  OTHER = "OTHER",
  PRIVATE = "PRIVATE",
  PURCHASE = "PURCHASE",
  STUDY = "STUDY",
}

export enum PersonalQuestionnaireSectionEnum {
  ADDRESSES = "ADDRESSES",
  ADDRESSES_AND_CONTACT_DETAILS = "ADDRESSES_AND_CONTACT_DETAILS",
  CONFIRM = "CONFIRM",
  CONTACT_DETAILS = "CONTACT_DETAILS",
  DEPRECATED = "DEPRECATED",
  ID_DOCUMENT_DETAILS = "ID_DOCUMENT_DETAILS",
  PERSONAL_DETAILS = "PERSONAL_DETAILS",
  PLANNED_INCOMING_TRANSACTION = "PLANNED_INCOMING_TRANSACTION",
  PLANNED_OUTGOING_TRANSACTION = "PLANNED_OUTGOING_TRANSACTION",
  PLANNED_SERVICES = "PLANNED_SERVICES",
  SOURCE_OF_INCOME = "SOURCE_OF_INCOME",
  TAX_INFORMATION = "TAX_INFORMATION",
  USE_OF_ACCOUNT = "USE_OF_ACCOUNT",
}

/**
 * An enumeration.
 */
export enum PersonalTransactionAmountEnum {
  BET_2K_5K = "BET_2K_5K",
  LESS_2K = "LESS_2K",
  MORE_5K = "MORE_5K",
}

/**
 * An enumeration.
 */
export enum PersonalTransactionFrequencyEnum {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

/**
 * An enumeration.
 */
export enum PersonalTransactionsTurnoverEnum {
  BET_10K_30K = "BET_10K_30K",
  BET_3K_5K = "BET_3K_5K",
  BET_5K_10K = "BET_5K_10K",
  LESS_3K = "LESS_3K",
  MORE_30K = "MORE_30K",
}

export enum PlannedPaymentsEnum {
  BORROWED_FUNDS = "BORROWED_FUNDS",
  DIVIDENDS = "DIVIDENDS",
  INHERITANCE = "INHERITANCE",
  OTHER = "OTHER",
  SALARY = "SALARY",
  SAVINGS = "SAVINGS",
}

/**
 * An enumeration.
 */
export enum PlannedServicesEnum {
  CARD_TRANSFERS = "CARD_TRANSFERS",
  INTERNAL_PAYMENTS = "INTERNAL_PAYMENTS",
  SEPA_TRANSFERS = "SEPA_TRANSFERS",
  SWIFT = "SWIFT",
  WIRE_TRANSFERS = "WIRE_TRANSFERS",
}

export enum QuestionnaireStatusEnum {
  ERROR = "ERROR",
  IN_PROCESS = "IN_PROCESS",
  OK = "OK",
}

/**
 * An enumeration.
 */
export enum ReadStatus {
  READ = "READ",
  UNREAD = "UNREAD",
}

/**
 * An enumeration.
 */
export enum ReportReportType {
  CSV_EU = "CSV_EU",
  CSV_US = "CSV_US",
  PDF = "PDF",
  SINGLE_PDF_RECEIPT = "SINGLE_PDF_RECEIPT",
}

export enum ReportTypes {
  CSV_EU = "CSV_EU",
  CSV_US = "CSV_US",
  PDF = "PDF",
  SINGLE_PDF_RECEIPT = "SINGLE_PDF_RECEIPT",
}

export enum RepresentativeType {
  LEGAL_ENTITY = "LEGAL_ENTITY",
  PERSON = "PERSON",
}

/**
 * An enumeration.
 */
export enum RussianBankTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum RussianCardTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

/**
 * An enumeration.
 */
export enum SEPABankTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

export enum SPMethod {
  CC = "CC",
  EMONEY = "EMONEY",
  EUCARD = "EUCARD",
  MT = "MT",
  NONEUCARD = "NONEUCARD",
  PLNPOST = "PLNPOST",
  SP = "SP",
  WIRE = "WIRE",
}

export enum SendNotifications {
  ALL = "ALL",
  NONE = "NONE",
}

export enum SourceOfFinancialResources {
  CREDIT = "CREDIT",
  INCOME = "INCOME",
  INVESTMENT = "INVESTMENT",
  OTHER = "OTHER",
}

/**
 * An enumeration.
 */
export enum SourceOfWealthAndIncomeEnum {
  BUSINESS_ACTIVITIES = "BUSINESS_ACTIVITIES",
  GIFT = "GIFT",
  HERITAGE = "HERITAGE",
  HOLD_PROPERTY = "HOLD_PROPERTY",
  LOAN = "LOAN",
  LOTTERY = "LOTTERY",
  OTHER = "OTHER",
  SALARY = "SALARY",
  SALE_PROPERTY = "SALE_PROPERTY",
}

export enum TemplateStatus {
  NO = "NO",
  YES = "YES",
}

export enum TransactionStatus {
  Canceled = "Canceled",
  Chargebacked = "Chargebacked",
  InProcess = "InProcess",
  Processed = "Processed",
  ReadyToReturn = "ReadyToReturn",
  ReadyToReverse = "ReadyToReverse",
  Refunded = "Refunded",
  Rejected = "Rejected",
  RequestedtoReturn = "RequestedtoReturn",
  Returned = "Returned",
  Reversed = "Reversed",
}

export enum TransactionType {
  CARDACQUIRING = "CARDACQUIRING",
  DOMESTIC = "DOMESTIC",
  DOMESTIC_ILS = "DOMESTIC_ILS",
  DOMESTIC_UAH = "DOMESTIC_UAH",
  EXCHANGE = "EXCHANGE",
  HOLD = "HOLD",
  INEMONEYPAYMENT = "INEMONEYPAYMENT",
  INTERNALPAYMENT = "INTERNALPAYMENT",
  OUTEMONEYPAYMENT = "OUTEMONEYPAYMENT",
  RUSSIANBANK = "RUSSIANBANK",
  RUSSIANCARD = "RUSSIANCARD",
  SEPA = "SEPA",
  WIRE = "WIRE",
}

/**
 * An enumeration.
 */
export enum VerificationStatus {
  FOR_APPROVAL = "FOR_APPROVAL",
  NOT_VERIFIED = "NOT_VERIFIED",
  ONGOING = "ONGOING",
  ON_HOLD = "ON_HOLD",
  REAPPROVAL = "REAPPROVAL",
  REPROCESS_ON_GOING = "REPROCESS_ON_GOING",
  TEMPORARILY_SUSPENDED = "TEMPORARILY_SUSPENDED",
  VERIFIED = "VERIFIED",
}

/**
 * An enumeration.
 */
export enum WireTransferStatus {
  FOREX_FAILED = "FOREX_FAILED",
  FOREX_SUCCESS = "FOREX_SUCCESS",
  NEW = "NEW",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  TRANSFER_SUCCESS = "TRANSFER_SUCCESS",
}

export enum YearlyTurnover {
  FROM_16M = "FROM_16M",
  FROM_6M_TO_15M = "FROM_6M_TO_15M",
  UP_TO_5M = "UP_TO_5M",
}

export interface AccountPermissionInput {
  productName?: (AccountProducts | null)[] | null;
  productType?: string[] | null;
  productCurrencies?: string[] | null;
}

export interface Activate2faInput {
  code: string;
}

export interface AddressInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
  countryCode?: string | null;
}

export interface AuthenticationInput {
  username?: string | null;
  password?: string | null;
  token?: string | null;
  code?: string | null;
  captcha?: string | null;
}

export interface BackupCodesInput {
  code: string;
}

export interface CancelPaymentInput {
  uuid: string;
  recipientEmail?: string | null;
  recipientMobile?: string | null;
}

export interface CheckCardAcquiringInput {
  currencyCode: string;
  amount: any;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCsc: string;
  successRedirect: string;
  failureRedirect: string;
  cancelRedirect: string;
}

export interface CheckCryptoExchangeInput {
  cryptoCurrencyCode: string;
  fiatCurrencyCode: string;
  exchangeAction: CryptoExchangeActions;
  cryptoNetwork?: CryptoNetwork | null;
  amount: number;
  recipientName?: string | null;
}

export interface CheckFiatExchangeInput {
  amount: number;
  fromCurrency: string;
  toCurrency: string;
}

export interface CheckRegistrationInput {
  phone?: string | null;
  email?: string | null;
}

export interface CheckSwiftCodeInput {
  swiftCode: string;
}

export interface CompanyAccountOperationsInput {
  currencies?: (AccountCurrencies | null)[] | null;
  monthlyTurnoverEstimate?: any | null;
}

export interface CompanyAddressInput {
  registeredAddress?: AddressInput | null;
  officeAddress?: AddressInput | null;
  officeIsRegisteredAddress?: boolean | null;
  postAddress?: AddressInput | null;
  postIsRegisteredAddress?: boolean | null;
  phone?: string | null;
  fax?: string | null;
  email?: string | null;
}

export interface CompanyAmountOfUseInput {
  outgoingTransaction?: CompanyOutgoingTransactionInput | null;
  incomingTransaction?: CompanyIncomingTransactionInput | null;
  sizeOfAverageTransfer?: any | null;
  maxSingleTransferAmount?: any | null;
  minSingleTransferAmount?: any | null;
  numberOfClients?: number | null;
}

export interface CompanyBusinessAddressInput {
  businessAddressIsLegalAddress?: boolean | null;
  businessAddress?: CompanyAddressInput | null;
}

export interface CompanyClientBusinessActivityInput {
  reasonForOpeningAccount?: string | null;
  countriesWhereBusinessIsRun?: (string | null)[] | null;
  businessActivityOfClient?: (BusinessActivity | null)[] | null;
  otherBusinessActivity?: string | null;
  specifyIfMoneyServicesOrSimilar?: (MoneyServicesProviders | null)[] | null;
  serviceWebsites?: string | null;
  trademark?: string | null;
  numberOfEmployees?: NumberOfEmployees | null;
  companyExistenceTime?: CompanyExistenceTime | null;
  lastYearTurnoverEur?: YearlyTurnover | null;
}

export interface CompanyClientsInput {
  percentTotalPrivateClients?: number | null;
  percentTotalLegalClients?: number | null;
  countriesWhereCompanyActivitiesConducted?: (string | null)[] | null;
  companyIdentifiesClients?: boolean | null;
}

export interface CompanyCreateRegisterInput {
  companyName?: string | null;
  companyType?: CompanyType | null;
  incorporationCountry?: string | null;
  registrationNumber?: string | null;
  roleOfClient?: CompanyRoleOfClient | null;
}

export interface CompanyGroupOrAssociationInput {
  isCompanyPartOfGroupOrAssociation?: boolean | null;
  describeStructureOfGroupOrAssociation?: string | null;
  roleOfClient?: string | null;
}

export interface CompanyIncomingTransactionInput {
  incomingTransactionsDescription?: string | null;
  frequencyOfTransactions?: CompanyTransactionFrequency | null;
  totalMonthlySpending?: CompanyTransactionAmount | null;
  estimatedMinAmountPerTransaction?: string | null;
  estimatedMaxAmountPerTransaction?: string | null;
  geographyOfIncomingTransfers?: (string | null)[] | null;
  numberOfMonthlyPlannedIncoming?: number | null;
}

export interface CompanyInformationInput {
  genericInformation?: GenericCompanyInformationInput | null;
  legalAddress?: CompanyAddressInput | null;
  businessAddressIsLegalAddress?: boolean | null;
  businessAddress?: CompanyAddressInput | null;
  beneficiaryEntityProfile?: OtherBeneficiaryEntityProfileInput | null;
}

export interface CompanyInstitutionInput {
  name?: string | null;
  country?: string | null;
  accountNumber?: string | null;
}

export interface CompanyMainPartnersInput {
  partners?: (PartnerInput | null)[] | null;
}

export interface CompanyOtherAccountsInput {
  hasAccountsWithOtherInstitutions?: boolean | null;
  institutions?: (CompanyInstitutionInput | null)[] | null;
}

export interface CompanyOutgoingTransactionInput {
  outgoingTransactionsDescription?: string | null;
  frequencyOfTransactions?: CompanyTransactionFrequency | null;
  totalMonthlySpending?: CompanyTransactionAmount | null;
  estimatedMinAmountPerTransaction?: string | null;
  estimatedMaxAmountPerTransaction?: string | null;
  geographyOfOutgoingTransfers?: (string | null)[] | null;
  numberOfMonthlyPlannedOutgoing?: number | null;
}

export interface CompanyRepresentativesInput {
  numberOfRepresentatives?: number | null;
  userInfo?: OtherRepresentativeDetailsInput | null;
  representatives?: (RepresentativeDetailsInput | null)[] | null;
}

export interface CompanyServicesOrderedInput {
  plannedTransactions?: (CompanyPlannedTransactions | null)[] | null;
  servicesOrdered?: (CompanyServicesOrdered | null)[] | null;
  integration?: (CompanyServicesIntegration | null)[] | null;
}

export interface CompanySourceOfFundInput {
  sourceOfFinancialResources?: (SourceOfFinancialResources | null)[] | null;
  otherSourceOfFinancialResources?: string | null;
}

export interface CompanyTaxInformationInput {
  isPayingTaxes?: boolean | null;
  hasTin?: boolean | null;
  tin?: string | null;
  taxResidency?: string | null;
  isTaxResidentOfUs?: boolean | null;
}

export interface CompanyTypesOfPaymentInput {
  typesOfPayments?: (PaymentTypes | null)[] | null;
  detailsOfActivityAndTransactions?: string | null;
}

export interface ConfirmCardAcquiringInput {
  paymentUuid: string;
}

export interface ConfirmCryptoExchangeInput {
  paymentUuid: string;
  code?: string | null;
  cryptoWalletAddress?: string | null;
}

export interface ConfirmEmailInput {
  email: string;
  confirmationCode: string;
}

export interface ConfirmFiatExchangeInput {
  paymentUuid: string;
}

export interface ConfirmPaymentInput {
  uuid: string;
  recipientEmail?: string | null;
  recipientMobile?: string | null;
  code?: string | null;
  token?: string | null;
}

export interface ConfirmPhoneInput {
  phoneNumber: string;
  confirmationCode: string;
  isRegistrationFlow?: boolean | null;
  token?: string | null;
}

export interface ConfirmPolicyInput {
  confirmPolicy: boolean;
}

export interface ConfirmVerifyCardAcquiringPaymentInput {
  paymentUuid: string;
  amount: any;
}

export interface CreateCompanyDocumentInput {
  documentType: CompanyDocumentType;
}

export interface CreateRegisterInput {
  title?: PersonTitleEnum | null;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  nationality?: string | null;
  dateOfBirth?: any | null;
  country?: string | null;
  zipCode?: string | null;
  city?: string | null;
  line1?: string | null;
  line2?: string | null;
  inviteCode?: string | null;
  captcha?: string | null;
  unsubscribeLoginReminderEmail?: boolean | null;
}

export interface CryptoExchangeInput {
  apiDocumentId?: number | null;
  merchantDocumentId?: string | null;
}

export interface DepositDetailsFilterInput {
  currency?: string | null;
}

export interface DomesticPartnerIlsInput {
  firstName: string;
  lastName: string;
}

export interface DomesticPartnerInput {
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  otherName?: string | null;
  countryCode?: string | null;
  address1: string;
  address2?: string | null;
  city?: string | null;
  zip?: string | null;
}

export interface DomesticPartnerUahInput {
  firstName: string;
  lastName: string;
}

export interface DomesticPaymentGbpInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  benSwiftCode?: string | null;
  benAccountNumber: string;
  benSortCode: string;
  partnerDetails: DomesticPartnerInput;
}

export interface DomesticPaymentIlsCalculateInput {
  transferType: DomesticILSType;
  currencyCode: string;
  amount: number;
}

export interface DomesticPaymentIlsInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  purposeId: number;
  transferType: DomesticILSType;
  benCardNumber?: string | null;
  benIbanNumber?: string | null;
  partnerDetails: DomesticPartnerIlsInput;
}

export interface DomesticPaymentUahCalculateInput {
  transferType: DomesticUAHType;
  currencyCode: string;
  amount: number;
}

export interface DomesticPaymentUahInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  purposeId: number;
  transferType: DomesticUAHType;
  benCardNumber?: string | null;
  benIbanNumber?: string | null;
  partnerDetails: DomesticPartnerUahInput;
}

export interface ExternalCorrespondentInput {
  amount: any;
  currencyCode: string;
  feeAmount: any;
  feeCurrencyCode: string;
}

export interface ExternalPartnerInput {
  iban?: string | null;
  swift?: string | null;
  beneficiaryName?: string | null;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  otherName?: string | null;
  countryCode: string;
  beneficiaryFields?: any | null;
}

export interface FeeDetailsInput {
  currency: string;
  operationType: Operation;
  amount?: any | null;
}

export interface ForgotPasswordInput {
  phoneNumber?: string | null;
  email?: string | null;
  captcha?: string | null;
}

export interface GenerateBackupCodesInput {
  generateNew?: boolean | null;
  code?: string | null;
}

export interface GenericCompanyInformationInput {
  companyName?: string | null;
  registrationNumber?: string | null;
  registrationDate?: any | null;
  vatNumber?: string | null;
  incorporationCountry?: string | null;
  isPermitRequiredForActivity?: boolean | null;
  otherPermitRequiredForActivity?: string | null;
  licenseNumber?: string | null;
  usaAccounts?: boolean | null;
  companyType?: CompanyType | null;
  pepsInUbo?: boolean | null;
  proceedingsInitiatedAgainst?: boolean | null;
  submitsFinancialReports?: boolean | null;
  otherFinanceReports?: string | null;
  licenseIssuingAuthority?: string | null;
  registrationPlace?: string | null;
  registeringAuthority?: string | null;
}

export interface GetMaxAmountInput {
  operationType: Operation;
  currency: string;
}

export interface HoldPendingTransactionListFiltersInput {
  currencyCodes?: (string | null)[] | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  pagination?: PaginationInput | null;
}

export interface InternalPartnerInput {
  emailOrMobileOrIban: string;
}

export interface InternalPaymentInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  partnerDetails: InternalPartnerInput;
}

export interface LogInput {
  logLevel: LogLevel;
  message: string;
  context?: any | null;
}

export interface MainPaymentInput {
  amount: any;
  currencyCode: string;
  feeAmount: any;
  feeCurrencyCode: string;
}

export interface MobileDocumentFilter {
  shortCode: string;
}

export interface OtherBeneficiaryEntityProfileInput {
  numberOfShares?: any | null;
  jointStockCompanyOpenTrade?: boolean | null;
}

export interface OtherRepresentativeDetailsInput {
  isDirector?: boolean | null;
  isShareholder?: boolean | null;
  amountOfShares?: any | null;
  isNominee?: boolean | null;
  isOtherRole?: boolean | null;
  position?: string | null;
  representativeType?: RepresentativeType | null;
  isPep?: boolean | null;
  phone?: string | null;
  fax?: string | null;
  emails?: string | null;
}

export interface PaginationInput {
  page: number;
  itemsOnPage?: number | null;
}

export interface PartnerInput {
  nameOfPartner?: string | null;
  address?: string | null;
  registrationCountry?: string | null;
  businessActivitySector?: string | null;
  websiteAddress?: string | null;
  termsOfCooperation?: string | null;
}

export interface PaymentOrderFilterInput {
  id?: number | null;
  uuid?: string | null;
  archiveStatus?: PaymentOrderArchiveStatus | null;
  statuses?: (PaymentOrderStatus | null)[] | null;
  payOrderType?: PaymentOrderType | null;
  fromCurrency?: string | null;
  toCurrency?: string | null;
  apiDocumentId?: number | null;
  documentId?: number | null;
  creationDevice?: string | null;
  flow?: FlowType | null;
}

export interface PaymentSummaryListFiltersInput {
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface PersonalAddressInput {
  street?: string | null;
  houseNumber?: string | null;
  city?: string | null;
  postCode?: string | null;
  country?: string | null;
  state?: string | null;
}

export interface PersonalAddressesInputV2 {
  residencyAddress: PersonalAddressInput;
  actualAddress?: PersonalAddressInput | null;
  actualIsResidency?: boolean | null;
  contactAddress?: PersonalAddressInput | null;
  contactIsResidency?: boolean | null;
  contactIsActual?: boolean | null;
}

export interface PersonalContactDetailsInput {
  phoneNumber?: string | null;
  email: string;
  landline?: string | null;
  skype?: string | null;
  websites?: string | null;
}

export interface PersonalEmploymentInput {
  employerName?: string | null;
  position?: string | null;
}

export interface PersonalPersonDetailsInput {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  sex?: string | null;
  birthDate?: any | null;
  birthCountry?: string | null;
  birthCity?: string | null;
  citizenships?: string | null;
}

/**
 * (3/3/2023) DEPRECATED
 */
export interface PersonalPlannedServicesPaymentsInput {
  plannedServices?: (PlannedServicesEnum | null)[] | null;
  plannedPayments?: (PlannedPaymentsEnum | null)[] | null;
  otherPlannedPayments?: string | null;
  plannedOutgoingTransaction?: PlannedOutgoingTransactionInput | null;
  plannedIncomingTransaction?: PlannedIncomingTransactionInput | null;
}

export interface PersonalSourceOfIncomeInput {
  employment?: boolean | null;
  employmentDetails?: PersonalEmploymentInput | null;
  tradesman?: boolean | null;
  tradesmanDetails?: PersonalTradesmanInput | null;
  sourceOfIncome?: (SourceOfWealthAndIncomeEnum | null)[] | null;
  otherSourceOfIncome?: string | null;
  totalMonthlyIncome?: PersonalTransactionAmountEnum | null;
  sourceOfWealth?: (SourceOfWealthAndIncomeEnum | null)[] | null;
  otherSourceOfWealth?: string | null;
  specifyMonthlyIncome?: string | null;
}

export interface PersonalTaxInfoInputV2 {
  hasTaxPayerNumber?: boolean | null;
  taxPayerDetails?: (TaxPayerDetailsInput | null)[] | null;
  isPersonRelatedToUsa?: boolean | null;
  tin?: string | null;
  isPoliticallyExposedPerson?: boolean | null;
  politicallyExposedPersonPosition?: string | null;
  politicallyExposedPersonOrganization?: string | null;
  politicallyExposedPersonCountry?: string | null;
  politicallyExposedPersonIsCurrentlyInPosition?: boolean | null;
  politicallyExposedPersonDateOfTermination?: any | null;
}

export interface PersonalTradesmanInput {
  identificationNumber?: string | null;
  providedServices?: string | null;
  countryOfRegistration?: string | null;
}

export interface PersonalUseOfAccountInputV2 {
  plannedServices?: (PlannedServicesEnum | null)[] | null;
  plannedPayments?: (PlannedPaymentsEnum | null)[] | null;
  otherPlannedPayments?: string | null;
  primaryUseOfAccount?: (PersonalPrimaryUseOfAccountEnum | null)[] | null;
  otherPrimaryUseOfAccount?: string | null;
  plannedIncomingTransaction?: PlannedIncomingTransactionInputV2 | null;
  plannedOutgoingTransaction?: PlannedOutgoingTransactionInputV2 | null;
  totalMonthlyTurnover?: any | null;
}

/**
 * (3/3/2023) DEPRECATED
 */
export interface PlannedIncomingTransactionInput {
  frequency?: PersonalTransactionFrequencyEnum | null;
  totalMonthlyReceiving?: PersonalTransactionAmountEnum | null;
  maxIncomingTransaction?: PersonalTransactionAmountEnum | null;
  geographyOfIncomingPayments?: (PersonalGeographyOfTransactionsEnum | null)[] | null;
  monthlyIncomingTransactions?: number | null;
  purposeOfMoneyTransfer?: (PersonalPrimaryUseOfAccountEnum | null)[] | null;
}

export interface PlannedIncomingTransactionInputV2 {
  frequency?: PersonalTransactionFrequencyEnum | null;
  monthlyIncomingTransactionsTurnover?: PersonalTransactionsTurnoverEnum | null;
  monthlyIncomingTransactions?: number | null;
  geographyOfIncomingPayments?: (PersonalGeographyOfTransactionsEnum | null)[] | null;
  otherGeographyOfIncomingPayments?: string | null;
  incomingTransactionsDescription?: string | null;
}

/**
 * (3/3/2023) DEPRECATED
 */
export interface PlannedOutgoingTransactionInput {
  frequency?: PersonalTransactionFrequencyEnum | null;
  totalMonthlySpending?: PersonalTransactionAmountEnum | null;
  maxOutgoingTransaction?: PersonalTransactionAmountEnum | null;
  geographyOfOutgoingPayments?: (PersonalGeographyOfTransactionsEnum | null)[] | null;
  monthlyOutgoingTransactions?: number | null;
  purposeOfMoneyTransfer?: (PersonalPrimaryUseOfAccountEnum | null)[] | null;
}

export interface PlannedOutgoingTransactionInputV2 {
  monthlyOutgoingTransactionsTurnover?: PersonalTransactionsTurnoverEnum | null;
  monthlyOutgoingTransactions?: number | null;
  geographyOfOutgoingPayments?: (PersonalGeographyOfTransactionsEnum | null)[] | null;
  otherGeographyOfOutgoingPayments?: string | null;
  outgoingTransactionsDescription?: string | null;
}

export interface ReadBulletinInput {
  bulletinId: string;
}

export interface RepeatPaymentInput {
  paymentOrderId: string;
  amount: any;
  feeAmount?: any | null;
  paymentDetails?: string | null;
  currencyCode?: string | null;
}

export interface ReportFilterInput {
  currencyCode?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  operationType?: OperationType | null;
  documentIdList?: string | null;
  pagination?: PaginationInput | null;
  reportType: ReportTypes;
}

export interface RepresentativeDetailsInput {
  name?: string | null;
  surname?: string | null;
  sex?: string | null;
  birthDate?: any | null;
  birthPlaceCountry?: string | null;
  birthPlaceCity?: string | null;
  citizenship?: string | null;
  addressStreet?: string | null;
  addressCity?: string | null;
  addressCountry?: string | null;
  addressPostal?: string | null;
  countryOfResidence?: string | null;
  idType?: string | null;
  idNumber?: string | null;
  idIssuedDate?: any | null;
  idExpiryDate?: any | null;
  otherInfo?: OtherRepresentativeDetailsInput | null;
}

export interface ResendMobileConfirmationInput {
  phoneNumber: string;
  includeAppHash?: boolean | null;
}

export interface ResetPasswordInput {
  resetToken?: string | null;
  uid?: string | null;
  newPassword?: string | null;
  code?: string | null;
  captcha?: string | null;
}

export interface RussianBankTransferPartnerInput {
  bicCode: string;
  company?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  otherName?: string | null;
  inn?: string | null;
  kpp?: string | null;
}

export interface RussianBankTransferPaymentInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  accountNumber: string;
  partnerDetails: RussianBankTransferPartnerInput;
}

export interface RussianCardTransferPartnerInput {
  firstName: string;
  lastName: string;
  potranimicNames?: string | null;
}

export interface RussianCardTransferPaymentInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  cardNumber: string;
  partnerDetails: RussianCardTransferPartnerInput;
}

export interface SaveCompanyDocumentInput {
  documentType: CompanyDocumentType;
  documentId: string;
  number?: string | null;
  numberInOriginalLanguage?: string | null;
  issueDate?: any | null;
  expireDate?: any | null;
  authority?: string | null;
  issuingCountry?: string | null;
  dateOfBirth?: string | null;
}

export interface SaveCurrentVerificationPageInput {
  page: number;
}

export interface SaveIdDocumentInput {
  number?: string | null;
  issueDate?: any | null;
  expireDate?: any | null;
  authority?: string | null;
  issuingCountry?: string | null;
  dateOfBirth?: string | null;
}

export interface SendOtpAlternativeInput {
  username: string;
  password: string;
  includeAppHash?: boolean | null;
}

export interface SendOtpUsingSmsInput {
  includeAppHash?: boolean | null;
}

export interface SepaPartnerInput {
  iban?: string | null;
  swift?: string | null;
  beneficiaryName?: string | null;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  otherName?: string | null;
  countryCode: string;
  address1: string;
  address2?: string | null;
  city?: string | null;
  zip?: string | null;
}

export interface SepaPaymentInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  accountNumber?: string | null;
  partnerDetails: SepaPartnerInput;
  payerReference?: string | null;
}

export interface SingleCardAcquiringFilterInput {
  apiDocumentId?: number | null;
  merchantDocumentId?: string | null;
}

export interface SinglePaymentOrderFilterInput {
  apiDocumentId?: number | null;
  merchantDocumentId?: number | null;
  paymentType?: TransactionType | null;
}

export interface SubmitDocumentForOCRInput {
  documentId: string;
  ocrDocumentType?: OCRDocumentType | null;
}

export interface SupportedCountryFilterInput {
  currencyCode?: string | null;
}

export interface SupportedCurrencyPaymentsFilterInput {
  countryCode?: string | null;
  countryCodes?: (string | null)[] | null;
  currencyCode?: string | null;
}

export interface TaxPayerDetailsInput {
  taxResidenceCountry?: string | null;
  taxPayerNumber?: string | null;
}

export interface TransactionListFilterInput {
  currencyCode?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  operationType?: OperationType | null;
  documentIdList?: string | null;
  pagination?: PaginationInput | null;
}

export interface Unbind2faInput {
  code: string;
}

export interface UpdateAddressDocumentDetailsInput {
  idIsPoa?: boolean | null;
  country?: string | null;
  zipCode?: string | null;
  city?: string | null;
  line1?: string | null;
  line2?: string | null;
  state?: string | null;
  postalCode?: string | null;
  issueDate?: any | null;
  expireDate?: any | null;
  authority?: string | null;
  issuingCountry?: string | null;
  number?: string | null;
}

export interface UpdateEmailInput {
  newEmail: string;
  code?: string | null;
}

export interface UpdatePasswordInput {
  oldPassword: string;
  newPassword: string;
  code?: string | null;
}

export interface UpdatePhoneInput {
  phone: string;
  otpToken?: string | null;
  token?: string | null;
  isRegistrationFlow?: boolean | null;
  includeAppHash?: boolean | null;
}

export interface UploadFileInput {
  personalDocumentType?: PersonalDocumentType | null;
  companyDocumentType?: CompanyDocumentType | null;
  shortCode?: string | null;
  uuid?: string | null;
  documentId?: string | null;
}

export interface UploadMobileDocumentInput {
  shortCode: string;
}

export interface VerifyCardAcquiringPaymentInput {
  currencyCode: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCsc: string;
  successRedirect: string;
  failureRedirect: string;
  cancelRedirect: string;
}

export interface VerifyOtpInput {
  code: string;
}

export interface VerifySessionInput {
  password: string;
  captcha?: string | null;
}

export interface WirePaymentInput {
  paymentReference?: string | null;
  main: MainPaymentInput;
  correspondent: ExternalCorrespondentInput;
  paymentDetails?: string | null;
  isBankConfirmation?: boolean | null;
  isSwiftConfirmation?: boolean | null;
  accountNumber?: string | null;
  partnerDetails: ExternalPartnerInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
