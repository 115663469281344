import { createStore } from "vuex";
import { userInfo, UserInfoState } from "./userInfo";
import { vuexLocal } from "./vuexLocal";

export type RootState = {
  userInfo: UserInfoState;
};

export default createStore<RootState>({
  modules: {
    userInfo,
  },
  plugins: [vuexLocal.plugin],
});
