import { computed, ref, watch } from "vue";
import { windowSizeSimpleStore } from "@/shared/store/simpleStore/windowSizeData";

/**
 * A re-usable composable that fetches the Jira Widget values
 * so we can modify its attributes to be responsive enough
 */
export const useReactiveJiraWidget = () => {
  // Jira Widget query selector
  const jiraWidget = document.querySelector("#jsd-widget") as HTMLIFrameElement;
  const isJiraOpened = ref(false);
  const isJiraSearchExpanded = ref(false);
  const isJiraConfirmed = ref(false);
  const jiraWidgetContainer = ref();
  const jiraSearchContainer = ref();
  const isLargeScreen = computed(() => windowSizeSimpleStore.isLarge);

  watch([isJiraOpened], ([opened]) => {
    /**
     * On initial load of screen, the watch of screen size won't trigger.
     * We need to check if it's on desktop or not, to set specific value
     * to the Jira Widget class since it is not responsive enough
     */
    if (isLargeScreen.value) {
      // set container class to mobile on IPAD and below
      jiraWidgetContainer.value?.setAttribute("class", "mobile");
    } else {
      // remove container class on larger screens
      jiraWidgetContainer.value?.setAttribute("class", "");
    }

    // Checking if jira widget is opened/closed, we use the class name to modify the CSS
    if (opened) {
      // Add specific class for Jira Widget Opened
      jiraWidget?.setAttribute("class", "jira-widget-opened");
      return;
    } else {
      // Add specific class for Jira Widget Closed
      jiraWidget?.setAttribute("class", "jira-widget-closed");
    }
  });

  /**
   * Also watch the changing of screen size
   * for the jira widget UI to be responsive
   * since it won't re-enter on watch isJiraOpened
   * if the attribute isn't changed
   */
  watch([isLargeScreen], ([largeScreen]) => {
    // Only enter if the JiraWidget Container has value
    if (jiraWidgetContainer.value) {
      if (largeScreen) {
        // set container class to mobile on IPAD and below
        jiraWidgetContainer.value?.setAttribute("class", "mobile");
        return;
      }

      // remove container class on larger screens
      jiraWidgetContainer.value?.setAttribute("class", "");
    }
  });

  // Watch if user has searched on jira
  watch([isJiraSearchExpanded], ([jiraWidgetSearch]) => {
    // Add specific class when user has searched on Jira
    if (jiraWidgetSearch) {
      jiraWidget?.classList?.add("jira-widget-opened--searched");
      return;
    }

    // Else, remove the searched class name
    jiraWidget?.classList?.remove("jira-widget-opened--searched");
  });

  // Watch if user has contacted us
  watch([isJiraConfirmed], ([jiraConfirmed]) => {
    // Add specific class when user has contacted us
    if (jiraConfirmed) {
      jiraWidget?.classList?.add("jira-widget-confirmed");
      return;
    }

    // Else, remove the class name
    jiraWidget?.classList?.remove("jira-widget-confirmed");
  });

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  // Callback for Mutation List of Search Container
  const searchCallback = (mutationList) => {
    for (const mutation of mutationList) {
      // Check if user has interacted with the jira (ie.e searched, etc)
      isJiraSearchExpanded.value =
        mutation?.target?.className?.includes("body expanded");
    }
  };
  // Mutation Observer of Search Container
  const observerSearch = new MutationObserver(searchCallback);

  // Callback function to execute when mutations are observed
  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === "attributes") {
        //  Assign value of Jira Widget container so we can modify its attributes
        jiraWidgetContainer.value =
          mutation?.target?.contentDocument?.body?.firstElementChild?.firstElementChild;

        isJiraConfirmed.value =
          jiraWidgetContainer.value?.firstElementChild.id.includes(
            "confirmation-container"
          );

        // Check if Jira Widget has been opened
        isJiraOpened.value =
          !jiraWidgetContainer.value?.firstElementChild.id.includes(
            "button-container"
          );

        //  Assign value of Search Container so we can watch its changes
        jiraSearchContainer.value =
          jiraWidgetContainer.value?.firstElementChild.lastElementChild;
        // Start observing nodes for search container
        observerSearch?.observe(jiraSearchContainer.value, config);
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer?.observe(jiraWidget, config);

  return {
    isJiraOpened,
    jiraWidget,
    jiraWidgetContainer,
    isJiraSearchExpanded,
    isJiraConfirmed,
    jiraSearchContainer,
  };
};
