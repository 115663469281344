import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes, {
  sharedRouteGuard,
  handleTemporarilySuspendedRedirect,
} from "@/shared/router";
import NProgress from "nprogress";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import store from "../store";
import {
  InternalVerificationStatus,
  VerificationStatus,
} from "@/../__generated__/globalTypes";
import { forceRedirectIf } from "@/shared/utils/routes";
import { config } from "@/shared/utils/config";
import { i18nTranslate } from "@/plugins/i18n";

/**
 * Note: Add `private` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. privateHome)
 */
const privateRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: routePaths.login,
  //   name: routeNames.login,
  //   component: () =>
  //     import(/* webpackChunkName: "privateLogin" */ "../views/Login.vue"),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: routePaths.home,
    name: routeNames.home,
    component: () =>
      import(
        /* webpackChunkName: "privateDashboard" */ "../../shared/views/Dashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Dashboard",
      }),
    },
  },
  {
    path: routePaths.application,
    name: routeNames.application,
    component: () =>
      import(/* webpackChunkName: "privateKyc" */ "../views/PrivateKyc.vue"),
    meta: {
      requiresAuth: true,
      // TODO: Please set KYC into a more specific document title if questionnaires are finalized
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "KYC",
      }),
    },
  },
  {
    path: routePaths.questionnairePersonal,
    name: routeNames.questionnairePersonal,
    component: () =>
      import(
        /* webpackChunkName: "privatePersonalQuestionnaire" */ "../views/PersonalQuestionnaire.vue"
      ),
    meta: {
      requiresAuth: true,
      // TODO: Please set Questionnaire into a more specific document title if questionnaires are finalized
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Questionnaire",
      }),
    },
  },
  ...sharedRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: privateRoutes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export const authenticatedPrivateUserRoutes = privateRoutes
  .filter((route) => route?.meta?.requiresAuth)
  .map((route) => route.name);

router.beforeEach((to, from, next) => {
  // Start the route progress bar.
  NProgress.start();

  const isAuthenticated = store.state.userInfo.isAuthenticated;

  // Verification Statuses
  const personalVerificationStatus =
    store.state.userInfo.user.personalVerificationStatus;
  const internalVerificationStatus =
    store.state.userInfo.user.internalVerificationStatus;

  // Set page title
  document.title = String(to.meta?.title ?? config.merchantBrand);

  /** For personal site, check if personal status is temporarily suspended, see #86epqdkbd */
  const isAccountVerificationStatusTemporarilySuspended =
    personalVerificationStatus == VerificationStatus.TEMPORARILY_SUSPENDED;

  // If going to a page that requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if the user has logged in
    if (isAuthenticated) {
      // if account is temporarily suspended and route is not going to temporarily suspended page
      handleTemporarilySuspendedRedirect(
        to,
        next,
        isAccountVerificationStatusTemporarilySuspended
      );

      // Force redirect verified user to dashboard if user is trying to go to temporarily suspended page
      forceRedirectIf(
        to,
        [routeNames.temporarilySuspended],
        !isAccountVerificationStatusTemporarilySuspended,
        () => {
          return next({
            name: routeNames.home,
          });
        },
        "Overall VERIFIED users should not be able to navigate to temporarily suspended account page"
      );

      /**
       * forced redirection for authenticated users depending on their
       * internal verififcation and Personal Verification Status
       */
      forceRedirectIf(
        to,
        [routeNames.questionnairePersonal],
        internalVerificationStatus == InternalVerificationStatus.VERIFIED &&
          personalVerificationStatus == VerificationStatus.VERIFIED,
        () => {
          return next({
            name: routeNames.settings,
            params: { tab: "profile" },
          });
        },
        "Overall VERIFIED users should not be able to navigate to any routes of company KYC"
      );

      /** Insert force redirection functions for private env here if needed */

      sharedRouteGuard(store, to, from, next);
    } else {
      return next({ name: routeNames.login });
    }
  }

  // If going to a page that does not require authentication
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if you can go to the page while authenticated using `allowedAuth`
    if (isAuthenticated && to.meta.allowedAuth) {
      return next();
    }

    // Some pages (like Login page) should not be accessed if user is logged in
    if (isAuthenticated && to.meta.requiresAuth === false) {
      return next({ name: routeNames.home });
    }

    return next();
  }

  next();
});

/** The afterEach hook tells the router that
 *  after a link has completely evaluated to
 *  stop the progress bar, it shouldn’t care
 *  if the page request succeeds */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
