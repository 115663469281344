<template>
  <!---------------------------------------
  Two Layouts for desktop and medium responsiveness
    * Medium = header over sider and content
    * Desktop = sider over header and content
  ---------------------------------------->
  <a-layout
    :class="['authenticated-layout', isMediumScreen && 'mobile-layout']"
  >
    <AuthenticatedHeader
      @openSidebar="openMobileSidebar"
      @closeSidebar="closeMobileSidebar"
      :mobileCollapsed="mobileCollapsed"
      v-if="isMediumScreen"
    />
    <a-layout-sider
      v-model:collapsed="collapsed"
      collapsible
      class="authenticated-layout__sider desktop-sider"
      v-else
      :trigger="null"
    >
      <div class="authenticated-layout__custom-sider-wrapper">
        <Sidebar :isCollapsed="collapsed" />
        <div
          class="authenticated-layout__custom-sider-wrapper__trigger"
          @click="toggleSidebar"
        >
          <LeftOutlined
            :class="[
              'authenticated-layout__custom-sider-wrapper__trigger__icon',
              collapsed && 'icon-reversed',
            ]"
          />
        </div>
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-sider
        v-model:collapsed="mobileCollapsed"
        breakpoint="md"
        collapsed-width="0"
        class="authenticated-layout__sider mobile-sider"
        width="100vw"
        :trigger="null"
        v-if="isMediumScreen"
      >
        <Sidebar @closeSidebar="closeMobileSidebar" />
      </a-layout-sider>
      <AuthenticatedHeader @openSidebar="openMobileSidebar" v-else />

      <a-layout-content class="authenticated-layout__body-content">
        <slot />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<style lang="scss">
.authenticated-layout {
  &__custom-sider-wrapper {
    height: 100%;
    background-color: $sidebar-menu-bg-color;
    border-right: 1px solid $separator-color;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    .sidebar {
      flex: auto;
      height: auto;
      min-height: unset;
      overflow: auto;
      .ant-menu-root {
        border-right: 0;
      }
    }
    &__trigger {
      height: auto;
      padding: $sidebar-trigger-padding;
      width: $sidebar-trigger-inner-width;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      &__icon {
        font-size: $sidebar-trigger-icon-size;
        transition: transform 200ms ease-in;
        &.icon-reversed {
          transform: rotate(180deg);
        }
      }
    }
  }

  .ant-layout-sider,
  .ant-layout-sider-trigger {
    border-right: 1px solid $separator-color;
    background-color: $sidebar-menu-bg-color;
  }

  .ant-layout-sider {
    z-index: 1;
    .ant-menu-inline,
    .ant-menu-vertical {
      .ant-menu-item {
        height: 42px;
      }
    }
  }

  .ant-layout-sider-children {
    width: inherit;
    position: fixed;
  }

  .ant-layout-sider-trigger {
    height: 60px;
    line-height: 80px;
    text-align: left;

    .anticon svg {
      width: 19px;
      height: 30px;
      margin-left: 30px;
    }
  }

  .ant-layout-content {
    margin-top: $layout-header-height;
    min-height: calc(100vh - $layout-header-height);
  }

  @media screen and (max-width: $screen-md) {
    .ant-layout-has-sider {
      overflow: hidden;
    }
    .ant-layout-sider {
      margin-top: $layout-header-height-mobile;
      z-index: 0;
    }

    .ant-layout-content {
      margin-top: $layout-header-height-mobile;
      min-height: calc(100vh - $layout-header-height);
    }

    &.mobile-layout {
      overflow-y: auto;
      overflow-x: hidden;

      .mobile-sider {
        touch-action: none;
        overflow: hidden;
        z-index: 1;
        width: 100vw;

        // ANT DESIGN VUE CLASS
        .ant-layout-sider-children {
          max-height: calc(100vh - $layout-header-height-mobile);
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { computed, watch } from "vue";
import AuthenticatedHeader from "@/shared/components/AuthenticatedHeader.vue";
import Sidebar from "@/shared/components/navigation/Sidebar.vue";
import { defineComponent, ref } from "vue";
import { useUpdateUserInfo } from "@/shared/composables/api/useUpdateUserInfo";
import { windowSizeSimpleStore } from "@/shared/store/simpleStore/windowSizeData";

export default defineComponent({
  setup() {
    const collapsed = ref(false);
    const mobileCollapsed = ref(true);
    const updateUserInfo = useUpdateUserInfo();
    const isMediumScreen = computed(() => windowSizeSimpleStore.isMedium);

    watch(isMediumScreen, () => {
      console.log("isMediumScreen", isMediumScreen.value);
    });

    //onmount of auth layout, call updateuserInfo to update company
    updateUserInfo.update();

    const toggleSidebar = () => {
      collapsed.value = !collapsed.value;
    };

    const closeMobileSidebar = () => {
      mobileCollapsed.value = true;
    };

    const openMobileSidebar = () => {
      mobileCollapsed.value = false;
    };

    return {
      isMediumScreen,
      collapsed,
      updateUserInfo,
      mobileCollapsed,
      toggleSidebar,
      closeMobileSidebar,
      openMobileSidebar,
    };
  },
  components: {
    AuthenticatedHeader,
    Sidebar,
  },
});
</script>
