/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import { RootState } from "./index";
import { ActionContext } from "vuex/types";
import { kycCorporateSideBarMenuItems } from "@/corporate/router/routeMenuItems";
import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { KycNavigationSteps } from "@/shared/types/kycNavigationSteps";

export type KycNavigationState = {
  activeItem: KycNavigationSteps;
  percentage: number;
  currentPage: number;
};

const kycNavigationInitialState = {
  activeItem: KycNavigationSteps.SubmissionStatus,
  percentage: 10,
  currentPage: 0,
};

type KycNavigationContext = ActionContext<KycNavigationState, RootState>;

const computeKycPercentage = (kycStep: KycNavigationSteps) => {
  const menuItems = kycCorporateSideBarMenuItems();
  if (!menuItems) return 0;
  const currentItemIndex = findIndex(menuItems, { name: kycStep }) + 1;
  return (currentItemIndex / menuItems.length) * 100;
};

const updateKycStepActionMutation = actionMutationFactory<
  KycNavigationState,
  KycNavigationSteps,
  RootState
>("updateKycStep", (state, data) => {
  state.activeItem = data;
});

const updateKycPercentageActionMutation = actionMutationFactory<
  KycNavigationState,
  number,
  RootState
>("updateKycPercentage", (state, data) => {
  state.percentage = data;
});

const setInitialKycCurrentPageActionMutation = actionMutationFactory<
  KycNavigationState,
  number,
  RootState
>("setInitialKycCurrentPage", (state, data) => {
  state.currentPage = data;
});

const resetKycNavigationStateActionMutation = resetStateActionMutationFactory<
  KycNavigationState,
  RootState
>("resetKycNavigationState", kycNavigationInitialState);

export const kycNavigation = {
  state: {
    ...kycNavigationInitialState,
  },
  actions: {
    ...updateKycPercentageActionMutation.actions,
    ...setInitialKycCurrentPageActionMutation.actions,
    ...resetKycNavigationStateActionMutation.actions,
    updateKycStep(context: KycNavigationContext, kycStep: KycNavigationSteps) {
      context.commit("updateKycStep", kycStep);

      // update kyc percentage
      const percentage = computeKycPercentage(kycStep);
      context.commit("updateKycPercentage", percentage);
    },
    goToNextKyc(context: KycNavigationContext) {
      context.commit("goToNextKyc");

      // update kyc page
      context.commit("updateKycCurrentPage");
    },
    goToPreviousKyc(context: KycNavigationContext) {
      context.commit("goToPreviousKyc");
    },
  },
  mutations: {
    ...updateKycStepActionMutation.mutations,
    ...setInitialKycCurrentPageActionMutation.mutations,
    ...updateKycPercentageActionMutation.mutations,
    ...resetKycNavigationStateActionMutation.mutations,
    updateKycCurrentPage(state: KycNavigationState) {
      const currentKycItem = state.activeItem;

      const menuItems = kycCorporateSideBarMenuItems();
      if (!menuItems) return;

      const currentItemIndex = findIndex(menuItems, {
        name: currentKycItem,
      });
      state.currentPage = currentItemIndex + 1;
    },
    goToNextKyc(state: KycNavigationState) {
      const menuItems = kycCorporateSideBarMenuItems();
      if (!menuItems) return;

      const currentKycItem = state.activeItem;
      const currentItemIndex = findIndex(menuItems, {
        name: currentKycItem,
      });
      const nextItem = get(menuItems, currentItemIndex + 1);
      if (!nextItem) return;
      if (nextItem.action) nextItem.action();
    },
    goToPreviousKyc(state: KycNavigationState) {
      const menuItems = kycCorporateSideBarMenuItems();
      if (!menuItems) return;

      const currentKycItem = state.activeItem;
      const currentItemIndex = findIndex(menuItems, {
        name: currentKycItem,
      });
      const previousItem = get(menuItems, currentItemIndex - 1);
      if (!previousItem) return;
      if (previousItem.action) previousItem.action();
    },
  },
};
