<template>
  <a-layout-header class="non-authenticated-header px-0">
    <div class="ml-10 non-authenticated-header__back">
      <BackButton
        v-if="isAuthenticated"
        @on-back-click="logoutUser"
        :label="t('Log out')"
        :loading="logoutLoading"
      />
      <BackButton v-else @on-back-click="toHome" :label="t('Back home')" />
    </div>
    <div class="non-authenticated-header__image">
      <img
        v-if="!hideLogo"
        src="@/assets/images/LogoWithText.svg"
        class="non-authenticated-header__logo px-10"
      />
    </div>
  </a-layout-header>
</template>

<style lang="scss" scoped>
.non-authenticated-header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  &.ant-layout-header {
    background: inherit;
  }

  &__back {
    position: absolute;
    left: 0;
  }

  &__logo {
    width: 100px;
    height: 50px;
  }
}
</style>

<script lang="ts">
import { isCorporateSite } from "@/shared/utils/config";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { loginSimpleStore } from "@/shared/store/simpleStore/loginData";
import BackButton from "@/shared/components/buttons/BackButton.vue";
import routeNames from "@/shared/router/routeNames";
import useLogout from "@/shared/composables/useLogout";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();
    const store = useStore();
    const merchantBrand = config.merchantBrand;
    let personalCabinetLinkStr = t("{merchantBrand} personal account");
    let corporateCabinetLinkStr = t("{merchantBrand} business account");
    const isAuthenticated = computed(
      () => store.state.userInfo.isAuthenticated
    );
    const { logoutUser, logoutLoading } = useLogout();

    /** if on corporate env, show link to personal cabinet */
    const cabinetLinkStr = isCorporateSite
      ? personalCabinetLinkStr.replace("{merchantBrand}", merchantBrand)
      : corporateCabinetLinkStr.replace("{merchantBrand}", merchantBrand);

    const toHome = () => {
      if (isCorporateSite) {
        window.open(config.corporatePromoPageUrl, "_self");
      }

      window.open(config.personalPromoPageUrl, "_self");
    };

    // Hide Logo on Login since it has its own Logo
    const hideLogo = computed(() => {
      // one way to know if user is on login or login otp is to check if login simple store has details
      return route.name == routeNames.login && loginSimpleStore.username == "";
    });

    return {
      t,
      selectedKeys: ref<string[]>([]),
      isCorporateSite,
      config,
      cabinetLinkStr,
      toHome,
      hideLogo,
      isAuthenticated,
      logoutUser,
      logoutLoading,
    };
  },
  emits: ["openSidebar", "closeSidebar"],
  components: {
    BackButton,
  },
});
</script>
