import { KycNavigationSteps } from "@/shared/types/kycNavigationSteps";
import store from "../store";
import { QuestionnaireNavigationSteps } from "@/shared/types/questionnaireNavigationSteps";
import {
  KycSideBarMenuItems,
  SidebarMenuItems,
} from "@/shared/types/sideMenuItems";
import { PersonalQuestionnaireNavigationSteps } from "@/shared/types/personalQuestionnaireNavigationSteps";

/** NOTE: Corporate Site equivalent of private/router/routeMenuItems.ts
 *
 * kept as separate file to avoid conflicts in importing of store directory;
 *
 * take important note of the file directory and variable names
 * when using in their respective environment
 */
export const kycCorporateSideBarMenuItems = (): KycSideBarMenuItems[] => [
  {
    name: KycNavigationSteps.SubmissionStatus,
    text: "Welcome",
    action: () =>
      store.dispatch("updateKycStep", KycNavigationSteps.SubmissionStatus),
  },
  {
    name: KycNavigationSteps.UploadDocumentId,
    text: "Upload Document ID",
    action: () =>
      store.dispatch("updateKycStep", KycNavigationSteps.UploadDocumentId),
  },
  {
    name: KycNavigationSteps.DocumentIdDetails,
    text: "Document ID",
    action: () =>
      store.dispatch("updateKycStep", KycNavigationSteps.DocumentIdDetails),
  },
  {
    name: KycNavigationSteps.UploadProofOfAddress,
    text: "Upload Proof of Address",
    action: () =>
      store.dispatch("updateKycStep", KycNavigationSteps.UploadProofOfAddress),
  },
  {
    name: KycNavigationSteps.ProofOfAddressDetails,
    text: "Proof of Address",
    action: () =>
      store.dispatch("updateKycStep", KycNavigationSteps.ProofOfAddressDetails),
  },
  {
    name: KycNavigationSteps.UploadSelfie,
    text: "Upload Selfie",
    action: () =>
      store.dispatch("updateKycStep", KycNavigationSteps.UploadSelfie),
  },
];

export const questionnaireCorporateSideBarMenuItems =
  (): SidebarMenuItems[] => [
    {
      name: QuestionnaireNavigationSteps.Welcome,
      text: "Welcome",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.Welcome
        ),
    },
    {
      name: QuestionnaireNavigationSteps.BirthPlace,
      text: "Birth Place",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.BirthPlace
        ),
    },
    {
      name: QuestionnaireNavigationSteps.UsageType,
      text: "Type of Usage",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.UsageType
        ),
    },
    {
      name: QuestionnaireNavigationSteps.EmploymentStatus,
      text: "Employment Status",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.EmploymentStatus
        ),
    },
    {
      name: QuestionnaireNavigationSteps.UsageAmount,
      text: "Amount of Usage",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.UsageAmount
        ),
    },
    {
      name: QuestionnaireNavigationSteps.Tax,
      text: "Tax",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.Tax
        ),
    },
    {
      name: QuestionnaireNavigationSteps.Pep,
      text: "Politically Exposed Person",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.Pep
        ),
    },
    {
      name: QuestionnaireNavigationSteps.Confirm,
      text: "Confirmation",
      action: () =>
        store.dispatch(
          "updateQuestionnaireStep",
          QuestionnaireNavigationSteps.Confirm
        ),
    },
  ];

export const corpPersonalQuestionnaireSidebarMenuItems =
  (): SidebarMenuItems[] => [
    {
      name: PersonalQuestionnaireNavigationSteps.BASIC_INFO,
      text: "Basic Info",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.BASIC_INFO
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.ADDRESS_OF_RESIDENCY,
      text: "Address of Residency",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.ADDRESS_OF_RESIDENCY
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.ACTUAL_ADDRESS,
      text: "Actual Address",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.ACTUAL_ADDRESS
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.CORRESPONDENCE_ADDRESS,
      text: "Correspondence Address",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.CORRESPONDENCE_ADDRESS
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.SOURCES_OF_INCOME,
      text: "Sources of Income",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.SOURCES_OF_INCOME
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.USE_OF_ACCOUNT,
      text: "Use of Account",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.USE_OF_ACCOUNT
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.PLANNED_SERVICES,
      text: "Planned Services",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.PLANNED_SERVICES
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.PLANNED_OUTGOING,
      text: "Planned Outgoing",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.PLANNED_OUTGOING
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.PLANNED_INCOMING,
      text: "Planned Incoming",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.PLANNED_INCOMING
        ),
    },
    {
      name: PersonalQuestionnaireNavigationSteps.TAX_INFORMATION,
      text: "Tax Information",
      action: () =>
        store.dispatch(
          "updatePersonalQuestionnaireStep",
          PersonalQuestionnaireNavigationSteps.TAX_INFORMATION
        ),
    },
  ];
