import { ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import {
  InternalVerificationStatus,
  VerificationStatus,
} from "../../../__generated__/globalTypes";
import { isCorporateSite } from "@/shared/utils/config";

export const useUserStatus = (): {
  isPersonalVerificationStatusVerified: boolean;
  isCompanyVerificationStatusVerified: boolean;
  isInternalVerificationStatusVerified: boolean;
  isUserFullyVerified: boolean;
  personalVerificationStatus: VerificationStatus;
  companyVerificationStatus: VerificationStatus;
  internalVerificationStatus: VerificationStatus;
  isPersonalVerificationStatusForApproval: boolean;
  isCompanyVerificationStatusForApproval: boolean;
  isPersonalVerificationStatusOnHold: boolean;
  isCompanyVerificationStatusOnHold: boolean;
  isPersonalVerificationStatusReprocessOnGoing: boolean;
  isCompanyVerificationStatusReprocessOnGoing: boolean;
  isPersonalVerificationStatusReapproval: boolean;
  isCompanyVerificationStatusReapproval: boolean;
  isPersonalVerificationStatusOngoing: boolean;
  isCompanyVerificationStatusOngoing: boolean;
  isPersonalVerificationStatusNotVerified: boolean;
  isCompanyVerificationStatusNotVerified: boolean;
  // Account is temporarily suspended
  isPersonalVerificationStatusTemporarilySuspended: ComputedRef<boolean>;
  isCompanyVerificationStatusTemporarilySuspended: ComputedRef<boolean>;
  isAccountVerificationStatusTemporarilySuspended: ComputedRef<boolean>;
  /**
   * Fetch if user's account is temporarily suspended
   * for personal site, checks for personal verification status if it's temporarily suspended
   * for corporate site, checks for either the company or personal verification status if it's temporarily suspended
   */
  isUserStatusTemporarilySuspended: ComputedRef<boolean>;
} => {
  const store = useStore();

  const personalVerificationStatus = computed(
    () => store.state.userInfo.user.personalVerificationStatus
  );
  const companyVerificationStatus = computed(
    () => store.state.userInfo.user.companyVerificationStatus
  );
  const internalVerificationStatus = computed(
    () => store.state.userInfo.user.internalVerificationStatus
  );

  const isPersonalVerificationStatusVerified = computed(
    () => personalVerificationStatus.value == VerificationStatus.VERIFIED
  );

  const isCompanyVerificationStatusVerified = computed(
    () => companyVerificationStatus.value == VerificationStatus.VERIFIED
  );

  const isInternalVerificationStatusVerified = computed(
    () => internalVerificationStatus.value == VerificationStatus.VERIFIED
  );

  const isPersonalVerificationStatusForApproval = computed(
    () => personalVerificationStatus.value == VerificationStatus.FOR_APPROVAL
  );

  const isCompanyVerificationStatusForApproval = computed(
    () => companyVerificationStatus.value == VerificationStatus.FOR_APPROVAL
  );

  const isPersonalVerificationStatusOnHold = computed(
    () => personalVerificationStatus.value == VerificationStatus.ON_HOLD
  );

  const isCompanyVerificationStatusOnHold = computed(
    () => companyVerificationStatus.value == VerificationStatus.ON_HOLD
  );

  const isPersonalVerificationStatusReprocessOnGoing = computed(
    () =>
      personalVerificationStatus.value == VerificationStatus.REPROCESS_ON_GOING
  );

  const isCompanyVerificationStatusReprocessOnGoing = computed(
    () =>
      companyVerificationStatus.value == VerificationStatus.REPROCESS_ON_GOING
  );

  const isPersonalVerificationStatusReapproval = computed(
    () => personalVerificationStatus.value == VerificationStatus.REAPPROVAL
  );

  const isCompanyVerificationStatusReapproval = computed(
    () => companyVerificationStatus.value == VerificationStatus.REAPPROVAL
  );

  const isPersonalVerificationStatusOngoing = computed(
    () => personalVerificationStatus.value == VerificationStatus.ONGOING
  );

  const isCompanyVerificationStatusOngoing = computed(
    () => companyVerificationStatus.value == VerificationStatus.ONGOING
  );

  const isPersonalVerificationStatusNotVerified = computed(
    () => personalVerificationStatus.value == VerificationStatus.NOT_VERIFIED
  );

  const isCompanyVerificationStatusNotVerified = computed(
    () => companyVerificationStatus.value == VerificationStatus.NOT_VERIFIED
  );

  /** Personal account is suspended */
  const isPersonalVerificationStatusTemporarilySuspended = computed(
    () =>
      personalVerificationStatus.value ==
      VerificationStatus.TEMPORARILY_SUSPENDED
  );
  /** Corporate account is suspended */
  const isCompanyVerificationStatusTemporarilySuspended = computed(
    () =>
      companyVerificationStatus.value ==
      VerificationStatus.TEMPORARILY_SUSPENDED
  );
  /** Account is suspended either personal or corporate */
  const isAccountVerificationStatusTemporarilySuspended = computed(
    () =>
      isPersonalVerificationStatusTemporarilySuspended.value ||
      isCompanyVerificationStatusTemporarilySuspended.value
  );

  /**
   * Fetch if user's account is temporarily suspended
   * for personal site, checks for personal verification status if it's temporarily suspended
   * for corporate site, checks for either the company or personal verification status if it's temporarily suspended
   */
  const isUserStatusTemporarilySuspended = computed(() => {
    // For corporate site,
    if (isCorporateSite) {
      // check if personal or company verification status is temporarily suspended
      // returns true if either personal or company status is temporarily suspended
      return isAccountVerificationStatusTemporarilySuspended.value;
    }
    // for personal site,
    else {
      // check if personal verification status is temporarily suspended
      // returns true if personal verification status is temporarily suspended
      return isPersonalVerificationStatusTemporarilySuspended.value;
    }
  });

  return {
    isPersonalVerificationStatusVerified:
      isPersonalVerificationStatusVerified.value,
    isCompanyVerificationStatusVerified:
      isCompanyVerificationStatusVerified.value,
    isInternalVerificationStatusVerified:
      isInternalVerificationStatusVerified.value,
    isUserFullyVerified:
      personalVerificationStatus.value === VerificationStatus.VERIFIED &&
      companyVerificationStatus.value === VerificationStatus.VERIFIED &&
      internalVerificationStatus.value === InternalVerificationStatus.VERIFIED,
    personalVerificationStatus: personalVerificationStatus.value,
    companyVerificationStatus: companyVerificationStatus.value,
    internalVerificationStatus: internalVerificationStatus.value,
    isPersonalVerificationStatusForApproval:
      isPersonalVerificationStatusForApproval.value,
    isCompanyVerificationStatusForApproval:
      isCompanyVerificationStatusForApproval.value,
    isPersonalVerificationStatusOnHold:
      isPersonalVerificationStatusOnHold.value,
    isCompanyVerificationStatusOnHold: isCompanyVerificationStatusOnHold.value,
    isPersonalVerificationStatusReprocessOnGoing:
      isPersonalVerificationStatusReprocessOnGoing.value,
    isCompanyVerificationStatusReprocessOnGoing:
      isCompanyVerificationStatusReprocessOnGoing.value,
    isPersonalVerificationStatusReapproval:
      isPersonalVerificationStatusReapproval.value,
    isCompanyVerificationStatusReapproval:
      isCompanyVerificationStatusReapproval.value,
    isPersonalVerificationStatusOngoing:
      isPersonalVerificationStatusOngoing.value,
    isCompanyVerificationStatusOngoing:
      isCompanyVerificationStatusOngoing.value,
    isPersonalVerificationStatusNotVerified:
      isPersonalVerificationStatusNotVerified.value,
    isCompanyVerificationStatusNotVerified:
      isCompanyVerificationStatusNotVerified.value,
    isPersonalVerificationStatusTemporarilySuspended,
    isCompanyVerificationStatusTemporarilySuspended,
    isAccountVerificationStatusTemporarilySuspended,
    isUserStatusTemporarilySuspended,
  };
};
