import routeNames from "@/shared/router/routeNames";
import {
  KycNavigationSteps,
  NumericKycNavigationSteps,
} from "@/shared/types/kycNavigationSteps";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const DEFAULT_DEBUG_FLAGS = {
  /** Disable skipping in KYC documents. ID, POA, and Selfie */
  disableSkipKycUpload: false,
  /**
   * Disable skipping of OTP verification in mobile.
   * But this would cause an error on OTP submit, since the phone might
   * already be verified.
   */
  disableSkipPhoneVerification: false,
};

/** Flags that are undefined/false by default, unless toggled in console */
export type DebugFlags = Partial<typeof DEFAULT_DEBUG_FLAGS>;

/** Composable to mount debug logic on either private or corporate Vue app */
export const useDebugApp = () => {
  const router = useRouter();
  const store = useStore();

  const debugFlags = reactive<DebugFlags>(DEFAULT_DEBUG_FLAGS);

  /** TODO: Add methods here that can be called in console */
  const debugMethods = {
    /**
     * Disabled skipping of steps in KYC, then redirects to it.
     * @param numericKycPageNumber The numeric page number of step you want to redirect to. (default=4)
     */
    testKyc: (numericKycPageNumber = 4) => {
      debugFlags.disableSkipKycUpload = true;
      debugFlags.disableSkipPhoneVerification = true;

      const key = NumericKycNavigationSteps[numericKycPageNumber];

      store.dispatch("updateKycStep", KycNavigationSteps[key]);

      router.push({ name: routeNames.application });
    },
  };

  /** To expose vue app on window variable for debugging */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).debug = {
    flags: debugFlags,
    methods: debugMethods,
  };

  return { debugFlags, debugMethods };
};

/** Checks whether a debug flag is enabled. If not found, always returns false for safety. */
export const getDebugFlagValue = (key: keyof DebugFlags): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).debug?.flags[key] ?? false;
};
