<template>
  <UpdateInfoModal
    class="auto-logout-modal"
    v-model:visible="warningZone"
    :footer="null"
  >
    <template #title>
      <a-typography-text>
        {{ t("Warning") }}
      </a-typography-text>
    </template>
    <a-typography-text :level="4">
      {{
        t(
          "Are you still there? If not, you will be automatically logout due to inactivity in {countdownTimer} seconds",
          { countdownTimer: warningCountdownTimer }
        )
      }}
    </a-typography-text>
  </UpdateInfoModal>
</template>

<style lang="scss">
#app {
  height: 100%;
}
</style>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import UpdateInfoModal from "@/shared/components/modals/UpdateInfoModal.vue";
import { makeToast } from "@/shared/utils/toast";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import useLogout from "@/shared/composables/useLogout";
import useLogging from "@/shared/composables/useLogging";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    /* These are the lists of events that could trigger to reset this timer */
    const events = [
      "click",
      "mousemove",
      "mousedown",
      "scroll",
      "keypress",
      "load",
    ];

    // Create Log
    const { submitInfoToApi } = useLogging();

    /* Create 2 timers, warning and the actual timer to log users out */
    const warningTimer = ref();
    const logoutTimer = ref();
    const warningZone = ref(false);
    //countdown timer will be set to 1 min, interval between warning and logout timer
    const warningCountdownTimer = ref(config.autoLogoutTimeoutInSec);

    const { logoutUser } = useLogout();

    onMounted(() => {
      /* this refers to the events on this setup */
      events.forEach(function (event) {
        /* Every time these events happen , reset Timer */
        window.addEventListener(event, resetTimer);
      }, this);

      /* on mounted call set timers */
      setTimers();
    });

    /* opposite of on mounted*/
    onUnmounted(() => {
      events.forEach(function (event) {
        //destroy
        window.removeEventListener(event, resetTimer);
      }, this);

      resetTimer();
    });

    /* set timer value */
    const setTimers = () => {
      /* On expired set timeout, call the warning message */
      warningTimer.value = setTimeout(
        warningMessage,
        config.inactivityWarningTimeoutInMs
      );

      /* This is a logout timer */
      logoutTimer.value = setTimeout(
        autoLogoutUser,
        config.inactivityLogoutTimeoutInMs
      ); //formula: 14 minutes - 14 * 60 *1000

      /* Reset the value to false */
      warningZone.value = false;
    };

    const warningMessage = () => {
      /** if warning message method is called,
       *  that means the user is inactive
       *  set the value of warning zone into true */
      warningZone.value = true;

      // Start countdown timer
      setInterval(() => {
        warningCountdownTimer.value--;
      }, 1000);
    };

    /* Reset Timer */
    const resetTimer = () => {
      clearTimeout(warningTimer.value);
      clearTimeout(logoutTimer.value);

      /* recall the same function on setting timers */
      setTimers();
    };

    /* Log out the user automatically after a specific time*/
    const autoLogoutUser = async () => {
      // Add a Log that user has been automatically logged out;
      // called before actual logout mutation call
      // as it can apparently response an AuthenticationError
      submitInfoToApi(
        "SESSION_EXPIRED: Logged Out Automatically due to inactivity"
      );
      await logoutUser();
      makeToast(
        "info",
        t("Logged Out Automatically"),
        t("You have been automatically logout due to inactivity")
      );
    };

    return {
      events,
      warningTimer,
      logoutTimer,
      setTimers,
      resetTimer,
      warningMessage,
      warningZone,
      t,
      warningCountdownTimer,
    };
  },
  components: {
    UpdateInfoModal,
  },
});
</script>
