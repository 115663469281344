import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/corporate/store";
import {
  QuestionnaireAmountUsage,
  QuestionnaireBirthPlace,
  QuestionnaireEmploymentStatus,
  QuestionnairePep,
  QuestionnaireTax,
  QuestionnaireUsageType,
} from "@/shared/types/questionnaire";

type QuestionnaireData = {
  questionnaireBirthPlace?: QuestionnaireBirthPlace;
  questionnaireUsageType?: QuestionnaireUsageType;
  questionnaireEmploymentStatus?: QuestionnaireEmploymentStatus;
  questionnaireAmountUsage?: QuestionnaireAmountUsage;
  questionnaireTax?: QuestionnaireTax;
  questionnairePep?: QuestionnairePep;
};

const QuestionnaireDataInitialState = {};

export type QuestionnaireDataState = QuestionnaireData;

const updateQuestionnaireData = actionMutationFactory<
  QuestionnaireDataState,
  QuestionnaireData,
  RootState
>("updateQuestionnaireData", (state, data) => {
  state.questionnaireBirthPlace =
    data.questionnaireBirthPlace || state.questionnaireBirthPlace;
  state.questionnaireUsageType =
    data.questionnaireUsageType || state.questionnaireUsageType;
  state.questionnaireEmploymentStatus =
    data.questionnaireEmploymentStatus || state.questionnaireEmploymentStatus;
  state.questionnaireAmountUsage =
    data.questionnaireAmountUsage || state.questionnaireAmountUsage;
  state.questionnaireTax = data.questionnaireTax || state.questionnaireTax;
  state.questionnairePep = data.questionnairePep || state.questionnairePep;
});

const resetQuestionnaireDataState = resetStateActionMutationFactory<
  QuestionnaireDataState,
  RootState
>("resetQuestionnaireDataState", QuestionnaireDataInitialState);

export const questionnaireData = {
  state: {
    ...QuestionnaireDataInitialState,
  },
  actions: {
    ...updateQuestionnaireData.actions,
    ...resetQuestionnaireDataState.actions,
  },
  mutations: {
    ...updateQuestionnaireData.mutations,
    ...resetQuestionnaireDataState.mutations,
  },
};
