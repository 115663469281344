import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    class: _normalizeClass(['back-button', _ctx.mobileTextOnly && 'back-button--mobile-text-only']),
    type: "text",
    onClick: _ctx.goBack,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      (!_ctx.mobileTextOnly)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
            _createElementVNode("img", {
              src: _ctx.BackButtonIcon,
              class: "back-button__icon"
            }, null, 8, _hoisted_1)
          ])
        : _createCommentVNode("", true),
      (_ctx.showText)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([
        'back-button__label',
        _ctx.isCorporateSite && 'back-button__label--is-corp',
        _ctx.mobileTextOnly && 'back-button__label--mobile-text-only',
      ])
          }, _toDisplayString(_ctx.label || _ctx.t("backButton.text")), 3))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "onClick", "disabled"]))
}