<template>
  <a-button
    :class="['back-button', mobileTextOnly && 'back-button--mobile-text-only']"
    type="text"
    @click="goBack"
    :disabled="loading"
  >
    <!-- @slot - Default slot to change icon -->
    <slot v-if="!mobileTextOnly">
      <img :src="BackButtonIcon" class="back-button__icon" />
    </slot>

    <div
      v-if="showText"
      :class="[
        'back-button__label',
        isCorporateSite && 'back-button__label--is-corp',
        mobileTextOnly && 'back-button__label--mobile-text-only',
      ]"
    >
      {{ label || t("backButton.text") }}
    </div>
  </a-button>
</template>

<style lang="scss">
.back-button {
  font-weight: $font-semibold;
  text-transform: uppercase;

  display: inline-flex;
  margin-right: $padding-sm;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  // Remove default button styling
  background-color: transparent;
  padding: 0;
  border: $btn-border-width solid transparent !important;
  box-shadow: none;
  height: unset;

  // Fix inline alignment
  font-size: inherit;

  &--mobile-text-only {
    margin-right: 0;
  }

  &:active,
  &:focus,
  &:hover {
    background: transparent !important;
    border: $btn-border-width solid transparent !important;
    box-shadow: none;
  }

  &__label {
    color: initial;
    text-transform: none;
    font-weight: initial;
    &--is-corp {
      color: $text-color;
    }
    &--mobile-text-only {
      font-weight: $font-regular;
      color: $primary-color;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { isCorporateSite } from "@/shared/utils/config";
import BackButtonIcon from "@/assets/images/icons/common/arrow-left-blue.svg";

export default defineComponent({
  setup(props, { emit }) {
    const t = useI18n().t;

    return {
      t,
      BackButtonIcon,
      showText: ref(props.showBtnText),
      goBack: () => emit("on-back-click"),
      isCorporateSite,
    };
  },
  emits: ["on-back-click"],
  props: {
    showBtnText: {
      type: Boolean,
      default: true,
    },
    label: String,
    loading: Boolean,
    mobileTextOnly: Boolean,
  },
});
</script>
