import gql from "graphql-tag";

export const logsApiGql = gql`
  mutation CreateLog($input: LogInput!) {
    createLog(input: $input) {
      ... on Log {
        logLevel
        message
        context
      }

      ... on ResponseErrors {
        errors {
          message
          code
        }
      }
    }
  }
`;
