/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment, { Moment } from "moment";
import {
  getMessageValidAtLeastSixMonthsFutureDate,
  getMessageValidLegalAge,
  getMessageValidCurrentToFuture,
  getMessageValidPastToCurrentDate,
} from "@/shared/utils/validators/dateValidators";

export const dateFormats = {
  default: "YYYY-MM-DD HH:mm",
  defaultDateWithNoTime: "YYYY-MM-DD",
  compressed: "YYYYMMDDHHmm",
  groupLabel: "D MMM YYYY",
  groupSpecificLabel: "D MMMM YYYY",
  inverseDot: "DD.MM.YYYY",
  inverseDotShorter: "DD.MM.YY",
  time: "HH:mm",
  shortDayDisplay: "ddd DD.MM",
  dateRange: "DD.MM.YY",
  historyGroupLabel: "DD MMMM YYYY",
  dateTimeFormat: "DD MMMM YYYY HH:mm",
  dateTimeShortFormat: "DD MMM YYYY, HH:mm",
  dateTimezoneISOFormat: "YYYY-MM-DDZ", // Follows the ISO 8601 formatting
  dateTimeTimezoneISOFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ", // Follows the ISO 8601 formatting
};

/**
 * Reusable date definitions for date picker
 *
 * @example
 * <DatePicker
 *  ...
 *  :disabledDate="dateDefinition.birthDate.disabledDate"
 *  :defaultPickerValue="dateDefinition.birthDate.defaultPickerValue"
 * />
 */
export const dateDefinition = {
  /**
   * Birth date with minimum 18 years old selectable
   */
  birthDate: {
    disabledDate: (current: Moment): boolean => {
      //Disable dates not 18 years from now
      return current && current > moment().subtract(18, "years");
    },
    defaultPickerValue: moment().subtract(18, "years"),
    getExtraInfo: getMessageValidLegalAge,
  },
  /**
   * Expiry date for documents that only allows at least 6 months before expiry
   */
  expiryDate: {
    disabledDate: (current: Moment): boolean => {
      // Only allow dates 6 months from now
      return current && current < moment().add(6, "months");
    },
    defaultPickerValue: moment().add(6, "months").add(1, "days"),
    getExtraInfo: getMessageValidAtLeastSixMonthsFutureDate,
  },
  /**
   * Issue date for documents that requires date to be in the past
   */
  issueDate: {
    disabledDate: (current: Moment): boolean => {
      // Disable future dates... Allow today and past dates
      return current && current > moment();
    },
    defaultPickerValue: moment().subtract(1, "days"),
    getExtraInfo: getMessageValidPastToCurrentDate,
  },
  /**
   * For dates that only accepts values from current to future
   */
  currentToFutureDate: {
    disabledDate: (current: Moment): boolean => {
      // Disable past dates
      return current && current < moment().subtract(1, "days");
    },
    getExtraInfo: getMessageValidCurrentToFuture,
  },
  /**
   * same with issued date, but separated for reusable
   * For dates that only accepts values from past to current
   */
  pastToCurrentDate: {
    disabledDate: (current: Moment): boolean => {
      // Disable future dates... Allow today and past dates
      return current && current > moment();
    },
    defaultPickerValue: moment().subtract(1, "days"),
    getExtraInfo: getMessageValidPastToCurrentDate,
  },
};

/** Calculate the number of week/s from two dates;
 * calculate the length of a week
 * (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds) */
export function calculateWeeksBetween(date1, date2) {
  return Math.abs(Math.round((date2 - date1) / (7 * 24 * 60 * 60 * 1000)));
}

/** Calculate the number of week/s from two dates;
 * calculate the length of a day
 * (24 hours * 60 minutes * 60 seconds * 1000 milliseconds) */
export function calculateDaysBetween(date1, date2) {
  return Math.abs(Math.round((date2 - date1) / (24 * 60 * 60 * 1000)));
}
