import { ref } from "vue";

/**
 * A re-usable composable regarding Jira widget for modification of behaviors
 */
export const useJiraWidgetSupport = () => {
  const isJiraOpen = ref(false);
  const closeButton = ref();

  // Iframe Jira Widget query selector
  const iframeJiraWidget = document.querySelector(
    "#jsd-widget"
  ) as HTMLIFrameElement;

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      // Watch for attributes changes so we can fetch the needed query class
      if (mutation.type === "attributes") {
        const jiraContentDocument = iframeJiraWidget.contentDocument;
        // Close button query selector
        closeButton.value =
          jiraContentDocument?.querySelector(
            "div.close-icon-container > div"
          ) ??
          jiraContentDocument?.querySelector(
            "div.header-close-icon-container > div"
          );
        const jiraBodyExpanded = jiraContentDocument?.querySelector(
          "#search-container > div.body.expanded"
        ) as HTMLElement;
        const jiraForm = jiraContentDocument?.querySelector(
          "#search-container > div.form-container.no-chrome"
        ) as HTMLElement;
        const jiraWidgetBody = jiraContentDocument?.querySelector(
          "#search-container > div.form-container.no-chrome > .help-form, .widget-body, .widget-body-without-padding"
        ) as HTMLElement;

        // Override the jira widget UI
        jiraBodyExpanded?.style?.setProperty(
          "height",
          "calc(100vh - 50px)",
          "important"
        );
        jiraBodyExpanded?.style?.setProperty("max-height", "100%", "important");
        jiraForm?.style?.setProperty(
          "height",
          "calc(100vh - 90px)",
          "important"
        );
        jiraWidgetBody?.style?.setProperty("height", "100%", "important");
        jiraWidgetBody?.style?.setProperty("max-height", "100%", "important");

        // Add event listener when usr clicked close button
        closeButton.value?.addEventListener("click", closeJira);
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer?.observe(iframeJiraWidget, config);

  // Hide Jira Event Handler
  const hideJira = () => {
    iframeJiraWidget?.setAttribute("class", "jira-widget-closed");
  };

  // Opens Jira Event Handler
  const openJira = () => {
    // Help button query selector
    const helpButton = iframeJiraWidget?.contentDocument?.querySelector(
      "#help-button"
    ) as HTMLElement;

    // Trigger opening of Jira widget
    helpButton?.click();
    // Toggle Jira Visibility
    isJiraOpen.value = true;
  };

  // Closes Jira Event Handler
  const closeJira = () => {
    // trigger closing of Jira
    closeButton.value?.click();
    // Toggle Jira Visibility
    isJiraOpen.value = false;
  };

  return {
    isJiraOpen,
    hideJira,
    openJira,
    closeJira,
  };
};
