/**
 * For now we manually import both components and styles
 * since babel-plugin-import does not seem to work
 * with the current version of dependencies we have.
 */
import { App } from "@vue/runtime-core";
import Button from "ant-design-vue/lib/button";
import "ant-design-vue/lib/button/style";
import Card from "ant-design-vue/lib/card";
import "ant-design-vue/lib/card/style";
import Layout from "ant-design-vue/lib/layout";
import "ant-design-vue/lib/layout/style";
import Input from "ant-design-vue/lib/input";
import "ant-design-vue/lib/input/style";
import Form from "ant-design-vue/lib/form";
import "ant-design-vue/lib/form/style";
import Select from "ant-design-vue/lib/select";
import "ant-design-vue/lib/select/style";
import Switch from "ant-design-vue/lib/switch";
import "ant-design-vue/lib/switch/style";
import Menu from "ant-design-vue/lib/menu";
import "ant-design-vue/lib/menu/style";
import List from "ant-design-vue/lib/list";
import "ant-design-vue/lib/list/style";
import Tabs from "ant-design-vue/lib/tabs";
import "ant-design-vue/lib/tabs/style";
import Radio from "ant-design-vue/lib/radio";
import "ant-design-vue/lib/radio/style";
import AutoComplete from "ant-design-vue/lib/auto-complete";
import "ant-design-vue/lib/auto-complete/style";
import Dropdown from "ant-design-vue/lib/dropdown";
import "ant-design-vue/lib/dropdown/style";
import Checkbox from "ant-design-vue/lib/checkbox";
import "ant-design-vue/lib/checkbox/style";
import Typography from "ant-design-vue/lib/typography";
import "ant-design-vue/lib/typography/style";
import Modal from "ant-design-vue/lib/modal";
import "ant-design-vue/lib/modal/style";
import Image from "ant-design-vue/lib/image";
import "ant-design-vue/lib/image/style";
import {
  DownOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
  CheckOutlined,
  CloseOutlined,
  AppstoreOutlined,
  EditOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  RightOutlined,
} from "@ant-design/icons-vue";
import Upload from "ant-design-vue/lib/upload";
import "ant-design-vue/lib/upload/style";
import DatePicker from "ant-design-vue/lib/date-picker";
import RangePicker from "ant-design-vue/lib/date-picker";
import "ant-design-vue/lib/date-picker/style";
import Progress from "ant-design-vue/lib/progress";
import "ant-design-vue/lib/progress/style";
import Alert from "ant-design-vue/lib/alert";
import "ant-design-vue/lib/alert/style";
import Avatar from "ant-design-vue/lib/avatar";
import "ant-design-vue/lib/avatar/style";
import Divider from "ant-design-vue/lib/divider";
import "ant-design-vue/lib/divider/style";
import Tooltip from "ant-design-vue/lib/tooltip";
import "ant-design-vue/lib/tooltip/style";
import CollapsePanel from "ant-design-vue/lib/collapse";
import "ant-design-vue/lib/collapse/style";
import Skeleton from "ant-design-vue/lib/skeleton";
import "ant-design-vue/lib/skeleton/style";
import Statistic from "ant-design-vue/lib/statistic";
import "ant-design-vue/lib/statistic/style";
import Breadcrumb from "ant-design-vue/lib/breadcrumb";
import "ant-design-vue/lib/breadcrumb/style";
import Popconfirm from "ant-design-vue/lib/popconfirm";
import "ant-design-vue/lib/popconfirm/style";
import Result from "ant-design-vue/lib/result";
import "ant-design-vue/lib/result/style";
import Table from "ant-design-vue/lib/table";
import "ant-design-vue/lib/table/style";
import Space from "ant-design-vue/lib/space";
import "ant-design-vue/lib/space/style";

/**
 * Type definitions for these components are not yet
 * updated to use .use() method.
 *
 * For now we can use the .component() method for
 * these components
 */
import { Row, Col } from "ant-design-vue/lib/grid";
import "ant-design-vue/lib/grid/style";

/**
 * Reference for the configurations for message component:
 * https://2x.antdv.com/components/message#API
 */
import { message } from "ant-design-vue";
import "ant-design-vue/lib/message/style";
message.config({
  duration: 4,
  maxCount: 3,
  top: "10px",
});

import notification from "ant-design-vue/lib/notification";
import "ant-design-vue/lib/notification/style";
notification.config({
  placement: "bottomRight",
  bottom: "50px",
  duration: 3,
});

export const useAntDesignComponents = (app: App): App => {
  return app
    .component(DownOutlined.displayName, DownOutlined)
    .component(ArrowLeftOutlined.displayName, ArrowLeftOutlined)
    .component(LeftOutlined.displayName, LeftOutlined)
    .component(CheckOutlined.displayName, CheckOutlined)
    .component(CloseOutlined.displayName, CloseOutlined)
    .component(AppstoreOutlined.displayName, AppstoreOutlined)
    .component(EditOutlined.displayName, EditOutlined)
    .component(PlusCircleOutlined.displayName, PlusCircleOutlined)
    .component(ReloadOutlined.displayName, ReloadOutlined)
    .component(CheckCircleOutlined.displayName, CheckCircleOutlined)
    .component(CloseCircleOutlined.displayName, CloseCircleOutlined)
    .component(RightOutlined.displayName, RightOutlined)
    .component(Row.name, Row)
    .component(Col.name, Col)
    .use(Button)
    .use(Card)
    .use(Layout)
    .use(Input)
    .use(List)
    .use(Form)
    .use(Select)
    .use(Switch)
    .use(Tabs)
    .use(Menu)
    .use(Radio)
    .use(Dropdown)
    .use(AutoComplete)
    .use(Upload)
    .use(Checkbox)
    .use(Typography)
    .use(Modal)
    .use(DatePicker)
    .use(Alert)
    .use(Progress)
    .use(Avatar)
    .use(Divider)
    .use(RangePicker)
    .use(Skeleton)
    .use(Tooltip)
    .use(Statistic)
    .use(Breadcrumb)
    .use(CollapsePanel)
    .use(Popconfirm)
    .use(Image)
    .use(Result)
    .use(Table)
    .use(Space);
};
