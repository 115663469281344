import { createApp, h } from "vue";
import router from "./router";
import store from "./store";
import App from "./SsoLoginApp.vue";
import { apolloProvider } from "@/api/graphqlClient/apolloClient";

/**
 * Setup mobile upload
 */
export default createApp({
  setup() {
    apolloProvider;
  },

  render: () => h(App),
})
  .use(store)
  .use(router);
