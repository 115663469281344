import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes, {
  sharedRouteGuard,
  handleTemporarilySuspendedRedirect,
} from "@/shared/router";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import store from "../store";
import {
  InternalVerificationStatus,
  VerificationStatus,
} from "@/../__generated__/globalTypes";
import { forceRedirectIf } from "@/shared/utils/routes";
import NProgress from "nprogress";
import { config } from "@/shared/utils/config";
import { i18nTranslate } from "@/plugins/i18n";

/**
 * Note: Add `corporate` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. corporateHome)
 */
const corporateRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: routePaths.login,
  //   name: routeNames.login,
  //   component: () =>
  //     import(/* webpackChunkName: "corporateLogin" */ "../views/Login.vue"),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: routePaths.home,
    name: routeNames.home,
    component: () =>
      import(
        /* webpackChunkName: "corporateDashboard" */ "../../shared/views/Dashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Dashboard",
      }),
    },
  },
  {
    path: routePaths.application,
    name: routeNames.application,
    component: () =>
      import(
        /* webpackChunkName: "corporateKyc" */ "../views/CorporateKyc.vue"
      ),
    meta: {
      requiresAuth: true,
      // TODO: Please set KYC into a more specific document title if questionnaires are finalized
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "KYC",
      }),
    },
  },
  {
    path: routePaths.questionnaireCorporate,
    name: routeNames.questionnaireCorporate,
    component: () =>
      import(
        /* webpackChunkName: "corporateQuestionnaire" */ "../views/CorporateQuestionnaire.vue"
      ),
    meta: {
      requiresAuth: true,
      // TODO: Please set Questionnaire into a more specific document title if questionnaires are finalized
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Questionnaire",
      }),
    },
  },
  {
    path: routePaths.companyDocumentsUpload,
    name: routeNames.companyDocumentsUpload,
    component: () =>
      import(
        /* webpackChunkName: "corporateDocumentUpload" */ "../views/CorporateDocumentUpload.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Document Upload",
      }),
    },
  },
  {
    path: routePaths.corporateKYCWelcome,
    name: routeNames.corporateKYCWelcome,
    component: () =>
      import(
        /* webpackChunkName: "corporateKycWelcomeScreen" */ "../views/CorporateKYCWelcomeScreen.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Welcome",
      }),
    },
  },
  ...sharedRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: corporateRoutes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export const authenticatedCorporateUserRoutes = corporateRoutes
  .filter((route) => route?.meta?.requiresAuth)
  .map((route) => route.name);

router.beforeEach((to, from, next) => {
  // // Start the route progress bar.
  NProgress.start();

  const isAuthenticated = store.state.userInfo.isAuthenticated;

  // Verification Statuses
  const companyVerificationStatus =
    store.state.userInfo.user.companyVerificationStatus;
  const internalVerificationStatus =
    store.state.userInfo.user.internalVerificationStatus;
  const personalVerificationStatus =
    store.state.userInfo.user.personalVerificationStatus;

  const companyId = store.state.userInfo.user.apiCompanyId;

  // Set page title
  document.title = String(to.meta?.title ?? config.merchantBrand);

  /** For corporate site, check if personal and company status is temporarily suspended */
  const isAccountVerificationStatusTemporarilySuspended =
    personalVerificationStatus == VerificationStatus.TEMPORARILY_SUSPENDED ||
    companyVerificationStatus == VerificationStatus.TEMPORARILY_SUSPENDED;

  // If going to a page that requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //   if the user has logged in
    if (isAuthenticated) {
      // if account is temporarily suspended and route is not going to Temporarily Suspended page
      handleTemporarilySuspendedRedirect(
        to,
        next,
        isAccountVerificationStatusTemporarilySuspended
      );

      // Force redirect verified user to dashboard if user is trying to go to Temporarily Suspended page
      forceRedirectIf(
        to,
        [routeNames.temporarilySuspended],
        !isAccountVerificationStatusTemporarilySuspended,
        () => {
          return next({
            name: routeNames.home,
          });
        },
        "Overall VERIFIED users should not be able to navigate to temporarily suspended account page"
      );

      /**
       * forced redirection for authenticated users depending on their
       * Company/Personal Verification Status
       */
      forceRedirectIf(
        to,
        [routeNames.questionnaireCorporate],
        companyVerificationStatus == VerificationStatus.VERIFIED &&
          internalVerificationStatus == InternalVerificationStatus.VERIFIED,
        () => {
          return next({
            name: routeNames.settings,
            params: { tab: "profile" },
          });
        },
        "Overall VERIFIED users should not be able to navigate to any routes of company KYC"
      );

      /** force redirect users who have already seen KYC Welcome screen */
      forceRedirectIf(
        to,
        [routeNames.corporateKYCWelcome],
        companyVerificationStatus == VerificationStatus.ONGOING,
        () => {
          if (companyId == null || companyId == undefined) {
            return next({
              name: routeNames.questionnaireCorporate,
              params: {
                questionType: "company-info",
              },
            });
          } else {
            return next({
              name: routeNames.settings,
              params: { tab: "profile" },
            });
          }
        },
        "User already seen Company KYC Welcome screen (CompanyVerificationStatus ONGOING)"
      );

      /** force page redirection for Corporate Onboarding flow */
      forceRedirectIf(
        to,
        [routeNames.questionnaireCorporate],
        companyVerificationStatus == VerificationStatus.ONGOING,
        () => {
          if (
            (companyId == null || companyId == undefined) &&
            to.params?.questionType != "company-info"
          ) {
            return next({
              name: routeNames.questionnaireCorporate,
              params: {
                questionType: "company-info",
              },
            });
          }
        },
        `User already seen Company KYC Welcome screen (CompanyVerificationStatus ONGOING)
          but have no companyId yet`
      );

      forceRedirectIf(
        to,
        [routeNames.questionnaireCorporate],
        companyVerificationStatus == VerificationStatus.NOT_VERIFIED,
        () => {
          return next({
            name: routeNames.corporateKYCWelcome,
          });
        },
        `User have not yet seen Company KYC Welcome screen (CompanyVerificationStatus NOT_VERIFIED)`
      );

      forceRedirectIf(
        to,
        [routeNames.companyDocumentsUpload],
        companyId == null,
        () => {
          return next({
            name: routeNames.questionnaireCorporate,
          });
        },
        `User have no Company ID yet`
      );

      sharedRouteGuard(store, to, from, next);
    } else {
      return next({ name: routeNames.login });
    }
  }

  // If going to a page that does not require authentication
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if you can go to the page while authenticated using `allowedAuth`
    if (isAuthenticated && to.meta.allowedAuth) {
      return next();
    }

    // Some pages (like Login page) should not be accessed if user is logged in
    if (isAuthenticated && to.meta.requiresAuth === false) {
      return next({ name: routeNames.home });
    }

    return next();
  }

  next();
});

/** The afterEach hook tells the router that
 *  after a link has completely evaluated to
 *  stop the progress bar, it shouldn’t care
 *  if the page request succeeds */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
