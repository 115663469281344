// API docs @see https://github.com/yiminghe/async-validator
import { Rules } from "async-validator";
/**
 * Choose max metadata when you need the most strict version of isValid(),
 * or if you need to detect phone number type ("fixed line", "mobile", etc).
 */
import { isValidPhoneNumber } from "libphonenumber-js/max";
import { removeSpaces } from "@/shared/utils/stringHelper";
import { regex } from "@/shared/utils/constants";
import { parseLocalisedBigDec } from "@/shared/utils/numbers";
import toString from "lodash/toString";
import { isValidBIC, isValidIBAN } from "ibantools";
import { config } from "@/shared/utils/config";
import isNaN from "lodash/isNaN";
import app from "@/entryApp";
import { getValidPhoneNumber } from "@/shared/utils/mobileHelper";
import { i18nTranslate } from "@/plugins/i18n";

export const makeRequiredRule = (
  errorMessage: string,
  type: "string" | "array" | "number" | "boolean" | "object" = "string"
): {
  type: "string" | "array" | "number" | "boolean" | "object";
  message: string;
  required: boolean;
} => {
  return {
    type,
    required: true,
    message: errorMessage,
  };
};

export const makeMaxLengthRule = (
  errorMessage: string,
  max: number
): {
  max: number;
  message: string;
} => {
  return {
    max: max,
    message: errorMessage,
  };
};

export const makeMinLengthRule = (
  errorMessage: string,
  min: number
): {
  min: number;
  message: string;
} => {
  return {
    min: min,
    message: errorMessage,
  };
};

/** Regular function for specified checking of regex string */
export const isRegexMatch = (
  text: string | undefined,
  regexStr: string
): boolean => {
  const regexObject = new RegExp(regexStr);
  let isValid = false;

  if (regexObject.test(text ?? "")) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const makeNonZeroNumber = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: number) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: number) => {
      return new Promise<void>((resolve, reject) => {
        if (isNaN(value) || !value) {
          reject(new Error(errorMessage));
          return;
        }
        let parsedValue;
        try {
          parsedValue = parseFloat(
            parseLocalisedBigDec({
              localisedBigDecimal: toString(value),
              decimalPlaces: config.maximumFractionDigits,
            }) as string
          );
        } catch (e) {
          reject(new Error(errorMessage)); // reject
        }

        if (parsedValue === 0) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

export const makeRequiredInputNumber = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: number) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: number) => {
      return new Promise<void>((resolve, reject) => {
        if (value <= 0 || value == null) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

export const makeMaximumNumber = (
  errorMessage: string,
  maximumNumber: number,
  additionalNum?: number
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        let parsedValue;
        const addNum = additionalNum ?? 0;

        try {
          parsedValue = parseFloat(
            parseLocalisedBigDec({
              localisedBigDecimal: toString(value),
              decimalPlaces: 2,
            }) as string
          );
        } catch (e) {
          reject(new Error(errorMessage)); // reject
        }

        if (value != null && parsedValue + addNum > maximumNumber) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

export const makeMinimumNumber = (
  errorMessage: string,
  minimumNumber: number,
  additionalNum?: number
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        let parsedValue;
        const addNum = additionalNum ?? 0;

        try {
          parsedValue = parseFloat(
            parseLocalisedBigDec({
              localisedBigDecimal: toString(value),
              decimalPlaces: 2,
            }) as string
          );
        } catch (e) {
          reject(new Error(errorMessage)); // reject
        }

        if (value != null && parsedValue + addNum < minimumNumber) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

export const makeRegexRule = (
  errorMessage: string,
  regexString: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObject = new RegExp(regexString);
        if (optional && !value) {
          resolve();
        }
        if (regexObject.test(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage,
  };
};

/** Similar to makeRegexRule but specifically for Latin characters only */
export const makeLatinCharRule = (
  errorMessage?: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObject = regex.latinChars;
        if (optional && !value) {
          resolve();
        }
        if (regexObject.test(value)) {
          resolve();
        } else {
          console.log("errorMessage", value);
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage ?? i18nTranslate("Only Latin letters are allowed"),
  };
};

/** Similar to makeRegexRule but specifically for Latin characters only */
export const makeValidDescriptionRule = (
  errorMessage?: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObject = regex.validDescription;
        if (optional && !value) {
          resolve();
        }
        if (regexObject.test(value)) {
          resolve();
        } else {
          console.log("errorMessage", value);
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage ?? i18nTranslate("Only Latin letters are allowed"),
  };
};

export const makeMobileNumberRule = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
  warningOnly: boolean;
} => {
  return {
    type: "string",
    warningOnly: true,
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (isPhoneNumValid(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

/** Regular function for specified validation of phone numbers */
export const isPhoneNumValid = (mobile: string): boolean => {
  const mobileNum = getValidPhoneNumber(mobile);

  if (isValidPhoneNumber(mobileNum)) {
    return true;
  } else {
    return false;
  }
};

export const makeEmailRule = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return makeRegexRule(errorMessage, regex.email);
};

/** Regular function for specified checking of email string */
export const isValidEmail = (text: string | undefined): boolean => {
  const regexObject = new RegExp(regex.email);
  let isEmail = false;
  if (regexObject.test(text ?? "")) {
    isEmail = true;
  } else {
    isEmail = false;
  }

  return isEmail;
};

export const makeRequiredCheckedRule = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: boolean) => Promise<void>;
  type: string;
} => {
  return {
    type: "boolean",
    asyncValidator: (rule, value: boolean) => {
      return new Promise<void>((resolve, reject) => {
        if (value !== true) {
          reject(new Error(errorMessage)); // reject with error message
        } else {
          resolve();
        }
      });
    },
  };
};

// FOR IBAN
/** This rule will exempt the internalIBAN rule
 *  as user must still be able to proceed with InternalIBAN */
export const makeIbanRule = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObjectInternalIBAN = new RegExp(regex.internalIBAN);
        if (
          isValidIBAN(removeSpaces(value)) ||
          regexObjectInternalIBAN.test(value)
        ) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

// FOR SWIFT
export const makeSwiftRule = (
  errorMessage: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (optional && !value) {
          resolve();
        }
        // can also use: bicValidator.isValid(removeSpaces(value))
        if (isValidBIC(removeSpaces(value))) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

/* IBAN rule is included in AMS repo aside from internalIbanRule,
 ** but will only include the internalIbanRule and email rule here*/
export const makeEmailOrIbanRule = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObjectInternalIBAN = new RegExp(regex.internalIBAN);
        const regexObjectEmail = new RegExp(regex.email);

        if (
          regexObjectInternalIBAN.test(value) ||
          regexObjectEmail.test(value)
        ) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

//This is a rule with warningOnly, will not block form submit.
export const noticeInternalIban = (
  message: string
): {
  type: "string";
  // message: string;
  warningOnly: boolean;
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  // updateOn: string;
} => {
  return {
    type: "string",
    warningOnly: true,
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObjectInternalIBAN = new RegExp(regex.internalIBAN);
        if (regexObjectInternalIBAN.test(value)) {
          reject(new Error(message));
        } else {
          resolve();
        }
      });
    },
  };
};

export const isValidInternalIBAN = (text: string | undefined): boolean => {
  const regexObject = new RegExp(regex.internalIBAN);
  return regexObject.test(text ?? "");
};

/** Custom/Specified rules for CardDetailsForm fields
 * see for reference: https://github.com/wuori/vue-credit-card-validation
 */
export const makeCardNumberFieldRule = (
  errorMessage: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (optional && !value) {
          resolve();
        }
        if (app.config.globalProperties.$cardFormat.validateCardNumber(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

export const makeCardExpiryFieldRule = (
  errorMessage: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (optional && !value) {
          resolve();
        }
        if (app.config.globalProperties.$cardFormat.validateCardExpiry(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

export const makeCardCvcFieldRule = (
  errorMessage: string,
  optional?: boolean
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (optional && !value) {
          resolve();
        }
        if (app.config.globalProperties.$cardFormat.validateCardCVC(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

// Specific validator for Check Crypto Exchange
// since the validator bind with the calling of API
// on trigger blur calls and overrides the amount computed
// as seen on jira ticket: #ob-223
export const makeCryptoValidation = (
  errorMessage: string,
  hasError?: boolean
): {
  asyncValidator: () => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: () => {
      return new Promise<void>((resolve, reject) => {
        if (hasError) {
          reject(new Error(errorMessage));
        } else {
          resolve();
        }
      });
    },
  };
};

/** Validation for checking if a value is included in
 * a given list (Array of Strings) */
export const makeIncludedInList = (
  errorMessage: string,
  list: Array<string>
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        if (list.includes(value)) {
          resolve();
        }
        reject(new Error(errorMessage));
      });
    },
  };
};

/**
 * Current phone number validation that validates if the entered
 * new phone number is the same as the user's current phone number
 *
 * @param errorMessage error message to be displayed
 * @param currentPhoneNumber user's current phone number
 * @returns error message if the entered new phone number is the
 * user's current phone number
 */
export const makeCurrentPhoneNumberRule = (
  errorMessage: string,
  currentPhoneNumber: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        // if entered new phone number is the user's current phone number
        if (currentPhoneNumber == value) {
          reject(new Error(errorMessage));
        } else {
          resolve();
        }
      });
    },
  };
};
