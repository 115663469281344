/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import {
  InternalVerificationStatus,
  VerificationStatus,
} from "@/../__generated__/globalTypes";
import { allowedRoutes, forceRedirectIf } from "@/shared/utils/routes";
import {
  allowVerificationRequirements,
  allowFiatExchange,
  isCorporateSite,
  config,
} from "@/shared/utils/config";
import routeNames from "./routeNames";
import corpRouteNames from "@/corporate/router/routeNames";
import privateRouteNames from "@/private/router/routeNames";
import routePaths from "./routePaths";
/* eslint-disable */
// @ts-ignore
import devToolsRoutes from "./devToolsRoutes";
import { getDebugFlagValue } from "@/shared/composables/useDebugApp";
import { computed, watch } from "vue";
import { KycNavigationStepsCount } from "@/shared/types/kycNavigationSteps";
import { i18nTranslate } from "@/plugins/i18n";

/**
 * Note: Add `shared` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. sharedHome)
 */
const sharedRoutes: Array<RouteRecordRaw> = [
  ...devToolsRoutes,
  {
    path: routePaths.login,
    name: routeNames.login,
    component: () =>
      import(/* webpackChunkName: "sharedLogin" */ "../views/Login.vue"),
    meta: {
      requiresAuth: false,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Login"
      }),
    },
  },
  {
    path: routePaths.register,
    name: routeNames.register,
    component: () =>
      import(/* webpackChunkName: "sharedRegister" */ "../views/Register.vue"),
    meta: {
      requiresAuth: false,
      allowedAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Register"
      }),
    },
  },
  {
    path: routePaths.about,
    name: routeNames.about,
    component: () =>
      import(/* webpackChunkName: "sharedAbout" */ "../views/About.vue"),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "About"
      }),
    },
  },
  {
    path: routePaths.settings,
    name: routeNames.settings,
    component: () =>
      import(/* webpackChunkName: "sharedSettings" */ "../views/Settings.vue"),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Settings"
      }),
    },
  },
  {
    path: routePaths.wallets,
    name: routeNames.wallets,
    component: () =>
      import(/* webpackChunkName: "sharedWallet" */ "../views/Wallet.vue"),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Accounts"
      }),
    },
  },
  {
    path: routePaths.walletTopup,
    name: routeNames.walletTopup,
    component: () =>
      import(
        /* webpackChunkName: "sharedWalletTopup" */ "../views/WalletTopup.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Account Top Up"
      }),
    },
  },
  {
    path: routePaths.walletDetails,
    name: routeNames.walletDetails,
    component: () =>
      import(
        /* webpackChunkName: "sharedWalletDetails" */ "../views/WalletDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Account Details"
      }),
    },
  },
  {
    path: routePaths.walletViewDetails,
    name: routeNames.walletViewDetails,
    component: () =>
      import(
        /* webpackChunkName: "sharedWalletViewDetails" */ "../views/WalletViewDetails.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Account Details"
      }),
    },
  },
  {
    path: routePaths.payments,
    name: routeNames.payments,
    component: () =>
      import(/* webpackChunkName: "sharedPayments" */ "../views/Payments.vue"),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Payments"
      }),
    },
  },
  {
    path: routePaths.bankTransfer,
    name: routeNames.bankTransfer,
    component: () =>
      import(
        /* webpackChunkName: "sharedPaymentsTransfer" */ "../views/PaymentsTransfer.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Bank Payment"
      }),
    },
  },
  {
    path: routePaths.cardTransfer,
    name: routeNames.cardTransfer,
    component: () =>
      import(
        /* webpackChunkName: "sharedPaymentsTransfer" */ "../views/PaymentsTransfer.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Card Payment"
      }),
    },
  },
  {
    path: routePaths.internalTransfer,
    name: routeNames.internalTransfer,
    component: () =>
      import(
        /* webpackChunkName: "sharedInternalTransfer" */ "../views/InternalTransfer.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Internal Transfer"
      }),
    },
  },
  {
    path: routePaths.fiatExchange,
    name: routeNames.fiatExchange,
    component: () =>
      import(
        /* webpackChunkName: "sharedFiatExchange" */ "../views/FiatExchange.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Fiat Exchange"
      }),
    },
  },
  {
    path: routePaths.staticFiatExchange,
    name: routeNames.staticFiatExchange,
    component: () =>
      import(
        /* webpackChunkName: "sharedStaticFiatExchange" */ "../views/StaticFiatExchange.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Fiat Exchange"
      }),
    },
  },
  {
    path: routePaths.history,
    name: routeNames.history,
    component: () =>
      import(/* webpackChunkName: "sharedHistory" */ "../views/History.vue"),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "History"
      }),
    },
  },
  {
    path: routePaths.otpSetup,
    name: routeNames.otpSetup,
    component: () =>
      import(
        /* webpackChunkName: "sharedOTPSetup" */ "../views/AuthenticatorReqt.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "OTP Setup"
      }),
    },
  },
  {
    path: routePaths.forgotPassword,
    name: routeNames.forgotPassword,
    component: () =>
      import(
        /* webpackChunkName: "sharedForgotPassword" */ "../views/ForgotPassword.vue"
      ),
    meta: {
      requiresAuth: false,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Forgot Password"
      }),
    },
  },
  {
    path: routePaths.resetPassword,
    name: routeNames.resetPassword,
    component: () =>
      import(
        /* webpackChunkName: "sharedResetPassword" */ "../views/ResetPassword.vue"
      ),
    meta: {
      requiresAuth: false,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Reset Password"
      }),
    },
  },
  {
    path: routePaths.updatePassword,
    name: routeNames.updatePassword,
    component: () =>
      import(
        /* webpackChunkName: "sharedUpdatePassword" */ "../views/UpdatePassword.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Update Password"
      }),
    },
  },
  {
    path: routePaths.emailConfirmation,
    name: routeNames.emailConfirmation,
    component: () =>
      import(
        /* webpackChunkName: "sharedConfirmEmailStatus" */ "../views/ConfirmEmailStatus.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Confirm Email"
        }),
      },
  },
  {
    path: routePaths.otpUnbind,
    name: routeNames.otpUnbind,
    component: () =>
      import(
        /* webpackChunkName: "sharedOTPUnbind" */ "../views/OTPUnbind.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Unbind OTP"
      }),
    },
  },
  {
    path: routePaths.kycPending,
    name: routeNames.kycPending,
    component: () =>
      import(
        /* webpackChunkName: "sharedKycPending" */ "../views/KycPending.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Document Submitted"
      }),
    },
  },
  {
    path: routePaths.kycDeclaration,
    name: routeNames.kycDeclaration,
    component: () =>
      import(
        /* webpackChunkName: "sharedKycDeclaration" */ "../views/KycDeclaration.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Account Verification"
      }),
    },
  },
  //Added mobile upload here for easier flow
  {
    path: routePaths.mobileUploadCode,
    name: routeNames.mobileUploadCode,
    component: () =>
      import(
        /* webpackChunkName: "sharedMobileUpload" */ "../views/MobileUpload.vue"
      ),
    meta: {
      requiresAuth: false,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Mobile Upload"
      }),
    },
  },
  {
    path: routePaths.uploadIDDocument,
    name: routeNames.uploadIDDocument,
    component: () =>
      import(
        /* webpackChunkName: "sharedUploadIdDocument" */ "../views/UploadIDDocument.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Upload ID Document"
      }),
    },
  },
  {
    path: routePaths.uploadPOADocument,
    name: routeNames.uploadPOADocument,
    component: () =>
      import(
        /* webpackChunkName: "sharedUploadPoaDocument" */ "../views/UploadPOADocument.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Upload POA Document"
      }),
    },
  },
  {
    path: routePaths.uploadSelfieDocument,
    name: routeNames.uploadSelfieDocument,
    component: () =>
      import(
        /* webpackChunkName: "sharedUploadSelfieDocument" */ "../views/UploadSelfieDocument.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Upload Selfie Document"
      }),
    },
  },
  {
    path: routePaths.uploadGenericDocument,
    name: routeNames.uploadGenericDocument,
    component: () =>
      import(
        /* webpackChunkName: "sharedUploadGenericDocument" */ "../views/UploadGenericDocument.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Upload Generic Document"
      }),
    },
  },
  {
    path: routePaths.updatePhone,
    name: routeNames.updatePhone,
    component: () =>
      import(
        /* webpackChunkName: "sharedUpdatePhone" */ "../views/UpdatePhone.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Update Mobile Number"
      }),
    },
  },
  {
    path: routePaths.updateEmail,
    name: routeNames.updateEmail,
    component: () =>
      import(
        /* webpackChunkName: "sharedUpdateEmail" */ "../views/UpdateEmail.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Update Email"
      }),
    },
  },
  {
    path: routePaths.backupCodes,
    name: routeNames.backupCodes,
    component: () =>
      import(
        /* webpackChunkName: "sharedBackupCodes" */ "../views/BackupCodes.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Backup Codes"
      }),
    },
  },
  {
    path: routePaths.somethingWentWrong,
    name: routeNames.somethingWentWrong,
    component: () =>
      import(
        /* webpackChunkName: "sharedSomethingWentWrong" */ "../views/SomethingWentWrong.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Something Went Wrong"
      }),
    },
  },
  {
    path: routePaths.unsubscribedEmail,
    name: routeNames.unsubscribedEmail,
    component: () =>
      import(
        /* webpackChunkName: "sharedUnsubscribedEmail" */ "../views/UnsubscribedEmail.vue"
      ),
    meta: {
      requiresAuth: false,
      allowedAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Unsubscribed Email"
      }),
    },
  },
  {
    path: routePaths.unsubscribedEmailFailed,
    name: routeNames.unsubscribedEmailFailed,
    component: () =>
      import(
        /* webpackChunkName: "sharedUnsubscribedEmailFailed" */ "../views/UnsubscribedEmailFailed.vue"
      ),
    meta: {
      requiresAuth: false,
      allowedAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Unsubscribed Email Failed"
      }),
    },
  },
  {
    path: routePaths.cryptoExchange,
    name: routeNames.cryptoExchange,
    component: () =>
      import(
        /* webpackChunkName: "sharedCryptoExchange" */ "../views/CryptoExchange.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Crypto Exchange"
      }),
    },
  },

  {
    path: routePaths.cardDepositSuccess,
    name: routeNames.cardDepositSuccess,
    component: () =>
      import(
        /* webpackChunkName: "sharedCardDepositSuccess" */ "../views/CardDepositSuccessful.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Card Deposit Success"
        }),
      },
  },
  {
    path: routePaths.cardDepositFailed,
    name: routeNames.cardDepositFailed,
    component: () =>
      import(
        /* webpackChunkName: "sharedCardDepositFailed" */ "../views/CardDepositFailed.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Card Deposit Failed"
        }),
      },
  },
  {
    path: routePaths.cardDepositCanceled,
    name: routeNames.cardDepositCanceled,
    component: () =>
      import(
        /* webpackChunkName: "sharedCardDepositCanceled" */ "../views/CardDepositCanceled.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Card Deposit Canceled"
        }),
      },
  },

  {
    path: routePaths.cardVerifySuccess,
    name: routeNames.cardVerifySuccess,
    component: () =>
      import(
        /* webpackChunkName: "sharedCardVerifySuccess" */ "../views/CardVerifySuccessful.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Card Verify Success"
        }),
      },
  },
  {
    path: routePaths.cardVerifyFailed,
    name: routeNames.cardVerifyFailed,
    component: () =>
      import(
        /* webpackChunkName: "sharedCardVerifyFailed" */ "../views/CardVerifyFailed.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Card Verify Failed"
        }),
      },
  },
  {
    path: routePaths.cardVerifyCanceled,
    name: routeNames.cardVerifyCanceled,
    component: () =>
      import(
        /* webpackChunkName: "sharedCardVerifyCanceled" */ "../views/CardVerifyCanceled.vue"
      ),
      meta: {
        title: i18nTranslate("{merchantBrand} - {page}", {
          merchantBrand: config.merchantBrand,
          page: "Card Verify Canceled"
        }),
      },
  },
  {
    path: routePaths.splashScreenVerification,
    name: routeNames.splashScreenVerification,
    component: () =>
      import(
        /* webpackChunkName: "sharedSplashScreenVerification" */ "../views/SplashScreenVerification.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Verification"
      }),
    },
  },
  {
    path: routePaths.splashScreenAccounts,
    name: routeNames.splashScreenAccounts,
    component: () =>
      import(
        /* webpackChunkName: "sharedSplashScreenAccounts" */ "../views/SplashScreenAccounts.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Accounts"
      }),
    },
  },
  {
    path: routePaths.splashScreenPayments,
    name: routeNames.splashScreenPayments,
    component: () =>
      import(
        /* webpackChunkName: "sharedSplashScreenPayments" */ "../views/SplashScreenPayments.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Payments"
      }),
    },
  },
  {
    path: routePaths.splashScreenHistory,
    name: routeNames.splashScreenHistory,
    component: () =>
      import(
        /* webpackChunkName: "sharedSplashScreenHistory" */ "../views/SplashScreenHistory.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "History"
      }),
    },
  },
  {
    path: routePaths.splashScreenExchange,
    name: routeNames.splashScreenExchange,
    component: () =>
      import(
        /* webpackChunkName: "sharedSplashScreenExchange" */ "../views/SplashScreenExchange.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Fiat Exchange"
      }),
    },
  },
  // Temporarily Suspended Page
  {
    path: routePaths.temporarilySuspended,
    name: routeNames.temporarilySuspended,
    component: () =>
      import(
        /* webpackChunkName: "sharedTemporarilySuspendedDisplay" */ "../views/TemporarilySuspendedDisplay.vue"
      ),
    meta: {
      requiresAuth: true,
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Temporarily Suspended"
      }),
    },
  },


  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "sharedNotFound" */ "../views/NotFound.vue"),
    meta: {
      title: i18nTranslate("{merchantBrand} - {page}", {
        merchantBrand: config.merchantBrand,
        page: "Not Found"
      }),
    },
  },
];

export default sharedRoutes;

/** routes for verified users only */
export const verifiedUserRoutes = [
  routeNames.dashboard,
  routeNames.wallets,
  routeNames.walletTopup,
  routeNames.walletDetails,
  routeNames.accounts,
  routeNames.bankTransfer,
  routeNames.fiatExchange,
  routeNames.internalTransfer,
  routeNames.bankTransfer,
  routeNames.history,
  routeNames.home,
  routeNames.payments,
  routeNames.cryptoExchange,
];

// routes for splash screen
export const sharedSplashScreen = [
  routeNames.splashScreenAccounts,
  routeNames.splashScreenExchange,
  routeNames.splashScreenHistory,
  routeNames.splashScreenPayments,
  routeNames.splashScreenVerification,
];

export const sharedAllowedQuestionnaireRoutes = [
  routeNames.settings,
  routeNames.kycDeclaration,
  routeNames.questionnaire,
  routeNames.uploadIDDocument,
  routeNames.uploadPOADocument,
  routeNames.somethingWentWrong,
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sharedRouteGuard = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  store,
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isOtpSetup = store.state.userInfo.isOtpSetup;
  const currentVerificationPage =
    store.state.userInfo.user.currentVerificationPage ?? 0;
  const isEmailConfirmed = store.state.userInfo.user.isEmailConfirmed;
  const isPhoneConfirmed = store.state.userInfo.user.isPhoneConfirmed;
  const isPolicyConfirmed = store.state.userInfo.user.isPolicyConfirmed;

  const internalVerificationStatus =
    store.state.userInfo.user.internalVerificationStatus;
  const personalVerificationStatus =
    store.state.userInfo.user.personalVerificationStatus;
  const companyVerificationStatus = isCorporateSite
    ? store.state.userInfo.user.companyVerificationStatus
    : null;

  const companyId = isCorporateSite
    ? store.state.userInfo.user.apiCompanyId
    : null;

  console.log("router.beforeEach", {
    to: to.name,
    internalVerificationStatus: internalVerificationStatus,
    personalVerificationStatus: personalVerificationStatus,
    companyVerificationStatus: companyVerificationStatus,
    companyId: companyId,
  });

  if (getDebugFlagValue("disableSkipKycUpload")) {
    return next();
  }

  switch (internalVerificationStatus) {
    case InternalVerificationStatus.NOT_VERIFIED: {
      // force redirect to Register if user email, phone or policy is not yet confirmed
      if (!isEmailConfirmed || !isPhoneConfirmed || !isPolicyConfirmed) {
        return next({ name: routeNames.register });
      }

      // Welcome Screen Redirection
      forceRedirectIf(
        to,
        verifiedUserRoutes,
        personalVerificationStatus != VerificationStatus.VERIFIED ||
          internalVerificationStatus != VerificationStatus.VERIFIED,
        () => {
          next({
            name: routeNames.splashScreenVerification,
          });
        },
        "To Welcome Splash Screen (Onboarding Flow), User is not yet VERIFIED"
      );


      // For update phone, Force redirect to settings if users has not yet setup their OTP
      forceRedirectIf(
        to,
        [routeNames.updatePhone],
        !isOtpSetup,
        () => {
          next({ name: routeNames.settings });
        },
        "To Settings, isOtpSetup is false"
      );
      break;
    }
    case InternalVerificationStatus.ONGOING: {
      /**
       * For corporate, force redirect to application if current
       * verification page is less than or equal to KYC steps
       * count(6), and the companyVerificationStatus is not verified
       */
      forceRedirectIf(
        to,
        verifiedUserRoutes,
        isCorporateSite
          ? companyVerificationStatus === VerificationStatus.NOT_VERIFIED &&
              currentVerificationPage <= KycNavigationStepsCount.value
          : personalVerificationStatus === VerificationStatus.NOT_VERIFIED,
        () => {
          next({
            name: routeNames.application,
          });
        },
        "To Application (Onboarding Flow), User is not yet VERIFIED"
      );

      /**
       * For corporate, force redirect to welcome kyc questionnaire
       * if current verification page is > KYC Steps Count(6) and the
       * companyVerificationStatus is not verified
       */
      forceRedirectIf(
        to,
        verifiedUserRoutes,
        isCorporateSite &&
          companyVerificationStatus === VerificationStatus.NOT_VERIFIED &&
          currentVerificationPage > KycNavigationStepsCount.value,
        () => {
          next({
            name: corpRouteNames.corporateKYCWelcome,
          });
        },
        "To Corporate KYC Welcome, User did not start yet with Questionnaires"
      );

      /**
       * For corporate, force redirect to questionnaire
       * if current verification page is > KYC Steps Count(6) and the
       * companyVerificationStatus is ongoing
       */
      forceRedirectIf(
        to,
        verifiedUserRoutes,
        isCorporateSite &&
          companyVerificationStatus === VerificationStatus.ONGOING &&
          currentVerificationPage > KycNavigationStepsCount.value,
        () => {
          next({
            name: corpRouteNames.questionnaireCorporate,
            params: {
              questionType: "",
            },
          });
        },
        "To Questionnaire, User is not done yet with Questionnaires"
      );

      toVerificationRequirementsPending(
        to,
        next,
        allowVerificationRequirements &&
          (isCorporateSite
            ? companyVerificationStatus === VerificationStatus.REAPPROVAL
            : personalVerificationStatus === VerificationStatus.REAPPROVAL)
      );

      toKycPendingScreen(
        to,
        next,
        isCorporateSite
          ? companyVerificationStatus === VerificationStatus.FOR_APPROVAL ||
              (companyVerificationStatus === VerificationStatus.ON_HOLD &&
                !allowVerificationRequirements)
          : personalVerificationStatus === VerificationStatus.FOR_APPROVAL ||
              (personalVerificationStatus === VerificationStatus.ON_HOLD &&
                !allowVerificationRequirements)
      );

      const corporateAllowedQuestionnaireRoutes = [
        corpRouteNames.companyDocumentsUpload,
        corpRouteNames.corporateKYCWelcome,
        corpRouteNames.questionnaireCorporate,
        ...sharedAllowedQuestionnaireRoutes,
        ...sharedSplashScreen,
      ];

      const privateAllowedQuestionnaireRoutes = [
        privateRouteNames.questionnairePersonal,
        ...sharedAllowedQuestionnaireRoutes,
        ...sharedSplashScreen,
      ];

      toQuestionnaireSettingScreen(
        store,
        to,
        next,
        /** verification status to use in condition depending per site env */
        isCorporateSite
          ? companyVerificationStatus === VerificationStatus.ONGOING
          : personalVerificationStatus === VerificationStatus.ONGOING,
        /** list of allowed routes depending per site env */
        isCorporateSite
          ? corporateAllowedQuestionnaireRoutes
          : privateAllowedQuestionnaireRoutes
      );

      // We must allow user to go to settings and update email, phone, password while onboarding
      // Apply logic on corporate and personal
      if (
        personalVerificationStatus === VerificationStatus.ONGOING &&
        (store?.state?.userInfo?.user?.currentVerificationPage ?? 0) <=
          KycNavigationStepsCount.value &&
        allowedRoutes("Redirect To Onboarding, Not yet finished", to, [
          routeNames.application,
          routeNames.somethingWentWrong,
          routeNames.settings,
          routeNames.updatePhone,
          routeNames.updateEmail,
          routeNames.updatePassword,
          routeNames.uploadIDDocument,
          routeNames.uploadPOADocument,
          routeNames.uploadSelfieDocument,
          ...sharedSplashScreen,
        ])
      ) {
        next({ name: routeNames.application });
      }

      // Redirect to Verification Requirements
      toVerificationRequirements(
        to,
        next,
        allowVerificationRequirements &&
          (!isCorporateSite
            ? personalVerificationStatus === VerificationStatus.ON_HOLD ||
              personalVerificationStatus ===
                VerificationStatus.REPROCESS_ON_GOING
            : companyVerificationStatus === VerificationStatus.ON_HOLD ||
              companyVerificationStatus ===
                VerificationStatus.REPROCESS_ON_GOING)
      );

      forceRedirectIf(
        to,
        verifiedUserRoutes,
        !allowVerificationRequirements &&
          personalVerificationStatus !== VerificationStatus.VERIFIED &&
          internalVerificationStatus !== VerificationStatus.VERIFIED,
        () => {
          next({
            name: routeNames.settings,
            params: { tab: "profile" },
          });
        },
        "To Settings - Questionnaire, User is not yet VERIFIED"
      );

      // For update phone, Force redirect to settings if users has not yet setup their OTP
      forceRedirectIf(
        to,
        [routeNames.updatePhone],
        !isOtpSetup,
        () => {
          next({ name: routeNames.otpSetup });
        },
        "To Settings, isOtpSetup is false"
      );
      break;
    }
    // if the user will not go to otp setup, yet the isOtpSetup is
    // false and the user’s internal verification status is for approval, redirect to otp-setup
    case InternalVerificationStatus.VERIFIED: {
      const isCryptoPermitted = computed(
        () =>
          store.state.userInfo.accountPermissions?.isCryptoExchangePermitted ??
          false
      );

      // wait for the returned parsed value to know if user really has no access
      watch([isCryptoPermitted], ([value]) => {
        if (!value) {
          forceRedirectIf(
            to,
            [routeNames.cryptoExchange],
            !value,
            () => {
              next({ name: routeNames.home });
            },
            "User has no permission to access Crypto Exchange page"
          );
        }
      });

      // Force redirect to dashboard if Fiat Exchange is disabled on the env config files
      forceRedirectIf(
        to,
        [routeNames.fiatExchange],
        !allowFiatExchange,
        () => {
          next({ name: routeNames.home });
        },
        "Fiat Exchange is disabled. Redirect to dashboard"
      );

      forceRedirectIf(
        to,
        [routeNames.otpSetup],
        !!isOtpSetup,
        () => {
          next({ name: routeNames.home });
        },
        "Not allowed on OTP Setup, isOtpSetup is true"
      );

      // Force redirect to OTP setup if verified users has not yet setup their OTP on
      forceRedirectIf(
        to,
        [...verifiedUserRoutes, routeNames.updatePhone],
        !isOtpSetup,
        () => {
          next({ name: routeNames.otpSetup });
        },
        "To OTP Setup, isOtpSetup is false"
      );

      const isCorporateUserKycDeclared =
        companyVerificationStatus == VerificationStatus.VERIFIED ||
        companyVerificationStatus == VerificationStatus.FOR_APPROVAL;

      const isPrivateUserKycDeclared =
        personalVerificationStatus == VerificationStatus.VERIFIED ||
        personalVerificationStatus == VerificationStatus.FOR_APPROVAL;

      forceRedirectIf(
        to,
        [routeNames.kycDeclaration],
        isCorporateSite ? isCorporateUserKycDeclared : isPrivateUserKycDeclared,
        () => {
          next({ name: routeNames.home });
        },
        "To Dashboard, User is already Company VERIFIED"
      );

      forceRedirectIf(
        to,
        [routeNames.application],
        personalVerificationStatus == VerificationStatus.VERIFIED ||
          internalVerificationStatus == InternalVerificationStatus.VERIFIED,
        () => {
          next({ name: routeNames.home });
        },
        "To Dashboard, User is already VERIFIED"
      );

      // Redirect to Dashboard
      forceRedirectIf(
        to,
        sharedSplashScreen,
        personalVerificationStatus == VerificationStatus.VERIFIED ||
          internalVerificationStatus == InternalVerificationStatus.VERIFIED,
        () => {
          next({ name: routeNames.home });
        },
        "To Dashboard, User is already VERIFIED"
      );

      /** TODO: Apply checking if user is allowed to use crypto exchange feature;
       * [TBD - UX] force redirection to some other page when user manually redirects
       * to crypto exchange page
       */
      break;
    }
    default: {
      next();
    }
  }
};

const toQuestionnaireSettingScreen = (
  store,
  to,
  next,
  statusCondition,
  allowedRoutesList: string[]
) => {
  if (
    statusCondition &&
    (store?.state?.userInfo?.user?.currentVerificationPage ?? 0) >
      KycNavigationStepsCount.value &&
    allowedRoutes("Redirect Questionnaire, Not Yet Done", to, allowedRoutesList)
  ) {
    next({
      name: routeNames.settings,
      params: { tab: "profile" },
    });
  }
};

const toKycPendingScreen = (to, next, statusCondition) => {
  // Allow Changing of Password and updating of phone even if user status is pending
  if (
    statusCondition &&
    allowedRoutes("Redirect KYC Pending Screen", to, [
      routeNames.settings,
      routeNames.kycPending,
      routeNames.somethingWentWrong,
      routeNames.updatePassword,
      routeNames.updatePhone,
      routeNames.updateEmail,
      ...sharedSplashScreen,
    ])
  ) {
    next({ name: routeNames.kycPending });
  }
};

const toVerificationRequirementsPending = (to, next, statusCondition) => {
  if (
    statusCondition &&
    allowedRoutes("Redirect to Verification Requirements Pending Screen", to, [
      routeNames.settings,
      routeNames.somethingWentWrong,
      ...sharedSplashScreen,
    ])
  ) {
    next({ name: routeNames.settings });
  }
};

const toVerificationRequirements = (to, next, statusCondition) => {
  if (
    statusCondition &&
    allowedRoutes("To Verification Requirements, User is ON_HOLD", to, [
      routeNames.settings,
      routeNames.somethingWentWrong,
      routeNames.uploadIDDocument,
      routeNames.uploadPOADocument,
      routeNames.uploadSelfieDocument,
      routeNames.uploadGenericDocument,
      corpRouteNames.companyDocumentsUpload,
      // Allow user to change password, update email and phone even if user is ON HOLD
      routeNames.updatePhone,
      routeNames.updateEmail,
      routeNames.updatePassword,
    ])
  ) {
    next({
      name: routeNames.settings,
      params: { tab: "profile" },
    });
  }
};

/**
 * Redirection to Temporarily Suspended page when status conditons met
 * @param to 
 * @param next next route
 * @param statusCondition condition to allow redirection to Temporarily Suspended page
 */
export const handleTemporarilySuspendedRedirect = (to, next, statusCondition) => {
  if (
    statusCondition &&
    allowedRoutes("To Temporarily Suspended page, User is Temporarily Suspended", to, [
      routeNames.temporarilySuspended,
    ])
  ) {
    next({
      name: routeNames.temporarilySuspended,
    });
  }
};
