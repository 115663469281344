import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/LogoWithText.svg'


const _hoisted_1 = { class: "brand-logo" }
const _hoisted_2 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _imports_0,
          alt: _ctx.t('{brandName}', { brandName: _ctx.config.merchantBrand })
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}