import { windowSizeSimpleStore } from "@/shared/store/simpleStore/windowSizeData";

export const windowSizeChecker = (): void => {
  const mobileMatchMedia = window.matchMedia(`(max-width: 576px)`);
  const mediumMatchMedia = window.matchMedia(`(max-width: 768px)`);
  const largeMatchMedia = window.matchMedia(`(max-width: 992px)`);

  windowSizeSimpleStore.mobile = mobileMatchMedia;
  windowSizeSimpleStore.medium = mediumMatchMedia;
  windowSizeSimpleStore.large = largeMatchMedia;
  windowSizeSimpleStore.isMobile = mobileMatchMedia.matches;
  windowSizeSimpleStore.isMedium = mediumMatchMedia.matches;
  windowSizeSimpleStore.isLarge = largeMatchMedia.matches;

  windowSizeSimpleStore.mobile.addEventListener("change", (event) => {
    windowSizeSimpleStore.isMobile = event.matches;
  });

  windowSizeSimpleStore.medium.addEventListener("change", (event) => {
    windowSizeSimpleStore.isMedium = event.matches;
  });

  windowSizeSimpleStore.large.addEventListener("change", (event) => {
    windowSizeSimpleStore.isLarge = event.matches;
  });
};
