import throttle from "lodash/throttle";

export const antTabResizeInkbar = () => {
  // Callback function
  const adjustInkbarCallback = throttle(
    () => {
      setTimeout(() => {
        // Get ant-tabs elements
        const tabNavs = document.querySelectorAll(".ant-tabs .ant-tabs-nav");

        tabNavs.forEach((nav) => {
          // Get active tab
          const activeTab = nav.querySelector(
            ".ant-tabs-tab-active"
          ) as HTMLElement;
          // Get inkbar
          const inkBar = nav.querySelector(".ant-tabs-ink-bar") as HTMLElement;

          // Set inkbar's transform to adjust translate3d.x
          inkBar.style.transform = `translate3d(${activeTab.offsetLeft}px, 0, 0)`;
          // Set inkbar's width if it changes (mostly done in responsiveness)
          inkBar.style.width = `${activeTab.offsetWidth}px`;
        });
      }, 100); // Set a delay of 100ms for fast window resizing
    },
    100, // Throttle function for performance
    { leading: true, trailing: true }
  );

  window.addEventListener("resize", adjustInkbarCallback);
};
