import { Rules } from "async-validator";
import moment from "moment";
import { config } from "../config";
import { i18nTranslate } from "@/plugins/i18n";

type DateAsyncValidator = {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
};

export const getMessageValidFutureDate = (): string =>
  i18nTranslate("Date cannot be in the future. Please input a past date");

export const getMessageValidLegalAge = (): string =>
  i18nTranslate("Only persons having an age of 18 and above are allowed.");

export const getMessageValidAtLeastSixMonthsFutureDate = (): string =>
  i18nTranslate("Date must be at least 6 months from now.");

export const getMessageValidPastDate = (): string =>
  i18nTranslate("Date must be a past date");

export const getMessageValidPastToCurrentDate = (): string =>
  i18nTranslate("Date must be today or past date.");

export const getMessageValidCurrentToFuture = (): string =>
  i18nTranslate("Date must not be a past date");

export const makeBirthDateRule = (
  errorMessage: string = getMessageValidLegalAge(),
  dateFormat = config.generalDateFormat
): DateAsyncValidator => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        const dateToday = moment();
        const dateValue = moment(value, dateFormat);
        const differenceInYears = dateToday.diff(dateValue, "years");
        if (differenceInYears >= 18) {
          resolve();
        } else {
          reject(errorMessage);
        }
      });
    },
  };
};

export const makeExpiryDateRule = (
  errorMessage: string = getMessageValidAtLeastSixMonthsFutureDate(),
  dateFormat = config.generalDateFormat,
  isOptional = false
): DateAsyncValidator => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        const dateToday = moment();
        const dateValue = moment(value, dateFormat);
        const differenceInMonths = dateValue.diff(dateToday, "months");

        /** workaround for validation error message issue
         * where an "'[fieldName]' is not a valid undefined" message will be shown
         * right after selecting a date value
         * see: https://github.com/vueComponent/ant-design-vue/issues/5283
         * and more details in: https://app.clickup.com/t/2arx28h
         */
        if (differenceInMonths >= 6 || (isOptional && !value)) {
          resolve();
        } else {
          reject(errorMessage);
        }
      });
    },
  };
};

/** FIXME: Create data type for date validation function parameter */
export const makePastDateRule = (
  errorMessage: string = getMessageValidPastDate(),
  dateFormat = config.generalDateFormat,
  isOptional = false
): DateAsyncValidator => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        const dateToday = moment();
        const dateValue = moment(value, dateFormat);

        /** workaround for validation error message issue
         * where an "'[fieldName]' is not a valid undefined" message will be shown
         * right after selecting a date value
         * see: https://github.com/vueComponent/ant-design-vue/issues/5283
         * and more details in: https://app.clickup.com/t/2arx28h
         */
        if (dateToday.isAfter(dateValue, "day") || (isOptional && !value)) {
          resolve();
        } else {
          reject(errorMessage);
        }
      });
    },
  };
};

// This rule only allow past date and today's date
export const makePastToCurrentDateRule = (
  errorMessage: string = getMessageValidPastToCurrentDate(),
  dateFormat = config.generalDateFormat,
  isOptional = false
): DateAsyncValidator => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        const dateToday = moment();
        const dateValue = moment(value, dateFormat);

        /**
         * reject error message if date entered is after the date today i.e future date
         * or if isOptional is not true (i.e. required) and the value is empty
         */
        if (dateValue.isAfter(dateToday, "day") || (!isOptional && !value)) {
          reject(errorMessage);
        } else {
          resolve();
        }
      });
    },
  };
};

export const makeCurrentToFutureDateRule = (
  errorMessage: string = getMessageValidCurrentToFuture(),
  dateFormat = config.generalDateFormat,
  isOptional = false
): DateAsyncValidator => {
  return {
    type: "string",
    asyncValidator: (rule, value: string) => {
      return new Promise<void>((resolve, reject) => {
        const dateToday = moment();
        const dateValue = moment(value, dateFormat);

        /** workaround for validation error message issue
         * where an "'[fieldName]' is not a valid undefined" message will be shown
         * right after selecting a date value
         * see: https://github.com/vueComponent/ant-design-vue/issues/5283
         * and more details in: https://app.clickup.com/t/2arx28h
         */
        if (dateToday.isBefore(dateValue, "day") || (isOptional && !value)) {
          reject(errorMessage);
        } else {
          resolve();
        }
      });
    },
  };
};
