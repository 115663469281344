import i18nIsoCountries from "i18n-iso-countries";
/** This list of countries is on English language
 *  If you wish to change, just modify the import links
 */
import languageCountries from "i18n-iso-countries/langs/en.json";
import { includes, map, orderBy, remove } from "lodash";
import { DropdownOptionObj } from "@/shared/types/commonForm";
import { getCountryCallingCode, CountryCode } from "libphonenumber-js";
/**
 *  If you use the package in a browser environment,
 *  you have to register the languages you want to use
 *  to minimize the file size
 */
i18nIsoCountries.registerLocale(languageCountries);

/**
 * Top country codes priority list
 */
export const priorityCountryCodeList = ["CZ", "FR", "DE", "PL", "GB"];

/**
 * Getting the list of countries (names) and it's aplhacodes2
 */
const countryList = i18nIsoCountries.getNames("en", {
  select: "official",
});

// Due to disparity between Django's country list and i18n-iso-countries,
// XK (KOSOVO) needs to be removed from the countries array (https://app.clickup.com/t/2j4yfmh)
const countriesToRemove = ["XK"];

/**
 * We need to map the list of countries to
 * assign the value and text accordingly
 *
 * Order by priorityCountryCodeList after mapping
 * this will return Czech Republic as the first country for country list
 */
export const countries = orderBy(
  remove(
    /**
     * Filter Countries to be removed
     * (!include was used here because remove() returns the removed elements)
     * */
    map(countryList, (val, key) => {
      /**
       * Check if type of val is string or string[]
       * to avoid errors on dropdowninput when filtering
       * */
      if (typeof val === "string") {
        return { value: key, text: val };
      } else {
        // get first country name, sometimes there are other alias for a country. e.g. United Kingdom, Great Britain etc.
        return { value: key, text: val[0] };
      }
    }),
    (country) => {
      return !includes(countriesToRemove, country.value) && country;
    }
  ),
  (item) => includes(priorityCountryCodeList, item.value),
  "desc"
);

/** returns either the text or value of country data
 * depending on the input param
 */
export function getCountryObject(input) {
  /** if input is more than 3 characters it will
   * be treated as a country name thus will return
   * its equivalent country code
   *
   * ISO (Country) Codes are usually 2 or 3 characters
   */
  if (input.length > 3) {
    // returns equivalent country code,
    // updated with iso dynamic countries based on given comments,
    return i18nIsoCountries.getAlpha2Code(input, "en") ?? ""; //sample output: US
  } else {
    // returns equivalent country name
    return i18nIsoCountries.getName(input, "en", { select: "official" }) ?? ""; //sample output: United States of America
  }
}

export type CountryDropdownObj = DropdownOptionObj & { shortText?: string };

/** Dropdown options with label containing calling code, and value as the 2-letter country code. */
export const countriesPhoneDropdownOptions: CountryDropdownObj[] =
  countries.map((country) => {
    let countryCallingCode = "";

    try {
      countryCallingCode =
        "+" + getCountryCallingCode(country.value as CountryCode).toString();
    } catch (e) {
      // Ignore country calling code not found by `libphonenumber`.
      // Still add the dropdown option.
    }

    const label = countryCallingCode
      ? `${country.text} (${countryCallingCode})`
      : country.text;
    const value = country.value;
    // If calling code is empty, then use country code as short label instead
    const shortLabel = countryCallingCode || country.value;

    return {
      text: label,
      value: value,
      shortText: shortLabel,
    };
  });

export const priorityCountryDropdownOptions: CountryDropdownObj[] =
  countriesPhoneDropdownOptions.filter((option) =>
    priorityCountryCodeList.includes(option.value)
  );

export const nonPriorityCountryDropdownOptions: CountryDropdownObj[] =
  countriesPhoneDropdownOptions.filter(
    (option) => !priorityCountryCodeList.includes(option.value)
  );

export const noTaxCountryCodeList = [
  "BS", // BAHAMAS
  "BH", // BAHRAIN
  "BM", // BERMUDA
  "BN", // BRUNEI
  "KY", // CAYMAN ISLANDS
  "KW", // KUWAIT
  "MV", // MALDIVES
  "MC", // MONACO
  "NR", // NAURU
  "OM", // OMAN
  "QA", // QATAR
  "KN", // SAINT KITTS AND NEVIS
  "SO", // SOMALIA
  "AE", // UAE
  "VU", // VANUATU
  "EH", // WESTERN SAHARA
];
