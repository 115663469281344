import gql from "graphql-tag";

export const userGql = gql`
  query User {
    user {
      ... on User {
        id
        email
        name
        accountId
        ruid
        accountNumber
        phone
        apiCompanyId
        pendingPhone
        internalVerificationStatus
        personalVerificationStatus
        companyVerificationStatus
        companyName
        nationality {
          name
          code
          alpha3
          numeric
          iocCode
        }
        currentVerificationPage
        apiCompanyId
        pendingEmail
        apiPersonId
        loginFailedAttempts
        isEmailConfirmed
        isPhoneConfirmed
        hasBackupCodesSetup
        is2faEnabled
        avatarUrl
        isPolicyConfirmed
      }

      ... on ResponseErrors {
        errors {
          message
          code
          displayMessage

          ... on InternalError {
            message
            code
            displayMessage
            context
          }
        }
      }
    }
  }
`;
