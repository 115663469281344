<template>
  <div
    class="non-authenticated-layout"
    :class="[
      isCorporateSite
        ? 'non-authenticated-layout-dark'
        : 'non-authenticated-layout-light',
    ]"
  >
    <a-layout>
      <NonAuthenticatedHeader v-if="!isMobileScreen" />

      <a-layout-content class="non-authenticated-layout__body-content">
        <transition name="fade" mode="out-in">
          <slot />
        </transition>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<style lang="scss">
/** You are allowed to hide the badge as long as you include
*   the reCAPTCHA branding visibly in the user flow.
*   if you choose to hide the badge, please use:
*/
.grecaptcha-badge {
  visibility: hidden;
}

.non-authenticated-layout {
  // Corporate bg
  &-dark {
    .ant-layout {
      background: v-bind(backgroundValue);
    }
  }
  // Personal bg
  &-light {
    .ant-layout {
      background: v-bind(backgroundValue);
    }
  }
  .ant-layout {
    min-height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  @media screen and (max-width: $screen-sm) {
    .ant-layout {
      background: $non-auth-background;
    }
  }
}
</style>
<script>
import { useI18n } from "vue-i18n";
import { defineComponent, computed } from "vue";
import { windowSizeSimpleStore } from "@/shared/store/simpleStore/windowSizeData";
import NonAuthenticatedHeader from "@/shared/components/layouts/NonAuthenticatedHeader.vue";
import { isCorporateSite } from "@/shared/utils/config";
import DarkLayoutBg from "@/assets/images/login/non-auth-layout-bg-dark.png";
import LightLayoutBg from "@/assets/images/login/non-auth-layout-bg-light.png";
import { useRoute } from "vue-router";
import routeNames from "@/shared/router/routeNames";
import { getGlobalSassVariables } from "@/shared/utils/globalScssVariables";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();
    const isMobileScreen = computed(() => windowSizeSimpleStore.isMobile);
    const isMobileUploadPage = computed(
      () => route.name == routeNames.mobileUploadCode
    );

    // create a handler that returns the value of the background then v-bind it into CSS
    const backgroundValue = computed(() => {
      // specific background for Upload page
      if (isMobileUploadPage.value) {
        // this automatically determines whether it's on corporate or personal
        return getGlobalSassVariables().bodyBackground;
      }

      // else, return specific layout bg icons
      return isCorporateSite
        ? //use the dark bg layout
          `url(${DarkLayoutBg}) no-repeat center center fixed`
        : //use the light bg layout
          `url(${LightLayoutBg}) no-repeat center center fixed`;
    });

    return {
      t,
      isMobileScreen,
      isCorporateSite,
      backgroundValue,
    };
  },
  components: {
    NonAuthenticatedHeader,
  },
});
</script>
