import notification, { IconType } from "ant-design-vue/lib/notification";
import { i18nTranslate } from "@/plugins/i18n";
import { config } from "@/shared/utils/config";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { computed } from "vue";

export const makeToast = (
  type: IconType,
  title: string,
  message: string,
  duration?: number,
  key?: string
): void => {
  if (isEmpty(message)) {
    return;
  }

  /**
   * If the type of toast is an error toast,
   * duration must be doubled to 20 seconds,
   * else, duration will be retained to 10 seconds
   */
  const fallbackDuration = computed(() => {
    return type == "error" ? 20 : 10;
  });

  /// In case a malformed message is passed on the toast just show a default error
  if (!isString(message)) {
    return makeDefaultErrorToast();
  }

  // Note: Set timeout here is needed so we don't trigger an infinite loop of updates
  setTimeout(() => {
    notification[type]({
      message: title,
      description: message,
      duration: duration ?? fallbackDuration.value,

      /**
       * Setting the message as the key will prevent the UI from
       * displaying multiple toasts with just the same messages
       */
      key: key ?? message,
    });
  }, 500);
};

export const makeDefaultErrorToast = () => {
  makeToast(
    "error",
    i18nTranslate("Error"),
    i18nTranslate(
      "Something went wrong. Please try again later. If the problem persists, please contact us at {contactEmail}",
      {
        contactEmail: config.contactEmail,
      }
    )
  );
};
