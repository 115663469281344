export default {
  home: "home",
  login: "login",
  about: "about",
  application: "application",
  questionnaire: "questionnaire",
  payments: "payments",
  settings: "settings",
  accounts: "accounts",
  internalTransfer: "internal-transfer",
  wallets: "wallets",
  walletTopup: "wallet-topup",
  walletViewDetails: "wallet-view-details",
  dashboard: "dashboard",
  walletDetails: "wallet-details",
  fiatExchange: "fiat-exchange",
  staticFiatExchange: "static-fiat-exchange",
  history: "history",
  bankTransfer: "bank-transfer",
  cardTransfer: "card-transfer",
  otpSetup: "otp-setup",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  updatePassword: "update-password",
  register: "register",
  kycDeclaration: "kyc-declaration",
  kycPending: "kyc-pending",
  emailConfirmation: "confirm-email",
  otpUnbind: "otp-unbind",
  uploadIDDocument: "upload-id-document",
  uploadPOADocument: "upload-poa-document",
  uploadSelfieDocument: "upload-selfie-document",
  uploadGenericDocument: "upload-document",
  mobileUploadCode: "mobile-upload-short-code",
  updatePhone: "update-phone",
  updateEmail: "update-email",
  backupCodes: "backup-codes",
  tariff: "tariff",
  somethingWentWrong: "something-went-wrong",
  unsubscribedEmail: "unsubscribed-email",
  unsubscribedEmailFailed: "unsubscribed-email-failed",
  cryptoExchange: "crypto-exchange",
  cardDepositSuccess: "card-deposit-success",
  cardDepositFailed: "card-deposit-failed",
  cardDepositCanceled: "card-deposit-canceled",
  cardVerifySuccess: "card-verify-success",
  cardVerifyFailed: "card-verify-failed",
  cardVerifyCanceled: "card-verify-canceled",
  splashScreenVerification: "splash-screen-verification",
  splashScreenAccounts: "splash-screen-accounts",
  splashScreenPayments: "splash-screen-payments",
  splashScreenHistory: "splash-screen-history",
  splashScreenExchange: "splash-screen-exchange",
  /** Temporarily Suspended */
  temporarilySuspended: "temporarilySuspended",
};
