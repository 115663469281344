export enum PersonalQuestionnaireNavigationSteps {
  BASIC_INFO = "basic-info",
  ADDRESS_OF_RESIDENCY = "address-of-residency",
  ACTUAL_ADDRESS = "actual-address",
  CORRESPONDENCE_ADDRESS = "correspondence-address",
  SOURCES_OF_INCOME = "source-of-income",
  USE_OF_ACCOUNT = "use-of-account",
  PLANNED_SERVICES = "planned-services",
  PLANNED_OUTGOING = "planned-outgoing",
  PLANNED_INCOMING = "planned-incoming",
  TAX_INFORMATION = "tax-info",
}

export type PersonalQuestionnaireNavigationState = {
  activeItem: PersonalQuestionnaireNavigationSteps;
  percentage: number;
};
