import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "authenticated-layout__custom-sider-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthenticatedHeader = _resolveComponent("AuthenticatedHeader")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass(['authenticated-layout', _ctx.isMediumScreen && 'mobile-layout'])
  }, {
    default: _withCtx(() => [
      (_ctx.isMediumScreen)
        ? (_openBlock(), _createBlock(_component_AuthenticatedHeader, {
            key: 0,
            onOpenSidebar: _ctx.openMobileSidebar,
            onCloseSidebar: _ctx.closeMobileSidebar,
            mobileCollapsed: _ctx.mobileCollapsed
          }, null, 8, ["onOpenSidebar", "onCloseSidebar", "mobileCollapsed"]))
        : (_openBlock(), _createBlock(_component_a_layout_sider, {
            key: 1,
            collapsed: _ctx.collapsed,
            "onUpdate:collapsed": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.collapsed) = $event)),
            collapsible: "",
            class: "authenticated-layout__sider desktop-sider",
            trigger: null
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Sidebar, { isCollapsed: _ctx.collapsed }, null, 8, ["isCollapsed"]),
                _createElementVNode("div", {
                  class: "authenticated-layout__custom-sider-wrapper__trigger",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
                }, [
                  _createVNode(_component_LeftOutlined, {
                    class: _normalizeClass([
              'authenticated-layout__custom-sider-wrapper__trigger__icon',
              _ctx.collapsed && 'icon-reversed',
            ])
                  }, null, 8, ["class"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["collapsed"])),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          (_ctx.isMediumScreen)
            ? (_openBlock(), _createBlock(_component_a_layout_sider, {
                key: 0,
                collapsed: _ctx.mobileCollapsed,
                "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mobileCollapsed) = $event)),
                breakpoint: "md",
                "collapsed-width": "0",
                class: "authenticated-layout__sider mobile-sider",
                width: "100vw",
                trigger: null
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Sidebar, { onCloseSidebar: _ctx.closeMobileSidebar }, null, 8, ["onCloseSidebar"])
                ]),
                _: 1
              }, 8, ["collapsed"]))
            : (_openBlock(), _createBlock(_component_AuthenticatedHeader, {
                key: 1,
                onOpenSidebar: _ctx.openMobileSidebar
              }, null, 8, ["onOpenSidebar"])),
          _createVNode(_component_a_layout_content, { class: "authenticated-layout__body-content" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class"]))
}