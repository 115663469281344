export default {
  home: "/",
  login: "/login",
  about: "/about",
  application: "/application",
  dashboard: "/dashboard",
  accounts: "/accounts",
  payments: "/payments",
  history: "/history",
  support: "/support",
  settings: "/settings/:tab?",
  walletTopup: "/wallets/topup/:currency",
  walletViewDetails: "/wallets/details/:currency",
  internalTransfer: "/payments/internal",
  wallets: "/wallets",
  walletDetails: "/wallets/:currency",
  fiatExchange: "/fiat-exchange",
  staticFiatExchange: "/static-fiat-exchange",
  bankTransfer: "/payments/bank",
  cardTransfer: "/payments/card",
  otpSetup: "/otp-setup",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:uid/:token/:_2fa_enabled",
  updatePassword: "/settings/update-password",
  register: "/register",
  kycDeclaration: "/kyc/declaration",
  kycPending: "/kyc/pending",
  emailConfirmation: "/confirm-email/:status",
  otpUnbind: "/otp-unbind",
  mobileUploadCode: "/upload/:shortCode?",
  uploadIDDocument: "/settings/upload-id-document",
  uploadPOADocument: "/settings/upload-poa-document",
  uploadSelfieDocument: "/settings/upload-selfie-document",
  uploadGenericDocument: "/settings/upload-document",
  updatePhone: "/settings/update-phone",
  updateEmail: "/settings/update-email",
  backupCodes: "/settings/backup-codes",
  tariff: "/settings/tariff",
  somethingWentWrong: "/something-went-wrong",
  unsubscribedEmail: "/unsubscribe",
  unsubscribedEmailFailed: "/unsubscribe-failed",
  cryptoExchange: "/crypto-exchange",
  cardDepositSuccess: "/card-deposit-success",
  cardDepositFailed: "/card-deposit-failed",
  cardDepositCanceled: "/card-deposit-canceled",
  cardVerifySuccess: "/card-verify-success",
  cardVerifyFailed: "/card-verify-failed",
  cardVerifyCanceled: "/card-verify-canceled",
  splashScreenVerification: "/verification",
  splashScreenAccounts: "/view-accounts",
  splashScreenPayments: "/view-payments",
  splashScreenHistory: "/view-history",
  splashScreenExchange: "/view-exchange",
  /** Temporarily Suspended */
  temporarilySuspended: "/temporarily-suspended",
};
