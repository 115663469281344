import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import { provideApolloClient } from "@vue/apollo-composable";
import { authLink } from "@/api/graphqlClient/authLink";
import { wsHybridLink } from "@/api/graphqlClient/wsLink";
import { config } from "@/shared/utils/config";
import { createUploadLink } from "apollo-upload-client";
import {
  customErrorHandlerLink,
  errorHandlerLink,
} from "@/api/graphqlClient/errorHandlerLink";
import { httpLink } from "@/api/graphqlClient/httpLink";
import { retryLink } from "@/api/graphqlClient/retryLink";

/// See documentation of library @see https://v4.apollo.vuejs.org/guide-composable/

export const makeApolloClientInstance = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      /** Set the unique identifer (key) to "documentId" for the Type
       * "AccountStatement" which is used in "TransactionList" API.
       * Additional info: "documentId" is the key used in Back-end as well. */
      ["AccountStatement"]: {
        keyFields: ["documentId", "id"],
      },
    },
  });
  const uploadLink = createUploadLink({
    uri: config.httpGqlServerUrl,
    credentials: "include",
  });

  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      wsHybridLink,
      retryLink,
      errorHandlerLink,
      customErrorHandlerLink,
      uploadLink,
      httpLink,
    ]),
    credentials: "include",
    cache,
    defaultOptions: {
      query: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
      },
    },
  });
};

export const apolloProvider = provideApolloClient(makeApolloClientInstance());
