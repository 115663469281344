import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, _mergeProps({ class: "basic-button" }, _ctx.$attrs), {
    icon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    default: _withCtx(() => [
      (!_ctx.iconOnly)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
            _createTextVNode(_toDisplayString(_ctx.t("basicButton.defaultText")), 1)
          ])
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16))
}