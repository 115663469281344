import { i18nTranslate } from "@/plugins/i18n";
import { config } from "@/shared/utils/config";
import { makeToast } from "@/shared/utils/toast";
import store from "@/store";
import debounce from "lodash/debounce";

const delayBeforeNotifyForWsIssue = 4000;

// ======================== WEBSOCKET DISCONNECTED NOTIFIER ======================== //
export const userWebSocketNotifier = {
  noConnectionNotified: false,
};

export const noWebSocketConnectionNotifierDebounced = debounce(() => {
  const isAuthenticated = store.state.userInfo.isAuthenticated;

  if (!userWebSocketNotifier.noConnectionNotified && isAuthenticated) {
    makeToast(
      "error",
      i18nTranslate("Error"),
      i18nTranslate(
        "Real-time updates are not working. Please check your connection. If the problem persists, please contact us at {contactEmail}",
        {
          contactEmail: config.contactEmail,
        }
      )
    );
  }

  userWebSocketNotifier.noConnectionNotified = true;
}, delayBeforeNotifyForWsIssue);

export const hasWebSocketConnectionNotifier = () => {
  noWebSocketConnectionNotifierDebounced.cancel();

  if (userWebSocketNotifier.noConnectionNotified) {
    userWebSocketNotifier.noConnectionNotified = false;
    makeToast(
      "success",
      i18nTranslate("Connected"),
      i18nTranslate("Real-time updates have reconnected and should be working.")
    );
  }
};
