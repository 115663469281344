<template>
  <!-- MEDIUM VIEW -->
  <a-layout-header v-if="isMediumScreen" class="mobile-header">
    <a-row class="w-100 vertical-middle">
      <a-col :span="2">
        <MenuOutlined
          v-if="mobileCollapsed"
          class="sidebar-toggle cursor-pointer"
          @click="openSidebar"
        />
        <CloseOutlined
          v-else
          class="sidebar-toggle cursor-pointer"
          @click="closeSidebar"
        />
      </a-col>
      <a-col :span="20" class="page-name text-center">
        <a-typography-title
          :level="3"
          class="mt-20 capitalize mobile-header__title"
        >
          {{ pageName }}
        </a-typography-title>
      </a-col>
      <a-col :span="2" class="flex items-center justify-end">
        <img src="@/assets/images/Logo.svg" class="mobile-logo" />
      </a-col>
    </a-row>
  </a-layout-header>

  <!-- DESKTOP VIEW -->
  <a-layout-header v-else class="desktop-header authenticated-header pl-0">
    <a-row justify="center">
      <a-col v-if="!ifKycQuestionnaire" />
      <UserMenuPopup />
    </a-row>
  </a-layout-header>
</template>

<style lang="scss" scoped>
.authenticated-header {
  display: flex;
  /** 
   * Using flexbox property for parent
   * also known as inline-flex
   */
  justify-content: flex-end;
  /** 
   * Items are evenly distributed in the line;
   * first item is on the start line, last item
   * on the end line
   */
  align-items: center;
  position: fixed;
  overflow: hidden;
  width: 100%;
  position: absolute;
  right: 0;
  padding: 0px;
}
.ant-layout-header {
  background: $body-background;
  border-bottom: 1px solid $separator-color;
}

.mobile-header {
  height: $layout-header-height-mobile;
  position: fixed;
  z-index: 2;
  width: 100%;
  /**
  * For Browsers such as Safari with different spacing.
  *
  * Just in case the letter spacing doesn't take effect on typography
  */
  letter-spacing: normal;

  .sidebar-toggle {
    font-size: x-large;
    padding-top: 20px;
  }

  /**
  * For Browsers such as Safari with different spacing.
  */
  &__title {
    letter-spacing: normal;
  }
}

@media screen and (max-width: $screen-md) {
  .mobile-header {
    display: flex;
    max-width: 100vw;
  }

  .mobile-logo {
    height: 38px;
  }

  .ant-layout-header {
    box-shadow: none !important;
    padding: 0 20px;
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons-vue";
import UserMenuPopup from "@/shared/components/UserMenuPopup.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { windowSizeSimpleStore } from "@/shared/store/simpleStore/windowSizeData";
import RouteNames from "@/shared/router/routeNames";
import PrivateRouteNames from "@/private/router/routeNames";
import CorpRouteNames from "@/corporate/router/routeNames";

export default defineComponent({
  props: {
    mobileCollapsed: {
      type: Boolean,
    },
  },
  components: {
    UserMenuPopup,
    MenuOutlined,
    CloseOutlined,
  },
  setup(_, context) {
    const t = useI18n().t;
    const router = useRouter();
    const store = useStore();
    let ifKycQuestionnaire = ref(false);
    let percentage;
    const pageName = computed(() => {
      // Custom Header Names
      switch (router.currentRoute.value.name) {
        case RouteNames.home:
          return t("Dashboard");
        case RouteNames.application:
          return "";
        case RouteNames.wallets:
          return t("Accounts");
        case RouteNames.walletDetails:
          return t("Accounts");
        case RouteNames.walletTopup:
          return t("Top-up");
        case PrivateRouteNames.questionnairePersonal:
          return t("Questionnaire");
        case CorpRouteNames.questionnaireCorporate:
          return t("Questionnaire");
        case RouteNames.bankTransfer:
          return t("Bank Transfer");
        case RouteNames.internalTransfer:
          return t("Internal Transfer");
        case RouteNames.kycDeclaration:
          return t("KYC Declaration");
        case RouteNames.kycPending:
          return t("KYC Pending");
        case CorpRouteNames.corporateKYCWelcome:
          return t("Corporate KYC Welcome");
        case RouteNames.backupCodes:
          return t("Backup Codes");
        case RouteNames.cryptoExchange:
          return t("Crypto");
        case RouteNames.fiatExchange:
          return t("Fiat Exchange");
        case RouteNames.cardTransfer:
          return t("Card Transfer");
        case RouteNames.walletViewDetails:
          return t("Accounts");
        case RouteNames.splashScreenAccounts:
          return t("Accounts");
        case RouteNames.splashScreenExchange:
          return t("Fiat Exchange");
        case RouteNames.splashScreenHistory:
          return t("History");
        case RouteNames.splashScreenPayments:
          return t("Payments");
        case RouteNames.splashScreenVerification:
          return t("Verification");
        default:
          return String(router.currentRoute.value.name).replaceAll("-", " "); // Convert dash separated string to space separated
      }
    });
    const isMediumScreen = computed(() => windowSizeSimpleStore.isMedium);

    const openSidebar = () => {
      context.emit("openSidebar");
    };

    const closeSidebar = () => {
      context.emit("closeSidebar");
    };

    watchEffect(() => {
      const path = router.currentRoute.value.name;
      if (path == "application" || path == "questionnaire") {
        ifKycQuestionnaire.value = true;
      } else {
        ifKycQuestionnaire.value = false;
      }
      percentage = computed(() => store.state.kycNavigation.percentage);
    });

    const selectedKeys = ref<number[]>([1]);
    return {
      t,
      isMediumScreen,
      selectedKeys,
      ifKycQuestionnaire,
      percentage,
      openSidebar,
      closeSidebar,
      pageName,
    };
  },
  emits: ["openSidebar", "closeSidebar"],
});
</script>
