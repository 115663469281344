import { RetryLink } from "@apollo/client/link/retry";

/** To retry queries for maximum of 3 times. See: https://www.apollographql.com/docs/react/api/link/introduction */
export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      /**
       * This retry is only for operations aside from CreateLog.
       * An error on CreateLog would still re-trigger the errorHandlerLink
       * (after suppression).
       */
      const shouldRetry = !!error && operation.operationName !== "CreateLog";

      // Log details
      console.group("retryLink?");
      console.log(`[${operation.operationName}] shouldRetry?`, shouldRetry);
      console.log("error?", error);
      console.log("operation?", operation);
      console.groupEnd();

      return shouldRetry;
    },
  },
});
