import { createI18n, VueMessageType } from "vue-i18n";
import { config, isCorporateSite } from "@/shared/utils/config";
import commonJson from "../locales/common/en.json";
import isEmpty from "lodash/isEmpty";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): {
  [locale: string]: { [key: string]: VueMessageType };
} {
  // Webpack require.context only works with string literals
  let locales: __WebpackModuleApi.RequireContext;
  if (isCorporateSite) {
    locales = require.context(
      "../locales",
      true,
      /corporate.[A-Za-z0-9-_,\s]+\.json$/i
    );
  } else {
    locales = require.context(
      "../locales",
      true,
      /private.[A-Za-z0-9-_,\s]+\.json$/i
    );
  }

  const messages: { [locale: string]: { [key: string]: VueMessageType } } = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/\.([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = {
        ...commonJson,
        ...locales(key),
      };
    }
  });

  return messages;
}

/**
 * Default number formats
 *
 * For other format options, check https://vue-i18n.intlify.dev/guide/essentials/number.html
 */
export const numberFormats = {
  en: {
    decimal: {
      style: "decimal" as const, // Ensure literal type "decimal"
      minimumFractionDigits: config.minimumFractionDigits,
      maximumFractionDigits: config.maximumFractionDigits,
    },
  },
  cs: {
    decimal: {
      style: "decimal" as const,
      minimumFractionDigits: config.minimumFractionDigits,
      maximumFractionDigits: config.maximumFractionDigits,
    },
  },
};

const i18nMain = createI18n({
  legacy: false,
  locale: config.locale,
  fallbackLocale: config.fallbackLocale,
  messages: loadLocaleMessages(), // Dynamically load messages
  numberFormats, // Provide number formats
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  missingWarn: false,
});

/**
 * Translation function that wraps around i18n `t` method.
 * Falls back to key if translation is empty.
 */
export const i18nTranslate = (key: string, options = {}): string => {
  if (!key) {
    return "";
  }

  const result = i18nMain.global.t(key, options);

  if (isEmpty(result)) {
    return key; // Fallback to the key if no translation is found
  }

  return result;
};

/**
 * Number formatting function that wraps around i18n `n` method.
 */
export const i18nNumberFormat = i18nMain.global.n;

export default i18nMain;
