import { setContext } from "@apollo/client/link/context";
import store from "@/store";

export const getAuthToken = () => {
  const savedUserInfo = store?.state?.userInfo;

  if (savedUserInfo?.token) {
    return `Bearer ${savedUserInfo.token}`;
  }

  return "";
};

export const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getAuthToken(),
    },
    credentials: "include", // Ensure cookies are included
  };
});
