/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Operation,
  PaymentOrderType,
} from "../../../../__generated__/globalTypes";
import { makeSimpleStore } from "@/shared/utils/simpleStoreUtils";
import { SupportedCurrencyPaymentsList_supportedCurrencyPaymentsList_SupportedCurrencyPaymentsList_data_paymentMethods } from "@/api/payments/__generated__/SupportedCurrencyPaymentsList";
import { getCountryObject } from "@/shared/utils/countries/countries";
import { computed } from "vue";
import isEmpty from "lodash/isEmpty";

// ===== TYPE DEFINITIONS =====

export interface CommonPaymentDetails {
  accountNumber?: string | null;
  sortCode?: string | null;
  iban?: string | null | undefined;
  bicSwift?: string | null;
  cardNumber?: string | null;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  otherName?: string | null;
}

export interface BeneficiaryAddressPaymentDetails {
  beneficiaryAddress?: string | null;
  beneficiaryAddress2?: string | null;
  beneficiaryCity?: string | null;
  beneficiaryCountry?: string | null;
  beneficiaryZip?: string | null;
}

export interface CorrespondentBankPaymentDetails {
  correspondentBankAccount?: string | null;
  correspondentBankSwift?: string | null;
  correspondentBankCodeType?: string | null;
  correspondentBankCode?: string | null;
  correspondentBankName?: string | null;
  correspondentBankAddress?: string | null;
  correspondentBankAddress2?: string | null;
  correspondentBankCity?: string | null;
  correspondentBankState?: string | null;
  correspondentBankCountryCode?: string | null;
  correspondentBankZip?: string | null;
  correspondentBankOtherInfo?: string | null;
  isCorrRequired?: boolean | null;
}

export interface RussianPaymentDetails {
  kpp?: string | null;
  inn?: string | null;
  patronymicNames?: string | null;
  bicCode?: string | null;
}

export interface DomesticPaymentDetails {
  domesticAccountNumber?: string | null;
  domesticSortCode?: string | null;
}

// TODO: ADD additional fields for UAH/Ukraine Fields if needed
export interface UkrainePaymentDetails {
  purpose?: string | null;
}

export type PaymentDetails = CommonPaymentDetails &
  BeneficiaryAddressPaymentDetails &
  CorrespondentBankPaymentDetails &
  DomesticPaymentDetails &
  RussianPaymentDetails &
  UkrainePaymentDetails;

/** to track if coming from either of the tabs: Internal | To Bank | To Card */
export enum PaymentMode {
  INTERNAL = "INTERNAL",
  BANK = "BANK",
  CARD = "CARD",
}

export type BankTransferData = {
  pageNumber: number;
  paymentMode: PaymentMode | null;
  /** Form Data */
  paymentDetails: PaymentDetails;
  amount: string;
  currency: string;
  accountId: string;
  commission: string;
  paymentReference: string;
  /**
   * PaymentOrderType and Operation can be interchangeable but Opeartion should be more
   * used to be more consistent with API responses
   */
  transferType: PaymentOrderType | Operation | string | null;
  countryCode: string;
  noFee: boolean;
  feeAmount?: string | number;
  total: number;
  paymentOrderUuid: string;
  paymentOrderId: string;
  repeatedPayment: boolean;
  repeatedPoId: string; // to be used for repeat payment mutation query
  repeatedApiDocId: string | null; // solely for fetching repeated payment order details
  /**
   * Save selected payment methods's fee details for calculation
   */
  selectedPaymentMethod: SupportedCurrencyPaymentsList_supportedCurrencyPaymentsList_SupportedCurrencyPaymentsList_data_paymentMethods;
  success: boolean;
  // UAH Amount
  uahAmount: string;
  ilsAmount: string;
  //If payment flow is from Dashboard Shortcut
  isDashboardShortcut: boolean;
};

// ===== UTILITY FUNCTIONS =====

/** Note: Possible to change some beneficiaryFieldsData names if
 * included in SupportedCurrencyPaymentsList's beneficiaryFields;
 *
 * Created this map for the equivalent paymentDetails key from
 * expected dynamic beneficiary field names returned by API;
 * For multiple expected field names, put possible values in
 * a list of strings (see: bicSwift)
 *
 * Mainly to unify field label usages when localizing/parsing paymentDetails
 * based from dynamic beneficiary fields;
 *
 * Also see: parsePaymentMethodBeneficiaryFields in paymentMethods.ts
 */
export const beneficiaryFieldsMap: { [key in keyof PaymentDetails]: string } = {
  companyName: "BenCompanyName",
  firstName: "BenFirstName",
  lastName: "BenLastName",
  otherName: "BenOtherName",

  accountNumber: "BenAccount",
  /** Currently known beneficiaryFields doesn't include SortCode yet */
  sortCode: "SortCode",
  iban: "BenIBAN",
  /** SEPA_BANK fields returns BicSwift while WIRE returns benSWIFTCode */
  bicSwift: "BicSwift",
  /** Currently known beneficiaryFields doesn't include CardNumber yet */
  cardNumber: "CardNumber",
  beneficiaryAddress: "BenAddress",
  beneficiaryAddress2: "BenAddress2",
  beneficiaryCity: "BenCity",
  beneficiaryCountry: "BenCountryCode",
  beneficiaryZip: "BenZIP",
  correspondentBankAccount: "CorrBankAccount",
  correspondentBankSwift: "CorrBankSWIFT",
  correspondentBankCodeType: "CorrBankCodeType",
  correspondentBankCode: "CorrBankCode",
  correspondentBankName: "CorrBankName",
  correspondentBankAddress: "CorrBankAddress",
  correspondentBankAddress2: "CorrBankAddress2",
  correspondentBankCity: "CorrBankCity",
  correspondentBankState: "CorrBankState",
  correspondentBankCountryCode: "CorrBankCountryCode",
  correspondentBankZip: "CorrBankZIP",
  correspondentBankOtherInfo: "CorrBankOtherInfo",
  domesticAccountNumber: "benAccountNumber",
  domesticSortCode: "benSortCode",
  bicCode: "bicCode",
  inn: "inn",
  kpp: "kpp",
  // TODO: Update if the purpose has different name from BE API
  purpose: "purpose",
};

/** mostly for WIRE transfer where BeneficiaryFields query input
 * requires JSON string and object keys should match with the BE field names
 */
export const reverseMapBenFields = (beneficiaryFieldsData) => {
  const dynamicFieldNames = Object.values(beneficiaryFieldsMap);
  const paymentDetailsFields = Object.keys(beneficiaryFieldsMap);

  const parsedData = {};

  dynamicFieldNames.map((fieldName, index) => {
    parsedData[fieldName ?? ""] =
      beneficiaryFieldsData[paymentDetailsFields[index]];
    /** Beneficiary Fields expect a country code while
     * the one being saved in the store is the country name;
     * thus, convert it into country code first
     */
    if (fieldName === "BenCountryCode") {
      parsedData[fieldName] = getCountryObject(
        beneficiaryFieldsData[paymentDetailsFields[index]]
      );
    }
  });

  /** remove fields with undefined values */
  Object.keys(parsedData).forEach((key) => {
    if (parsedData[key] === undefined) {
      delete parsedData[key];
    }
  });
  return parsedData;
};

export const mapBeneficiaryFields = (beneficiaryFieldsData) => {
  return <PaymentDetails>{
    companyName:
      beneficiaryFieldsData[beneficiaryFieldsMap.companyName ?? ""] ?? "",
    /** Hide firstName and lastName in Confirmation Page. Instead, shows the fullName */
    firstName:
      beneficiaryFieldsData[beneficiaryFieldsMap.firstName ?? ""] ?? "",
    lastName: beneficiaryFieldsData[beneficiaryFieldsMap.lastName ?? ""] ?? "",
    otherName:
      beneficiaryFieldsData[beneficiaryFieldsMap.otherName ?? ""] ?? "",
    accountNumber:
      beneficiaryFieldsData[beneficiaryFieldsMap.accountNumber ?? ""] ?? "",
    sortCode: beneficiaryFieldsData[beneficiaryFieldsMap.sortCode ?? ""] ?? "",
    iban: beneficiaryFieldsData[beneficiaryFieldsMap.iban ?? ""] ?? "",
    bicSwift: beneficiaryFieldsData[beneficiaryFieldsMap.bicSwift ?? ""],
    cardNumber:
      beneficiaryFieldsData[beneficiaryFieldsMap.cardNumber ?? ""] ?? "",

    beneficiaryAddress:
      beneficiaryFieldsData[beneficiaryFieldsMap.beneficiaryAddress ?? ""] ??
      "",
    beneficiaryAddress2:
      beneficiaryFieldsData[beneficiaryFieldsMap.beneficiaryAddress2 ?? ""] ??
      "",
    beneficiaryCity:
      beneficiaryFieldsData[beneficiaryFieldsMap.beneficiaryCity ?? ""] ?? "",
    beneficiaryCountry:
      beneficiaryFieldsData[beneficiaryFieldsMap.beneficiaryCountry ?? ""] ??
      "",
    beneficiaryZip:
      beneficiaryFieldsData[beneficiaryFieldsMap.beneficiaryZip ?? ""],
    correspondentBankAccount:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankAccount ?? ""
      ] ?? "",
    correspondentBankSwift:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankSwift ?? ""
      ] ?? "",
    correspondentBankCodeType:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankCodeType ?? ""
      ] ?? "",
    correspondentBankCode:
      beneficiaryFieldsData[beneficiaryFieldsMap.correspondentBankCode ?? ""] ??
      "",
    correspondentBankName:
      beneficiaryFieldsData[beneficiaryFieldsMap.correspondentBankName ?? ""] ??
      "",
    correspondentBankAddress:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankAddress ?? ""
      ] ?? "",
    correspondentBankAddress2:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankAddress2 ?? ""
      ] ?? "",
    correspondentBankCity:
      beneficiaryFieldsData[beneficiaryFieldsMap.correspondentBankCity ?? ""] ??
      "",
    correspondentBankState:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankState ?? ""
      ] ?? "",
    correspondentBankCountryCode:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankCountryCode ?? ""
      ] ?? "",
    correspondentBankZip:
      beneficiaryFieldsData[beneficiaryFieldsMap.correspondentBankZip ?? ""] ??
      "",
    correspondentBankOtherInfo:
      beneficiaryFieldsData[
        beneficiaryFieldsMap.correspondentBankOtherInfo ?? ""
      ] ?? "",
    domesticAccountNumber:
      beneficiaryFieldsData[beneficiaryFieldsMap.domesticAccountNumber ?? ""] ??
      "",
    domesticSortCode:
      beneficiaryFieldsData[beneficiaryFieldsMap.domesticSortCode ?? ""] ?? "",
    bicCode: beneficiaryFieldsData[beneficiaryFieldsMap.bicCode ?? ""] ?? "",
    inn: beneficiaryFieldsData[beneficiaryFieldsMap.inn ?? ""] ?? "",
    kpp: beneficiaryFieldsData[beneficiaryFieldsMap.kpp ?? ""] ?? "",
    purpose: beneficiaryFieldsData[beneficiaryFieldsMap.purpose ?? ""] ?? "",
  };
};

// ===== STORE =====

// Default value for payment details
export const paymentDetailsDefault = {
  accountNumber: null,
  sortCode: null,
  iban: null,
  bicSwift: null,
  cardNumber: null,
  companyName: null,
  firstName: null,
  lastName: null,
  otherName: null,
  beneficiaryAddress: null,
  beneficiaryAddress2: null,
  beneficiaryCity: null,
  beneficiaryCountry: null,
  beneficiaryZip: null,
  correspondentBankAccount: null,
  correspondentBankSwift: null,
  correspondentBankCodeType: null,
  correspondentBankCode: null,
  correspondentBankName: null,
  correspondentBankAddress: null,
  correspondentBankAddress2: null,
  correspondentBankCity: null,
  correspondentBankState: null,
  correspondentBankCountryCode: null,
  correspondentBankZip: null,
  correspondentBankOtherInfo: null,
  isCorrRequired: null,
  domesticAccountNumber: "",
  domesticSortCode: "",
  kpp: "",
  inn: "",
  bicCode: "",
  patronymicNames: "",
  purpose: "",
};

export const bankTransferSimpleStore = makeSimpleStore<BankTransferData>({
  pageNumber: 1,
  paymentMode: null,
  /** always use paymentDetails object if relating
   * to beneficiary details
   */
  paymentDetails: paymentDetailsDefault,
  amount: "",
  currency: "",
  accountId: "",
  commission: "",
  paymentReference: "",
  transferType: null,
  countryCode: "",
  noFee: true,
  feeAmount: undefined,
  total: 0,
  paymentOrderUuid: "",
  paymentOrderId: "",
  repeatedPayment: false,
  repeatedPoId: "",
  repeatedApiDocId: null,
  selectedPaymentMethod: {
    __typename: "PaymentMethod",
    operationType: null,
    beneficiaryFields: "{}", // json string object from backend
    expectationDetails: {
      __typename: "ExpectationDetails",
      min: null,
      max: null,
      timeUnit: null,
    },
    feeDetails: {
      __typename: "FeeDetails",
      feeId: null,
      otherParameters: null,
      preference: null,
      fixedAmount: null,
      percentAmount: null,
      minimumAmount: null,
      maximumAmount: null,
      transactionLimit: null,
      region: null,
    },
  },
  success: false,
  uahAmount: "",
  ilsAmount: "",
  isDashboardShortcut: false,
});

// ===== COMPUTED VALUES =====

export const isBankTransfer = computed(
  () => bankTransferSimpleStore.paymentMode === PaymentMode.BANK
);

export const isCardTransfer = computed(
  () => bankTransferSimpleStore.paymentMode === PaymentMode.CARD
);

export const isEurTransfer = computed(
  () => bankTransferSimpleStore.currency === "EUR"
);

export const isRussianTransfer = computed(
  () => bankTransferSimpleStore.currency === "RUB"
);

// TODO: Make sure that KPP is only for Russian Company Transfer in the future
export const isRussianBankCompanyTransfer = computed(
  () =>
    isRussianTransfer.value &&
    isBankTransfer.value &&
    bankTransferSimpleStore.selectedPaymentMethod.operationType ===
      Operation.RUSSIAN_BANK &&
    !isEmpty(bankTransferSimpleStore.paymentDetails.kpp)
);

export const isRussianCardTransfer = computed(
  () => isRussianTransfer.value && isCardTransfer.value
);

export const isPaymentSuccess = computed(() => bankTransferSimpleStore.success);

/** Custom tranfer for UAH */
export const isUAHTransfer = computed(
  () =>
    bankTransferSimpleStore.selectedPaymentMethod.operationType ===
    Operation.DOMESTIC_PAYMENT_UAH
);
export const isUAHCardTransfer = computed(
  () => isUAHTransfer.value && isCardTransfer.value
);
export const isUAHBankTransfer = computed(
  () => isUAHTransfer.value && isBankTransfer.value
);
export enum UAHOperation {
  UAH_BANK = "UAH_BANK",
  UAH_CARD = "UAH_CARD",
  EUR_BANK_UKRAINE = "EUR_BANK_UKRAINE",
}

/** Custom tranfer for ILS */
export const isILSTransfer = computed(
  () =>
    bankTransferSimpleStore.selectedPaymentMethod.operationType ===
    Operation.DOMESTIC_PAYMENT_ILS
);
export const isILSCardTransfer = computed(
  () => isILSTransfer.value && isCardTransfer.value
);
export const isILSBankTransfer = computed(
  () => isILSTransfer.value && isBankTransfer.value
);
export enum ILSOperation {
  ILS_BANK = "ILS_BANK",
  ILS_CARD = "ILS_CARD",
  EUR_BANK_ISRAEL = "EUR_BANK_ISRAEL",
}

/** Custom transfer are all transfer that are similar to UAH and ILS */
export const customTransfers: any = [
  Operation.DOMESTIC_PAYMENT_UAH,
  Operation.DOMESTIC_PAYMENT_ILS,
];
export const customTransfer = (specificTransfer: Operation | null = null) => {
  const isCustomTransfer = computed(() => {
    if (specificTransfer) {
      return (
        specificTransfer ===
        bankTransferSimpleStore.selectedPaymentMethod.operationType
      );
    }
    return customTransfers.includes(
      bankTransferSimpleStore.selectedPaymentMethod.operationType
    );
  });

  const isCustomTransferCard = computed(
    () => isCustomTransfer.value && isCardTransfer.value
  );
  const isCustomTransferBank = computed(
    () => isCustomTransfer.value && isBankTransfer.value
  );

  return {
    isCustomTransfer,
    isCustomTransferCard,
    isCustomTransferBank,
  };
};
export const customTransferCurrencies = {
  [Operation.DOMESTIC_PAYMENT_UAH]: "UAH",
  [Operation.DOMESTIC_PAYMENT_ILS]: "ILS",
};
export const customTransferCurrency = (specificTransfer) => {
  return specificTransfer ? customTransferCurrencies[specificTransfer] : null;
};
